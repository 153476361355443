/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 *
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 *
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React, { lazy, Suspense } from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphTimeTabSkeleton.module.css';
import ErrorBoundary from "@components/ErrorBoundary";

const LazyMorphTimeTab = lazy(() =>
  import('@components/MorphTime').then((module) => ({ default: module.MorphTimeTab }))
);

const MorphTimeTabFallback = () => {
  return <div className={Skeleton.loader + ' ' + Styles.style} />;
};
// The render component below lazy loads the corresponding component using the skeleton above
const MorphTimeTabSkeleton = () => {
  const MorphTimeTabSkeletonProps = {};

  return (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphTimeTabSkeleton.tsx`}>
      <Suspense fallback={<MorphTimeTabFallback />}>
        <LazyMorphTimeTab {...MorphTimeTabSkeletonProps} />
      </Suspense>
    </ErrorBoundary>
  );
};
export default MorphTimeTabSkeleton;
