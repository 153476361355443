import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MorphType } from '@common/MorphType';
import axiosCall from '@services/axios';
import { getPrimaryParticipant, getNewChatPosition } from '@common/errandUtils';
import { ChatType } from '@common/ChatType';
import { IErrand } from '@interfaces/Conversation';
import { useRootContext } from '@contexts/RootContext';
import { useErrandContext } from '@contexts/ErrandContext';
import Styles from '@styles/MessageContent/ErrandMessageContent.module.css';
import useInitialMount from '@common/hooks/useInitialMount';
import { useUserContext } from '@contexts/user';

type TErrandMessageContentProps = {
  errand: IErrand;
}

const ErrandMessageContent = ({
  errand, 
}: TErrandMessageContentProps) => {
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const { t, i18n } = useTranslation();
  const { _id } = useUserContext();

  // some errands have a parentId equal to themselves which results in forever having one errand incomplete
  const errands = useMemo(() => rootContext.childErrands?.filter((e) => e._id !== errand._id), [errand._id, rootContext.childErrands]);
  const closedErrands = useMemo(() => rootContext.childErrands?.filter((errand) => ['closed'].includes(errand.status)), [rootContext.childErrands]);

  const handleClick = useCallback((e) => {
    e?.stopPropagation();
    const primary = getPrimaryParticipant(errand);
    if (primary?.userData?._id === _id) {
      if (closedErrands?.length === 1) {
        axiosCall({
          url: `chat/${closedErrands[0]?._id}`,
          method: 'put',
          data: { 
            status: 'waiting-updates',
            lastOpened: new Date(Date.now()).toISOString(),
            position: getNewChatPosition(rootContext.errands),
          },
        });
      } else {
        errandContext.setMorphType((prev): MorphType => {
          if (prev === MorphType.Errand) {
            errandContext?.footerInputRef?.current?.focus();
            return MorphType.None;
          }
          return MorphType.Errand
        });
      }
    }
  }, [rootContext.errands, errandContext, errand, closedErrands, _id]);

  useInitialMount(() => rootContext.fetchClosedChats());

  return (
    <div className={Styles.style} onClick={handleClick}>
      <span>
        <span>
          <div>{t('yourErrands')}</div>
          <div>{closedErrands?.length === errands?.length ? t('allTasksComplete') : t('allTasksNotComplete')}</div>
          <div>{closedErrands?.length === 1 ? <>{t('open')} {t(ChatType.Errand)}</> : errandContext?.morphType === MorphType.Errand ? t('allTasksHide') : t('allTasksDetails')}</div>
        </span>
      </span>
      <span>
        <span className={Styles.errandsWrapper}>
          {closedErrands?.slice(0,3)?.map((errand, index) => (
            <span key={index}>
              {new Date(errand.createdAt)?.toLocaleDateString(i18n.language, { day: '2-digit' })}<br/>
              {new Date(errand.createdAt)?.toLocaleDateString(i18n.language, { weekday: 'short' })}
            </span>
          ))}
          <div><span>{closedErrands?.length}/{errands?.length}</span></div>
        </span>
      </span>
    </div>
  );
};

export default ErrandMessageContent;
