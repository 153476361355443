import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Styles from '@styles/MessageContent/PrivateMessageContent.module.css';
import { handlePreviews } from '@common/loadPreview';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import { MorphType } from '@common/MorphType';
import { IErrand, IMessage } from '@interfaces/Conversation';
import Sanitized from '@components/Sanitized';
import { getAudienceArr } from '@common/msgUtils';
import { ParticipantNameGet } from '@common/common';

const Shush = process.env.REACT_APP_MORGAN_CDN + '/Images/Shush.png';

type TPrivateMessageContent = {
  errand: IErrand;
  message: IMessage;
};

const PrivateMessageContent = ({ errand, message }: TPrivateMessageContent) => {
  const { t, i18n } = useTranslation();
  const { setMorphType, footerInputRef } = useErrandContext();
  const { setErrands } = useRootContext();

  const messagePreview = useMemo(() => handlePreviews(message), [message]);

  const errandParticipants = useMemo(() => errand?.participants, [errand?.participants]);

  const messageIntendedAudience = useMemo(() => message?.intendedAudience, [message?.intendedAudience]);
  const messageSender = useMemo(() => message?.sender, [message?.sender]);
  const messageChat = useMemo(() => message?.chat, [message?.chat]);
  const messageSentByCurrentUser = useMemo(() => message?.sentByCurrentUser, [message?.sentByCurrentUser]);
  const messageAlignByCurrentUser = useMemo(() => message?.alignByCurrentUser, [message?.alignByCurrentUser]);
  const messageSearchWords = useMemo(() => message?.searchWords, [message?.searchWords]);
  const messageVisible = useMemo(() => message?.visible, [message?.visible]);
  const messageSenderType = useMemo(() => message?.senderType, [message?.senderType]);

  const messageSenderId = useMemo(() => messageSender?._id, [messageSender?._id]);

  const ClassName = useMemo(
    () =>
      Styles.wrapper +
      ' ' +
      (messageSentByCurrentUser ? Styles.sentByCurrentUser + ' ' : '') +
      (messageAlignByCurrentUser ? Styles.alignByCurrentUser + ' ' : ''),
    [messageAlignByCurrentUser, messageSentByCurrentUser]
  );

  const displayName = useMemo(() => {
    const participantEntry = errandParticipants.find((x) => x?.userData?._id === messageSenderId);

    const userData = participantEntry?.userData;

    return ParticipantNameGet(userData);
  }, [errandParticipants, messageSenderId]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();

      const audience = getAudienceArr(null, messageSenderId, messageSenderType, messageIntendedAudience);

      setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === messageChat);

        if (chatObj) {
          chatObj.recipients = audience;
        }

        setMorphType(MorphType.PrivateChat);
        footerInputRef.current?.focus();
        return [...prev]; // spread to trigger dependency arrays as state was modified
      });
    },
    [messageSenderType, footerInputRef, setMorphType, messageChat, messageIntendedAudience, messageSenderId, setErrands]
  );

  return (
    <button className={ClassName} onClick={handleClick}>
      <div className={Styles.message}>
        <div>{t('lastMessage')}</div>
        <Sanitized highlight={messageSearchWords} html={messagePreview} visible={messageVisible} tag="p" />
      </div>
      <div className={Styles.badge}>
        <img src={Shush} alt="Angel with index finger on lips asking you to keep her secrets" />
        <div className={Styles.name}>
          <div>{displayName}</div>
          <div>{t('openPrivateChat')}</div>
        </div>
        <time className={Styles.time} dateTime={new Date(message?.createdAt)?.toISOString()}>
          <div>{new Date(message?.createdAt)?.toLocaleString(i18n.language, { day: '2-digit', month: 'short' })}</div>
          <div>
            {new Date(message?.createdAt)?.toLocaleString(i18n.language, { hour: '2-digit', minute: '2-digit' })}
          </div>
        </time>
      </div>
    </button>
  );
};

export default PrivateMessageContent;
