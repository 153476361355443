import { Menu, MenuItem, IconButton, Tooltip, Typography } from '@mui/material';
import { Context } from '@assets/Icons';
import axiosCall from '@services/axios';
import React, { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IContext, IParticipant } from '@interfaces/Conversation';
import { createValidator, ValidatorFunctions } from '@common/Validators';
import { copyObj } from '@common/userMessagesUtils';

// Validate Props of a component
// returns boolean indicating if all props are valid
const validateProps = (props): boolean => {
  const { isUndefinedOrNull, isTypeOfArray, isTypeOfString, isNotEmptyString, isNotEmptyArray, isTypeOfObject, isNotUndefinedNorNull, isTypeOfNumber } =
    ValidatorFunctions;
  // Check undefined/null values
  // if null or undefined
  if (isUndefinedOrNull(props.errand.context) === true || isUndefinedOrNull(props.errand.activeContext) === true) {
    // Return empty JSX
    return false;
  }
  // props.errand.context shoud be a non-empty array AND props.errand.activeContext should be a non-empty string
  const allValsAreValid =
    isTypeOfArray(props.errand.context) === true &&
    isNotEmptyArray(props.errand.context) === true &&
    isTypeOfString(props.errand.activeContext) === true &&
    isNotEmptyString(props.errand.activeContext) === true;
  // if not valid, it means some value is invalid. Return empty JSX.
  if (allValsAreValid === false) {
    // if empty arr (NOTE: 10/09/2023)
    // now, if empty arr is set, it means there are no context
    // Previously it was null/undefined, so show no error if empty, just return false.
    if(isTypeOfArray(props.errand.context) === true && props.errand.context.length === 0) {
      return false;
    } else {
      console.error(
        'Some of the following vals are invalid: props.errand.context OR props.errand.activeContext [check its types for validity.], Props.errand:', copyObj(props.errand)
      );
    }
    return false;
  }

  // check props.errand.context arr values to have a valid _id, indexField : format , value props
  const contextElCheckerReducer = (valsValid: boolean[], currContextElement: IContext) => {
    const { _id, indexField } = currContextElement;
    const { value, attributes } = indexField;
    valsValid.push(
      // check _id
      isTypeOfString(_id) && isNotEmptyString(_id),
      // check indexField
      isNotUndefinedNorNull(indexField) && isTypeOfObject(indexField),
      // check value
      isNotUndefinedNorNull(value) && isNotEmptyString(value),
      // check attributes
      isNotUndefinedNorNull(attributes) && isTypeOfObject(attributes),
      // check format
      isNotUndefinedNorNull(attributes.format) && isTypeOfString(attributes.format) && isNotEmptyString(attributes.format)
    );
    return valsValid;
  };
  const contextElsValid = props.errand.context.reduce(contextElCheckerReducer, []);

  // if at least one false is present, it means that _id of  some element in props.errand.context array is invalid value
  if (contextElsValid.includes(false)) {
    console.error('_id of  some element in props.errand.context array is invalid value!');
    return false;
  }

  const activeContextArr = props.errand.context.filter(
    (x: IContext) => x._id.toString() === props.errand.activeContext
  );

  // check it has at least one value to be able to access[0]
  if (activeContextArr.length === 0) {
    // console.warn('no activeContext was found!');
    return false;
  }

  // check indexField prop
  const errandContextIndexField = activeContextArr[0].indexField;
  if (isUndefinedOrNull(errandContextIndexField) || isTypeOfObject(errandContextIndexField) === false) {
    console.error('indexField prop on first el of activeContextArr is invalid!');
    return false;
  }

  // check value AND attributes.format (value should be not null/undefined AND type of string AND not an empty one)
  if (
    isUndefinedOrNull(errandContextIndexField.value) ||
    isTypeOfString(errandContextIndexField.value) === false ||
    isNotEmptyString(errandContextIndexField.value) === false
  ) {
    console.error('errandContextIndexField.value is invalid!');
    return false;
  }

  // value for errandContextIndexField.attributes should not be undefined/null AND should be a typeof string AND not an empty one.
  // checking errandContextIndexField.attributes to be an object type to make sure it exists.
  if (
    isUndefinedOrNull(errandContextIndexField.attributes.format) ||
    isTypeOfObject(errandContextIndexField.attributes) === false ||
    isTypeOfString(errandContextIndexField.attributes.format) === false ||
    isNotEmptyString(errandContextIndexField.attributes.format) === false
  ) {
    console.error('errandContextIndexField.attributes.format OR errandContextIndexField.attributes is invalid!');
    return false;
  }

  // Check styles props
  // heightValue: number; fontSizeValue: number; svgHeightValue: number; widthValue: number
  const { heightValue, fontSizeValue, svgHeightValue, widthValue } = props.elementStyle;
  const numberValidator = (val) => createValidator(val, [isNotUndefinedNorNull, isTypeOfNumber]);
  // create arr of validators and then map through them and run to get validation result
  const validatorsResArr = [numberValidator(heightValue), numberValidator(fontSizeValue), numberValidator(svgHeightValue), numberValidator(widthValue)].map((validatorObj) => validatorObj.run());
  if(validatorsResArr.includes(false)) {
    console.error('Some value of props.elementStyle is invalid!');
    return false;
  }

  return true;
}

/**
 * 
 * @summary Main Component that renders Context Dropdown 
 */
function ContextDropdown(props: {
  errand: { _id: string; activeContext: string | IContext[]; context: IContext[], participants: IParticipant[]; };
  elementStyle: { heightValue: number; fontSizeValue: number; svgHeightValue: number; widthValue: number };
  operatorData: any
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  // Run Validation Check on Props.
  // Remove for now, this became unusable
  // const propsValid = validateProps(props);
  // if (!propsValid) {
  //   // console.warn(`Invalid context selection.`);
  // }

  const textStyle = {
    // this is the styling for the text in the context view
    padding: '0 0 0 10px',
  };

  const formatContext = (value: string, format: string) => {
    if (!value || !format) return '';
    let res = [];
    for (let i = 0; i < value.length; i++) {
      // Step through the format and build the result
      for (let j = 0; j < format.length; j++) {
        if (format[j] !== '#') {
          res.push(format[j]);
        } else {
          res.push(value[i]);
          i += 1;
        }
      }
    }
    return res.join('');
  };

  const handleClick = async (activeContext: { toString: () => any }) => {
    // Switch statements to handle each of the contexts as needed
    if (!activeContext) {
      return;
    }
    // Setting active context etc.
    let request = {
      url: `chat/${props.errand?._id}`,
      method: 'PUT',
      data: { activeContext: activeContext.toString() },
    };
    await axiosCall(request).catch((e) => console.error(e));
  };
  let activeContextArr;
  let errandContextIndexField;
  if (props.errand.context && props.errand.activeContext) {
    activeContextArr = props.errand.context.filter(
      (x: IContext) => x._id.toString() === props.errand.activeContext
    );
    if (activeContextArr.length > 0){
      errandContextIndexField = activeContextArr[0].indexField;
    }
  } 
  const { heightValue, fontSizeValue, svgHeightValue, widthValue } = props.elementStyle;

  return (
    <>
      <Tooltip title={t('changeContext')}>
        <Typography
          id="positioned-button"
          aria-controls={anchorEl ? 'positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? 'true' : undefined}
          onClick={(event) => {
            const handlerFunc = () => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            };

            handlerFunc();
          }}
          sx={{
            cursor: 'pointer',
            backgroundColor: 'var(--gray000)',
            color: 'var(--orange700)',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0',
            height: `${heightValue}px`,
            fontSize: `${fontSizeValue}px`,
            whiteSpace: 'nowrap',
            minWidth: 'auto',
            userSelect: 'none',
          }}
        >
          <Context height={svgHeightValue} width={svgHeightValue} />
          <span style={textStyle}>
            {errandContextIndexField ? 
              formatContext(errandContextIndexField.value, errandContextIndexField.attributes.format)
              : 'No Selection'
            }
          </span>
        </Typography>
      </Tooltip>

      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ 
          transform: 'translate(25px, -4px)',
          marginRight: '10px'
        }}
      >
        {!activeContextArr || activeContextArr.length === 0 &&
          <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            handleClick(null);
            setAnchorEl(null);
          }}
          sx={{
            cursor: 'pointer',
            color: 'var(--orange700)',
            minWidth: `auto`,
            transform: 'translateX(0px)',
            fontSize: `${fontSizeValue}px`,
            marginRight: '10px'
          }}
          dense={true}
          >
            {/* TODO update the scaling for when the icon is added to 
            menu */}
          {/* <IconButton sx={{
            cursor: 'pointer',
            color: 'var(--orange700)',
          }}
          edge="start"> 
            <Context height={svgHeightValue} width={svgHeightValue} />
          </IconButton> */}
          <span style={textStyle}>
            No Selection
          </span>
          </MenuItem>
        }
        {props.errand.context && props.errand.context.filter((ctx) => ctx.active).map((context: IContext, index: Key) => (
          <MenuItem
            key={index}
            onClick={(event) => {
              event.stopPropagation();
              handleClick(context?._id);
              setAnchorEl(null);
            }}
            sx={{
              cursor: 'pointer',
              color: 'var(--orange700)',
              width: `${widthValue}px`,
              minWidth: `auto`,
              marginRight: '10px',
              transform: 'translateX(0px)',
              fontSize: `${fontSizeValue}px`,
            }}
            dense={true}
          >
            {/* <IconButton sx={{
              cursor: 'pointer',
              color: 'var(--orange700)',
            }}
            edge="start"> 
              <Context height={svgHeightValue} width={svgHeightValue} />
            </IconButton> */}
            <span style={textStyle}>
              {formatContext(context.indexField.value, context.indexField.attributes.format)}
            </span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
export default ContextDropdown