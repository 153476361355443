import React, { useState } from 'react';
import { AccessType } from '@common/AccessType';
import Allign from '@styles/Allign';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import MessageTextStyle from '@styles/MessageTextStyle';
import Styles from '@styles/MessageContent/NumberedListMessageContent.module.css';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { useUserContext } from '@contexts/user';
import axiosCall from '@services/axios';
import { useRootContext } from '@contexts/RootContext';

type TNumberedListMessageContentProps = {
  message: IMessage;
  errand: IErrand;
  cancelAction: (key: any, clear: boolean) => void;
};

const MULTI_SELECT_FIELD_ATTRIBUTE = 'MULTI SELECT';

function findLastIndex<T>(arr: T[], predicate: (elem: T) => boolean): number {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i])) {
      return i;
    }
  }
  return -1;
}

export default function NumberedListMessageContent({ message, errand }: TNumberedListMessageContentProps) {
  const { _id: userId } = useUserContext();
  const rootContext = useRootContext();
  const [selectedOption, setSelectedOption] = useState<number | null>(
    message?.message?.selectedOption ? Number(message?.message?.selectedOption) : null
  );
  const lastMultiSelectMsgIdx = getLastMultiSelectMsgIdx();

  function getLastMultiSelectMsgIdx() {
    return findLastIndex(
      errand.messages ?? [],
      (message) =>
        message?.action?.fieldAttribute?.description === MULTI_SELECT_FIELD_ATTRIBUTE &&
        message?.messageType === 'Action'
    );
  }

  async function handleOptionClick(selection: number) {
    setSelectedOption(selection);
    await submitSelection(selection.toString());
  }

  function resetStates() {
    // Reset state action state to reset the chat input box
    rootContext.setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === message?.chat);

      if (chatObj) {
        chatObj.icon = '';
        chatObj.placeholder = '';
        chatObj.action = null;
      }

      return [...prev];
    });
  }

  async function updateSelectionOnMessage(selection: string) {
    const request = {
      url: `chat/${message?.chat}/message/${message?._id}`,
      method: 'PUT',
      data: {
        ...message,
        message: encodeURIComponent(JSON.stringify({ ...message.message, selectedOption: selection })),
      },
    };

    await axiosCall(request);
  }

  async function submitSelection(selection: string) {
    const multiSelectMessageIdx = getLastMultiSelectMsgIdx();
    const multiSelectMessage = errand.messages?.[multiSelectMessageIdx];
    const userAction = multiSelectMessage?.userAction;
    if (!userAction) {
      console.error('userAction not found for message:', multiSelectMessage);
      return;
    }

    try {
      const data = {
        sender: userId,
        senderType: 'User',
        accessType: AccessType.public,
        message: selection,
        messageType: 'Field',
        userAction: userAction._id,
      };

      const payload = { url: `chat/${errand?._id}/message`, method: 'POST', data };
      await Promise.all([axiosCall(payload), updateSelectionOnMessage(selection)]);
      // Reset the morph type
      resetStates();
    } catch (error) {
      console.error(error);
    }
  }

  if (!message) {
    console.log('message is not defined');
    return <></>;
  }

  if (typeof message?.message?.title !== 'string' || !Array.isArray(message?.message?.options)) {
    console.log('Invalid NumberedList message:', message);
    return <></>;
  }

  if (lastMultiSelectMsgIdx < 0) {
    console.log('No multi select action in messages');
    return <></>;
  }

  const { title, options } = message.message;
  return (
    <Allign
      sx={{
        display: 'flex',
        width: 'fit-content',
        flexDirection: 'column',
        maxWidth: '350px',
        minWidth: '85px',
        position: 'relative',
        alignItems:
          // On the user side, Team, and Group chats: only sender is on the right side
          // On the operator side, only sender (operator) and Morgan is on the right side
          message?.alignByCurrentUser ? 'flex-end' : 'flex-start',
      }}
    >
      <ChatBubbleStyle
        sx={{
          pointerEvents: 'none',
          width: 'fit-content',
          minWidth: '85px',
          maxWidth: '100%',
          borderColor: message.accessType === AccessType.internal ? 'var(--gray400)' : 'var(--orange700)',
          background:
            message.accessType === AccessType.internal
              ? 'var(--peach020)'
              : message.sentByCurrentUser
              ? 'var(--gray000)'
              : 'var(--peach600)',
        }}
      >
        <div className={Styles.titleContainer}>
          <MessageTextStyle>{title}</MessageTextStyle>
        </div>
        <div className={Styles.optionsContainer}>
          {options.map((option, idx) => (
            <div className={Styles.optionContainer} key={option}>
              <div
                onClick={() => handleOptionClick(idx + 1)}
                className={[Styles.optionNumber, ...[selectedOption === idx + 1 ? [Styles.selectedNumber] : []]].join(
                  ' '
                )}
              >
                {idx + 1}
              </div>
              <MessageTextStyle>{option}</MessageTextStyle>
            </div>
          ))}
        </div>
      </ChatBubbleStyle>
    </Allign>
  );
}
