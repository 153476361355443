/**
 * @file This file handles and renders the UI for the blockchain wallet.
 * @author Timur Bickbau
 */

import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Styles from '@styles/MorphWallet.module.css';
import { ArrowBack, ArrowDropDown, Clear, Delete, Error as ErrorIcon, QrCode, Refresh, Warning } from '@mui/icons-material';
import { Page, Color, Event, NftType, NftUserType, NftList, Token, MorphWalletUtils } from '@components/MorphWallet/MorphWalletUtils';
import axiosCall from '@services/axios';
import { useSocketContext } from '@contexts/socket';
import { isMobile } from '@common/deviceTypeHelper';
import { IErrand } from '@interfaces/Conversation';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import ThinClientUtils from '@common/ThinClientUtils';
import { LinearProgress } from '@mui/material';
import LanguageUtils from '@common/LanguageUtils';
import { UiText } from '@components/MorphWallet/MorphWalletUiText';
import { useUserContext } from '@contexts/user';
import { removeNftData } from '@storage/userStorage';

type MorphWalletProps = {
  errand: IErrand;
};

/**
 * Returns the full CDN URL for the gif to be displayed for ticket NFTs
 */
const getGifUrl = (eventName: string) => `${process.env.REACT_APP_MORGAN_CDN}/Images/${eventName}NftEventAngel.gif`;

const MorphWallet: React.FC<React.PropsWithChildren<MorphWalletProps>> = ({ errand }) => {
  /**
   * Testing Variables (be careful committing any changes to this section!)
   * */

  // if you want to test the mobile app flow in the web view (for mobile styling on desktop, open browser console and select the mobile view)
  const TESTING_MOBILE_APP_FLOW_ON_WEB = false; // expected value: false

  // if you want to preserve the wallet keys between web sessions (i.e. store in local storage instead of session storage)
  const PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS = false; // expected value: false

  // if you want to be able to see the private key in the secret phrase page
  const SHOW_PRIVATE_KEY = false; // expected value: false

  /**
   * Enums and Interfaces
   * */

  // whether an asynchronous function is still in progress, has succeeded, or has failed
  enum AsyncStatus {
    Waiting,
    Success,
    Failure
  }

  // the reason we are entering the access PIN
  enum AccessPinFunction {
    CreateAccount,
    RecoverAccount,
    ViewRecoveryPhrase,
    Login,
    UpdatingEnterOldPin,
    UpdatingEnterNewPin,
    DecryptQrCode,
    ApproveAccount,
    RemoveApprovedAccount,
    ToggleNftPublicness,
    BurnNft,
    MintBidNft,
    AcceptBid
  }
  const accessPinFunction = useRef<AccessPinFunction>(null);

  /**
   * Variables for Account Keys
   * */

  // the blockchain account's mnemonic phrase (should almost always be null), private key, and public address
  const keys = useRef<{ mnemonicPhrase: string[], privateKey: string[], publicAddress: string }>(null);

  // the blockchain account keys, encrypted behind the user's access PIN
  const encryptedKeys = useRef<string>(null);

  const mnemonicInputIds = MorphWalletUtils.getInputIds(MorphWalletUtils.NUM_MNEMONIC_WORDS);

  /**
   * Variables for Code Entry
   * */

  // the access PIN entered by the user
  const accessPinInput = useRef<number[]>(new Array(MorphWalletUtils.ACCESS_PIN_LENGTH));

  const accessPinInputIds = MorphWalletUtils.getInputIds(MorphWalletUtils.ACCESS_PIN_LENGTH);

  // which text we show above the access PIN entry
  const [accessPinText, setAccessPinText] = useState<UiText>(null);

  // we may want to revert to the previous access PIN text (from 'Please wait...') if there is an error after the access PIN is entered
  const prevAccessPinText = useRef<UiText>(null);

  // whether to show the "Clear PIN" button
  const [showClearPin, setShowClearPin] = useState<boolean>(false);

  // the link device code entered by the user
  const linkDeviceCodeInput = useRef<number[]>(new Array(MorphWalletUtils.LINK_DEVICE_CODE_LENGTH));

  // code for linking wallet to another device
  const linkDeviceCode = useRef<string[]>([]);

  const linkDeviceCodeInputIds = MorphWalletUtils.getInputIds(MorphWalletUtils.LINK_DEVICE_CODE_LENGTH);

  // the progress of the link device timer (note that maximum must be 100)
  const MAX_TIMER_PROGRESS = 100;
  const [linkDeviceTimerProgress, setLinkDeviceTimerProgress] = useState<number>(MAX_TIMER_PROGRESS);
  
  /**
   * Variables for NFT List View
   * */

  // all the NFTs of which the user is an owner or approved user
  const myNfts = useRef<Token[]>([]);

  // all the public NFTs on which a bid can be made
  const publicNfts = useRef<Token[]>([]);

  // all the NFTs which are bids on the currently selected NFT
  const bidsOnNft = useRef<Token[]>(null);

  // which NFT types we are currently displaying in the list view
  const [displayedNftTypes, setDisplayedNftTypes] = useState<string[]>(Object.keys(NftType));

  // array of preview fields that will be shown for each NFT
  interface PreviewFields {
    nftType: string,
    fields: string[]
  }
  const previewFields = useRef<PreviewFields[]>([]);
  const previewFieldsStatus = useRef<AsyncStatus>(AsyncStatus.Waiting);

  // whether we are currently refreshing the NFT list view
  const [refreshingNftList, setRefreshingNftList] = useState<boolean>(false);

  // reference to the div containing the footer prompts; for auto-scrolling
  const footerPromptsRef = useRef(null);

  // whether the user has touched the footer prompts on mobile (in which case we should stop auto-scrolling)
  const hasUserTouchedFooterPrompts = useRef<boolean>(false);

  /**
   * Variables for NFT Single View
   * */

  // the QR code for the NFT
  const [qrCode, setQrCode] = useState<string>(null);

  // the currently selected My NFT
  const selectedMyNft = useRef<Token>(null);

  // the currently selected Bid on NFT
  const selectedBidOnNft = useRef<Token>(null);

  // the currently selected public NFT
  const selectedPublicNft = useRef<Token>(null);

  // the accepted TRUBID NFT being viewed from a TRUAPP NFT
  const acceptedBidNft = useRef<Token>(null);

  // the attached TRUAPP NFT being viewed from a TRUBID NFT
  const attachedNft = useRef<Token>(null);

  // whether we are showing the wallet's public address in the "secret phrase" page (as opposed to hiding it behind a "Show" button)
  const [showPublicAddress, setShowPublicAddress] = useState<boolean>(false);

  // whether we are showing certain fields in the single-view metadata (as opposed to hiding them behind a "Show" button)
  const [showSmartContractAddress, setShowSmartContractAddress] = useState<boolean>(false);
  const [showConfirmationCode, setShowConfirmationCode] = useState<boolean>(false);

  // which text shows on the "Make NFT Public" / "Make NFT Private" button
  const [toggleNftPublicnessText, setToggleNftPublicnessText] = useState<UiText>(null);

  // the list of approved blockchain accounts for the currently selected NFT
  const [approvedAccounts, setApprovedAccounts] = useState<string[]>([]);

  // the entered account for approving on an NFT
  const accountToBeApproved = useRef<string>(null);

  // the approved account set to be removed from an NFT
  const approvedAccountToBeRemoved = useRef<string>(null);

  /**
   * Variables for Bidding
   * */

  // the NFT on which the user is about to bid
  const nftToBid = useRef<Token>(null);

  // the purchase price offered for bidding
  const purchaseOffer = useRef<number>(null);

  /**
   * Other Variables
   * */

  const socketContext = useSocketContext();
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const userId: string = useUserContext()?._id;

  // the translated text in the UI
  const currentLanguage = useRef<string>(null);
  const [translatedUiText, setTranslatedUiText] = useState<{ name: string, text: string }[]>([]);

  // the current page in the wallet UI
  const [activePage, setActivePage] = useState<Page>(Page.Welcome);

  // whether we are loading something, in which case all buttons should be disabled and UI should fade slightly
  const [loading, setLoading] = useState<boolean>(false);

  // error string for display in UI
  const [displayedError, setDisplayedError] = useState<UiText>(null);

  // active loan numbers held in context to use for the minting of NFTs via workflow
  const loanNumbers = useMemo(() => {
    if (!errand.context || errand.context?.length === 0) {
      return [];
    }
    
    return errand.context?.filter((x: any) => x?.indexField?.name === 'loanNumber').map((x: any) => x?.indexField?.value);
  }, [errand.context]);

  /**
   * Functions
   * */

  /**
   * @description Get the token being viewed in the single NFT view.
   * @param {NftList} nftList The list from which that NFT was selected; if null, assume viewing NFT from elsewhere (e.g., an attached NFT).
   * */
  const getSingleViewNft = useCallback((nftList: NftList = null) => {
    switch (activePage) {
      case Page.AcceptedBidSingleView:
        return acceptedBidNft.current;
      case Page.AttachedNftSingleView:
        return attachedNft.current;
      default:
        return getSelectedNft(nftList);
    }
  }, [activePage]);

  /**
   * @description Get the array of IDs for the input elements containing the digits of a code (e.g., access PIN).
   * */
  const getInputIds = useCallback(() => {
    switch (activePage) {
      case Page.AccessPin:
        return accessPinInputIds;
      case Page.LinkDeviceMobile:
        return linkDeviceCodeInputIds;
    }
  }, [activePage, accessPinInputIds, linkDeviceCodeInputIds]);

  /**
   * @description Get the array containing the user input for a code (e.g., access PIN).
   * */
  const getCodeInput = () => {
    switch (activePage) {
      case Page.AccessPin:
        return accessPinInput.current;
      case Page.LinkDeviceMobile:
        return linkDeviceCodeInput.current;
    }
  }

  /**
   * @description Reset the entered code (e.g., access PIN) when the user presses the 'Reset' button.
   * */
  const handleResetCode = useCallback(() => {
    const inputIds = getInputIds();

    // clear the UI
    for (const inputId of inputIds) {
      const codeInputElement = document?.getElementById(inputId) as HTMLInputElement;
      if (codeInputElement) {
        codeInputElement.value = '';
      }
    }

    // clear the code input
    switch (activePage) {
      case Page.AccessPin:
        accessPinInput.current = new Array(MorphWalletUtils.ACCESS_PIN_LENGTH);
        setShowClearPin(false);
        break;
      case Page.LinkDeviceMobile:
        linkDeviceCodeInput.current = new Array(MorphWalletUtils.LINK_DEVICE_CODE_LENGTH);
        break;
    }
    
    // refocus on the first digit (setTimeout necessary or refocus won't work)
    setTimeout(() => document.getElementById(inputIds[0])?.focus(), 1);
  }, [getInputIds, activePage]);

  /**
   * @description Go to the access PIN page and set the text that appears above the input boxes.
   * @param {AccessPinFunction} accessPinFunc The function we want to perform after the access PIN is entered.
   * */
  const goToAccessPin = useCallback((accessPinFunc: AccessPinFunction) => {
    // save the access PIN function, so we know what to do after the PIN is entered
    accessPinFunction.current = accessPinFunc;

    // if updating PIN (i.e. going from access PIN page to access PIN page)
    if (activePage === Page.AccessPin) {
      handleResetCode();
    }

    switch (accessPinFunction.current) {
      case AccessPinFunction.UpdatingEnterOldPin:
        setAccessPinText(UiText.EnterOldPin);
        break;
      case AccessPinFunction.UpdatingEnterNewPin:
      case AccessPinFunction.CreateAccount:
      case AccessPinFunction.RecoverAccount:
        setAccessPinText(UiText.EnterNewPin);
        break;
      default:
        setAccessPinText(UiText.EnterYourPin);
    }

    setShowClearPin(false);
    setActivePage(Page.AccessPin);
  }, [activePage, handleResetCode, AccessPinFunction]);

  /**
   * @description Get the translated version of a given UiText. Only call this from React components.
   * @param {UiText} text The translated text to be retrieved.
   * */
  const tWallet = useCallback((text: UiText) => {
    if (!text) {
      return '';
    }

    if (LanguageUtils.fetchLocalizationLanguageSetting() === 'en') {
      return text;
    }

    const uiTextKey = Object.keys(UiText).find(uiText => UiText[uiText] === text);
    return translatedUiText.find(translatedText => translatedText.name === uiTextKey)?.text || text;
  }, [translatedUiText]);

  /**
   * @description Get the QR code for an NFT, and set it to be shown.
   * */
  const showQrCode = useCallback(async () => {
    const token = getSingleViewNft(NftList.MyNfts);

    if (token && token.nftType === NftType.TICKET) {
      const { hashString } = token?.metaData;

      if (!hashString) {
        return;
      }

      // decode the NFT hashkey to get the event URL
      await axiosCall({
        url: `hashkey/${hashString}`
      }).then(async (decoded) => {
        // do not generate a QR code if there is no URL for the event
        if (!decoded?.parameters?.eventUrl) {
          return;
        }

        // generate a QR code for the url, passing the hash string, token ID, and contract address
        return await axiosCall({
          url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/generateQrCode`,
          method: 'POST',
          data: {
            url: `${decoded?.parameters?.eventUrl}/?nft=${hashString}&tokenId=${token.tokenId}&contractAddress=${token.contractAddress}`
          }
        });
      }).then((data) => {
        if (data?.qrCode) {
          // show the generated QR code
          setQrCode(data.qrCode);
        }
      })
    }
  }, [getSingleViewNft]);

  /**
   * @description Go to the single view of a listed NFT.
   * @param {NftList} nftList The NFT list which contains that NFT.
   * @param {Token} token The token being viewed; unnecessary if already retrievable from getSelectedNft().
   * @param {boolean} checkAcceptedBid Whether to check if the token has an accepted Purchase Bid.
   * */
  const goToNftSingleView = useCallback(async (nftList: NftList, token: Token = null, checkAcceptedBid = false) => {
    const log = 'goToNftSingleView';

    setLoading(true);

    const handleError = () => {
      setDisplayedError(UiText.errAccessToken);
      setLoading(false);
    }

    // this function is only applicable to single views of NFTs associated with an NftList
    if (nftList === null) {
      console.error(`${log}.1: No NFT list to come from.`);
      handleError();
      return;
    }

    // if coming directly from the list view (i.e., not coming 'back' from another NFT like an approved bid)
    if (token) {
      setSelectedNft(nftList, token);
    }

    if (!getSelectedNft(nftList)) {
      console.error(`${log}.2: No selected NFT.`);
      handleError();
      return;
    }

    // find the token we are going to
    const selectedNft = getSelectedNft(nftList);

    if (!selectedNft) {
      console.error(`${log}.3: Selected NFT not found.`);
      handleError();
      return;
    }

    if (!selectedNft?.contractAddress || !selectedNft?.nftType || !selectedNft?.owner) {
      console.error(`${log}.4: Missing data for token ${selectedNft.tokenId}.`);
      handleError();
      return;
    }

    // set the QR code for ticket NFTs
    if (selectedNft.nftType === NftType.TICKET) {
      await showQrCode();
    }

    // check if the NFT has an accepted bid
    if (checkAcceptedBid) {
      acceptedBidNft.current = null;

      try {
        const { bidTokenId, bidContractAddress, error } = await axiosCall({
          url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getAcceptedBid`,
          method: 'POST',
          data: {
            contractAddress: selectedNft?.contractAddress,
            tokenId: selectedNft?.tokenId
          }
        });

        // if the NFT has an accepted bid
        if (!error && (bidTokenId || bidTokenId === 0) && bidContractAddress) {
          acceptedBidNft.current = {
            tokenId: bidTokenId,
            contractAddress: bidContractAddress,
            name: MorphWalletUtils.getNftName(bidContractAddress, bidTokenId),
            nftType: NftType.TRUBID,
            userType: null,
            owner: null,
            metaData: null,
            isPublic: true,
            isBurned: false
          }
        }
      } catch (err) {
        console.log(`${log}.5: No accepted bid found.`);
      }
    }

    // proceed to single view
    switch (nftList) {
      case NftList.MyNfts:
        setToggleNftPublicnessText(selectedNft.isPublic ? UiText.MakeNftPrivate : UiText.MakeNftPublic);
        setActivePage(Page.MyNftsSingleView);
        break;
      case NftList.BidsOnNft:
        setActivePage(Page.BidsOnNftSingleView);
        break;
      case NftList.PublicNfts:
        setActivePage(Page.PublicNftsSingleView);
        break;
    }

    setLoading(false);
  }, [showQrCode]);

  /**
   * @description Accept a Purchase Bid on the selected TRU-Approval NFT.
   * */
  const acceptBid = useCallback(async () => {
    const log = 'acceptBid';

    try {
      const { error } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/acceptBid`,
        method: 'POST',
        data: {
          contractAddress: getSelectedNft(NftList.BidsOnNft)?.contractAddress,
          tokenId: getSelectedNft(NftList.BidsOnNft)?.tokenId,
          privateKey: keys.current?.privateKey
        }
      });

      keys.current.privateKey = [];

      if (error) {
        console.error(`${log}.1: ${error}.`);
        setDisplayedError(UiText.errAcceptBid);
        return;
      }
    } catch (err) {
      console.error(`${log}.2: ${err.message}`);
      setDisplayedError(UiText.errAcceptBid);
      return;
    }

    // manually add the accepted bid instead of pulling again
    acceptedBidNft.current = selectedBidOnNft.current;

    await goToNftSingleView(NftList.MyNfts);
  }, [goToNftSingleView]);

  /**
   * @description Pull the most recent version of the given NFT list.
   * @param {NftList} nftList The NFT list being pulled.
   * */
  const getNfts = useCallback(async (nftList: NftList) => {
    const log = 'getNfts';

    // get the NFT list from Malcom
    const requestNftList = async () => {
      const log = 'requestNftList';

      try {
        switch (nftList) {
          case NftList.MyNfts:
            // get the list of My NFTs
            return await axiosCall({
              url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getMyNfts/${keys.current?.publicAddress}`,
              method: 'GET'
            });
          case NftList.BidsOnNft:
            // get the bids on the currently selected My NFT
            return await axiosCall({
              url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getBidsOnNft`,
              method: 'POST',
              data: {
                contractAddress: selectedMyNft.current?.contractAddress,
                tokenId: selectedMyNft.current?.tokenId,
                publicAddress: keys.current?.publicAddress
              }
            });
          case NftList.PublicNfts:
            // get the list of public NFTs
            return await axiosCall({
              url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getPublicNfts`,
              method: 'GET'
            });
        }
      } catch (err) {
        return { error: `${log}: ${err.message}` };
      }
    }

    const listResponse = await requestNftList();

    if (listResponse?.error) {
      console.error(`${log}.1: ${listResponse.error}`);
      return false;
    }

    // where in the list response to look for the NFT list
    const getResponseNftList = () => {
      switch (nftList) {
        case NftList.MyNfts:
          return listResponse.nftIds;
        case NftList.BidsOnNft:
          return listResponse.bidTokenIds;
        case NftList.PublicNfts:
          return listResponse.nftList;
      }
    }

    // get data for each token and prepare to add it to the UI list
    const nfts: Token[] = [];
    for (const responseNftData of getResponseNftList()) {
      // get the NFT data
      const nftData = await MorphWalletUtils.getNftData(listResponse, responseNftData, nftList, keys.current?.publicAddress);

      // perform validations
      const validationResponse = MorphWalletUtils.validateNftData(nftData, nftList);
      if (validationResponse?.error) {
        console.log(`${log}.2: Token with ID ${nftData?.tokenId} returned error '${validationResponse.error}'. Continuing without token.`);
        continue;
      }

      // mark encrypted metadata as encrypted
      switch (nftData?.nftType) {
        case NftType.TRUAPP:
          nftData.metaData = { encryptedData: nftData.metaData }
          break;
        case NftType.TICKET:
          nftData.metaData.encryptedHashString = nftData.metaData?.hashString;
          delete nftData.metaData.hashString;
          break;
      }

      // get event data from Rest for ticket NFT
      if (nftData?.nftType === NftType.TICKET) {
        const eventName = nftData?.metaData?.eventName;
        const response = await MorphWalletUtils.getEventData(eventName);

        if (response?.error || !response?.data) {
          console.error(`${log}.3: ${response?.error || 'No event data found.'}`);
          continue;
        }

        nftData.metaData.eventDisplay = response.data?.eventDisplay || eventName;
        nftData.metaData.eventDate = response.data?.eventDate;
      }

      // prepare to add the NFT to the UI list
      nfts.push({
        ...nftData,
        tokenId: Number(nftData?.tokenId),
        name: MorphWalletUtils.getNftName(nftData?.contractAddress, nftData?.tokenId),
        isBurned: false
      });
    };

    // add the tokens to the UI list
    switch (nftList) {
      case NftList.MyNfts:
        myNfts.current = nfts;
        break;
      case NftList.BidsOnNft:
        bidsOnNft.current = nfts;
        break;
      case NftList.PublicNfts:
        publicNfts.current = nfts;
        break;
    }

    // sort by token name
    if (getNftList(nftList)?.length > 1) {
      getNftList(nftList)?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

    return true;
  }, []);

  /**
   * @description Go to the single view of a listed NFT.
   * @param {NftList} nftList The NFT list being viewed.
   * @param {boolean} reloadNfts Whether we need to request the NFT list from the blockchain.
   * */
  const goToNftListView = useCallback(async (nftList: NftList, reloadNfts: boolean = false) => {
    const log = 'goToNftListView';

    setLoading(true);

    const handleError = () => {
      setLoading(false);
      switch (nftList) {
        case NftList.MyNfts:
          setDisplayedError(UiText.errGetNfts);
          return;
        case NftList.BidsOnNft:
          setDisplayedError(UiText.errAccessBidsOnNft);
          return;
        case NftList.PublicNfts:
          setDisplayedError(UiText.errAccessMarketplace);
          return;
      }
    }

    // make sure user is logged in to a blockchain account (in case we are accessing this page from outside the UI)
    if (!keys.current?.publicAddress) {
      console.error(`${log}.1: No keys found.`);
      handleError();
      return false;
    }
    
    // wait up to 5 seconds for asynchronous getPreviewFields() to finish
    for (let i = 0; i < 10; i++) {
      if (previewFieldsStatus.current !== AsyncStatus.Waiting) {
        break;
      }

      // if not finished, wait 0.5 seconds
      await new Promise(resolve => setTimeout(resolve, 500));
    }

    // pull the most recent version of the given NFT list
    if (reloadNfts) {
      if (!(await getNfts(nftList))) {
        console.error(`${log}.2: Failed to load user NFTs.`);
        handleError();
        return false;
      }
    }

    // proceed to list view
    switch (nftList) {
      case NftList.MyNfts:
        // remove token from list, if just burned in single view
        if (activePage === Page.MyNftsSingleView && selectedMyNft.current?.isBurned) {
          const index = myNfts.current.findIndex(token => token.isBurned);
          if (index !== -1) {
            myNfts.current.splice(index, 1);
          }
        }

        setActivePage(Page.MyNftsListView);
        break;
      case NftList.BidsOnNft:
        setActivePage(Page.BidsOnNftListView);
        break;
      case NftList.PublicNfts:
        setActivePage(Page.PublicNftsListView);
        break;
    }

    setLoading(false);
    setDisplayedNftTypes(Object.keys(NftType));
    return true;
  }, [AsyncStatus.Waiting, activePage, getNfts]);

  /**
   * @description Burn an NFT.
   * */
  const burnNft = useCallback(async () => {
    const log = 'burnNft';

    const token = selectedMyNft.current;

    if (!token) {
      console.error(`${log}.1: Token not found.`);
      setDisplayedError(UiText.errBurnNft);
      return;
    }

    // burn the NFT
    try {
      const { error } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/burnNft`,
        method: 'POST',
        data: {
          contractAddress: token?.contractAddress,
          nftType: token?.nftType,
          tokenId: token?.tokenId,
          privateKey: keys.current?.privateKey
        }
      });

      keys.current.privateKey = [];

      if (error) {
        console.error(`${log}.2: ${error}`);
        setDisplayedError(UiText.errBurnNft);
        return;
      }
    } catch (err) {
      console.error(`${log}.3: ${err.message}`);
      setDisplayedError(UiText.errBurnNft);
      return;
    }

    await goToNftListView(NftList.MyNfts, true);
  }, [goToNftListView]);

  /**
   * @description Mint a Purchase Bid NFT, for the selected TRU-Approval NFT.
   * */
  const mintBidNft = useCallback(async () => {
    const log = 'mintBidNft';

    if (!nftToBid.current) {
      console.error(`${log}.1: Missing NFT on which to bid.`);
      setDisplayedError(UiText.errMintBidNft);
      return;
    }

    try {
      const { error } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/mintBidNft`,
        method: 'POST',
        data: {
          contractAddress: nftToBid.current?.contractAddress,
          tokenId: nftToBid.current?.tokenId,
          publicAddress: keys.current?.publicAddress,
          purchaseOffer: purchaseOffer.current
        }
      });

      if (error) {
        console.error(`${log}.2: ${error}.`);
        setDisplayedError(UiText.errMintBidNft);
        return;
      }
    } catch (err) {
      console.error(`${log}.3: ${err.message}`);
      setDisplayedError(UiText.errMintBidNft);
      return;
    }

    await goToNftListView(NftList.PublicNfts);
  }, [goToNftListView]);

  /**
   * @description Mint a TRU-Approval NFT.
   * */
  const mintNftFromLoanNumber = useCallback(async () => {
    const log = 'mintNftFromLoanNumber';

    try {
      await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/mintTruNft`,
        method: 'POST',
        data: {
          publicAddress: keys.current?.publicAddress,
          loanNo: loanNumbers[0],
          chatId: errand._id
        }
      });

      errandContext.setBypassToMintFlag(false);
    } catch (err) {
      console.error(`${log}: ${err.message}`);
    }
  }, [errand._id, errandContext, loanNumbers]);

  /**
   * @description Make a private NFT public, or vice versa.
   * */
  const toggleNftPublicness = useCallback(async () => {
    const log = 'toggleNftPublicness';
    const token = selectedMyNft.current;

    const handleError = () => {
      if (!token) {
        setDisplayedError(UiText.errRequest);
      } else {
        setDisplayedError(token.isPublic ? UiText.errMakeNftPrivate : UiText.errMakeNftPublic);
      }
    }

    if (!token) {
      console.error(`${log}.1: Token not found.`);
      handleError();
      return;
    }
    
    // toggle the NFT publicness (make it public if currently private, or vice versa)
    try {
      const { error } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/toggleNftPublicness`,
        method: 'POST',
        data: {
          contractAddress: token?.contractAddress,
          tokenId: token?.tokenId,
          privateKey: keys.current?.privateKey,
          makingPublic: !token?.isPublic
        }
      });

      keys.current.privateKey = [];

      if (error) {
        console.error(`${log}.2: ${error}`);
        handleError();
        return;
      }
    } catch (err) {
      console.error(`${log}.3: ${err.message}`);
      handleError();
      return;
    }

    // we could instead reload all the NFTs to pull the updated value but this would take a while for the same effect
    token.isPublic = !token.isPublic;
    setToggleNftPublicnessText(token.isPublic ? UiText.MakeNftPrivate : UiText.MakeNftPublic);
    await goToNftSingleView(NftList.MyNfts);
  }, [goToNftSingleView]);

  /**
   * @description Add or remove a blockchain account from the approved list of an NFT.
   * @param {boolean} isApproving Whether we are approving an account (otherwise, removing).
   * */
  const updateApprovedAccount = useCallback(async (isApproving: boolean) => {
    const log = 'updateApprovedAccount';
    const accountToBeUpdated = isApproving ? accountToBeApproved.current : approvedAccountToBeRemoved.current;

    setLoading(true);

    const handleError = () => {
      setDisplayedError(isApproving ? UiText.errApproveAccount : UiText.errRemoveApprovedAccount);
      setActivePage(Page.ApprovedAccounts);
    }

    const token = selectedMyNft.current;

    if (!token) {
      console.error(`${log}.1: Token not found.`);
      handleError();
      return;
    }

    if (!accountToBeUpdated) {
      console.error(`${log}.2: No account ${isApproving ? 'entered' : 'selected'}.`);
      handleError();
      return;
    }

    try {
      const { error } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/updateApprovedAccount`,
        method: 'POST',
        data: {
          contractAddress: token?.contractAddress,
          tokenId: token?.tokenId,
          privateKey: keys.current?.privateKey,
          publicAddress: accountToBeUpdated,
          isApproving
        }
      });

      keys.current.privateKey = [];

      if (error) {
        console.error(`${log}.3: ${error}`);
        handleError();
        return;
      }
    } catch (err) {
      console.error(`${log}.4: ${err.message}`);
      handleError();
      return;
    }

    // manually update the approved accounts list in the UI instead of pulling it again
    setApprovedAccounts(isApproving ? [...approvedAccounts, accountToBeUpdated] : approvedAccounts.filter(account => account !== accountToBeUpdated));

    accountToBeApproved.current = null;
    approvedAccountToBeRemoved.current = null;

    setActivePage(Page.ApprovedAccounts);
  }, [approvedAccounts]);

  /**
   * @description Mint or assign an event ticket NFT (depending on the event configuration)
   * */
  const handleTicketNft = useCallback(async (nftData: any) => {
    const log = 'handleTicketNft';

    try {
      const { ticketHash, eventName, eventConfig, numAdditionalGuests } = nftData;

      // Determine if a new NFT should be minted or an existing one should be transferred
      if (eventConfig?.valTicketMint === true) {
        const request = {
          ownerAddress: keys.current?.publicAddress,
          eventName,
          eventDisplay: eventConfig.eventDisplay || eventName,
          hashString: ticketHash,
          guestPrivateKey: keys.current?.privateKey,
          chatId: errand._id,
        };

        // Mint and transfer a ticket to the user's wallet
        await axiosCall({
          url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/mintTicketNft`,
          method: 'POST',
          data: request,
        });
      } else {
        const request: Record<string, any> = {
          eventName,
          ticketHash,
          guestPrivateKey: keys.current?.privateKey,
          eventDisplay: eventConfig.eventDisplay || eventName,
          contractAddress: eventConfig.contractAddress,
          chatId: errand._id,
        };
        if (typeof numAdditionalGuests === 'number') {
          request.additionalGuests = numAdditionalGuests;
        }

        // Transfer an already minted ticket to the user's wallet
        await axiosCall({
          url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/event-tickets/assignEventTicketAndHash`,
          method: 'POST',
          data: request,
        });
      }
    } catch (e) {
      console.error(`${log}: ${e}`);
      if (e instanceof window.Error) {
        const message = e.message.toLowerCase();
        if (message.includes("no tokens remaining")) {
          setDisplayedError(UiText.errNoTicketsRemaining);
        } else if (message.includes("user already owns nft")) {
          setDisplayedError(UiText.errUserAlreadyOwnsNft);
        } else {
          setDisplayedError(UiText.errTransferEventNft);
        }
      } else {
        setDisplayedError(UiText.errTransferEventNft);
      }
    } finally {
      errandContext.setBypassToMintFlag(false);
      errandContext.setNftData(null);
      // Remove the NFT data from local storage
      removeNftData();
    }

    keys.current.privateKey = [];
  }, [errand._id, errandContext]);

  /**
   * @description Prepare to view the user's recovery phrase.
   * @param requirePin Whether we want to ask for the access PIN before showing the secret phrase.
   * */
  const goToViewRecoveryPhrase = useCallback((requirePin: boolean = true) => {
    if (requirePin) {
      goToAccessPin(AccessPinFunction.ViewRecoveryPhrase);
    } else {
      setActivePage(Page.ViewRecoveryPhrase);
    }
  }, [AccessPinFunction.ViewRecoveryPhrase, goToAccessPin]);

  /**
   * @description Go to next page from the Recovery Phrase page, depending on where they are viewing the page from.
   * */
  const continueFromViewRecoveryPhrase = useCallback(() => {
    if (!encryptedKeys.current) {
      // if we are seeing the keys right after creating the wallet, set an access PIN before going to the NFT list
      goToAccessPin(AccessPinFunction.CreateAccount);
    } else {
      // if we are going back after having accessed this page from My NFTs, clear the keys
      keys.current.mnemonicPhrase = [];
      keys.current.privateKey = [];
      goToNftListView(NftList.MyNfts);
    }
  }, [AccessPinFunction.CreateAccount, goToAccessPin, goToNftListView]);

  /**
   * @description Decrypt the QR code for the currently selected NFT.
   * */
  const decryptQrCode = useCallback(async () => {
    const log = 'decryptQrCode';

    const response = await MorphWalletUtils.decryptData(selectedMyNft.current?.metaData?.encryptedHashString, keys.current?.privateKey);

    keys.current.privateKey = [];

    if (response?.error || !response?.decryptedData) {
      console.error(`${log}: ${response?.error || 'No data returned.'}`);
      setDisplayedError(UiText.errDecryptQrCode);
      return;
    }

    selectedMyNft.current.metaData.hashString = response.decryptedData;
    delete selectedMyNft.current.metaData.encryptedHashString;

    await goToNftSingleView(NftList.MyNfts);
  }, [goToNftSingleView]);

  /**
   * @description Attempt to decrypt or encrypt keys using the access PIN, and proceed to next page if successful.
   * */
  const submitAccessPin = useCallback(async (accessPin: string) => {
    const log = 'submitAccessPin';

    setDisplayedError(null);

    switch (accessPinFunction.current) {
      case AccessPinFunction.CreateAccount:
      case AccessPinFunction.RecoverAccount:
      case AccessPinFunction.UpdatingEnterNewPin:
        // encrypt the wallet keys behind the provided access PIN
        const encryptResponse = await MorphWalletUtils.encryptKeys(keys.current, accessPin);

        if (encryptResponse?.error || !encryptResponse?.encryptedKeys) {
          setDisplayedError(UiText.errSetAccessPin);
          return { error: `${log}.1: ${encryptResponse.error || 'No encrypted wallet keys.'}` };
        }

        encryptedKeys.current = encryptResponse.encryptedKeys;

        // store keys in local storage (mobile app) or session storage (web view)
        if (ThinClientUtils.isThinClient() || PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS) {
          localStorage.setItem(MorphWalletUtils.BLOCKCHAIN_KEYS, encryptedKeys.current);
          if (ThinClientUtils.isThinClient()) {
            window.dispatchEvent(new StorageEvent('storage', {
              key: MorphWalletUtils.BLOCKCHAIN_KEYS,
              newValue: encryptedKeys.current,
              oldValue: localStorage.getItem(MorphWalletUtils.BLOCKCHAIN_KEYS),
              url: window.location.href,
              storageArea: localStorage
            }));
          }
        } else {
          sessionStorage.setItem(MorphWalletUtils.BLOCKCHAIN_KEYS, encryptedKeys.current);
        }

        // clear the user's keys from memory after storing them
        keys.current.mnemonicPhrase = [];
        keys.current.privateKey = [];

        // store the user's public address in the database
        try {
          const { error } = await axiosCall({
            url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/addPublicAddress/${userId}`,
            method: 'POST',
            data: {
              publicAddress: keys.current?.publicAddress
            }
          });

          if (error) {
            setDisplayedError(UiText.errSetAccessPin);
            return { error: `${log}.2: ${error}` };
          }
        } catch (err) {
          setDisplayedError(UiText.errSetAccessPin);
          return { error: `${log}.3: ${err.message}` };
        }

        break;
      default:
        // decrypt the existing wallet keys using the provided access PIN
        const decryptResponse = await MorphWalletUtils.decryptKeys(encryptedKeys.current, accessPin);

        if (decryptResponse?.error || !decryptResponse?.decryptedKeys) {
          if (decryptResponse?.error?.includes('bad decrypt')) {
            setDisplayedError(UiText.errIncorrectAccessPin);
          } else {
            setDisplayedError(UiText.errSubmitAccessPin);
          }

          return { error: `${log}.4: ${decryptResponse?.error || 'No decrypted wallet keys.'}` };
        }

        const { decryptedKeys } = decryptResponse;

        if (decryptedKeys?.mnemonicPhrase && decryptedKeys?.privateKey && decryptedKeys?.publicAddress) {
          // for security, don't store any keys in memory that are not immediately needed
          switch (accessPinFunction.current) {
            case AccessPinFunction.ViewRecoveryPhrase:
            case AccessPinFunction.UpdatingEnterOldPin:
              keys.current = { ...decryptedKeys };
              break;
            case AccessPinFunction.Login:
              keys.current = {
                mnemonicPhrase: [],
                privateKey: [],
                publicAddress: decryptedKeys.publicAddress
              };
              break;
            default:
              keys.current = {
                mnemonicPhrase: [],
                privateKey: decryptedKeys.privateKey,
                publicAddress: decryptedKeys.publicAddress
              };
          }
        } else {
          setDisplayedError(UiText.errSubmitAccessPin);
          return { error: `${log}.5: No keys found.` };
        }
    }

    prevAccessPinText.current = accessPinText;
    setAccessPinText(UiText.PleaseWait);

    // continue to intended function
    switch (accessPinFunction.current) {
      case AccessPinFunction.CreateAccount:
      case AccessPinFunction.RecoverAccount:
      case AccessPinFunction.Login:
        if (errandContext.bypassToMintFlag) {
          if (errandContext.nftData) {
            await handleTicketNft(errandContext.nftData);
          } else {
            await mintNftFromLoanNumber();
          }
        }
        await goToNftListView(NftList.MyNfts, true);
        break;
      case AccessPinFunction.ViewRecoveryPhrase:
        goToViewRecoveryPhrase(false);
        break;
      case AccessPinFunction.UpdatingEnterOldPin:
        goToAccessPin(AccessPinFunction.UpdatingEnterNewPin);
        break;
      case AccessPinFunction.UpdatingEnterNewPin:
        await goToNftListView(NftList.MyNfts);
        break;
      case AccessPinFunction.DecryptQrCode:
        await decryptQrCode();
        break;
      case AccessPinFunction.ApproveAccount:
        await updateApprovedAccount(true);
        break;
      case AccessPinFunction.RemoveApprovedAccount:
        await updateApprovedAccount(false);
        break;
      case AccessPinFunction.ToggleNftPublicness:
        await toggleNftPublicness();
        break;
      case AccessPinFunction.BurnNft:
        await burnNft();
        break;
      case AccessPinFunction.AcceptBid:
        await acceptBid();
        break;
      case AccessPinFunction.MintBidNft:
        await mintBidNft();
        break;
    }

    handleResetCode();
  }, [AccessPinFunction, acceptBid, accessPinText, handleResetCode, burnNft, errandContext, decryptQrCode,
      goToAccessPin, goToNftListView, mintBidNft, goToViewRecoveryPhrase, handleTicketNft, userId,
      mintNftFromLoanNumber, toggleNftPublicness, updateApprovedAccount, PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS]);

  /**
   * @description Perform operations whenever the page changes.
   * */
  useLayoutEffect(() => {
    switch (activePage) {
      // don't reset error on pages with on-screen input as the page refreshes after wrong input (which resets the error)
      case Page.LinkDeviceMobile:
      case Page.RecoverAccount:
      case Page.ApprovedAccounts:  // don't reset error if updating approved account fails, since we immediately go from access PIN to approved accounts (which resets the error)
        break;
      default:
        setDisplayedError(null);
    }

    setShowPublicAddress(false);
    setShowSmartContractAddress(false);
    setShowConfirmationCode(false);
    prevAccessPinText.current = null;
    hasUserTouchedFooterPrompts.current = false;
  }, [activePage, AccessPinFunction.UpdatingEnterOldPin, errand._id]);

  /**
   * @description Load the wallet and check if we should proceed to account setup or login.
   * */
  const loadWallet = () => {
    const log = 'loadWallet';

    // translate and set the UI text
    translateUiText();

    // if keys exist in web localStorage (from previously setting PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS), remove
    if (!ThinClientUtils.isThinClient() && !PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS) {
      localStorage.removeItem(MorphWalletUtils.BLOCKCHAIN_KEYS);
    }

    // check if the user already has keys in storage
    const checkKeys = () => {
      if (ThinClientUtils.isThinClient() || PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS) {
        // remove keys encrypted with CryptoJS, which has been deprecated; applies to all wallets made on or before 11/05/24
        if (!localStorage.getItem(MorphWalletUtils.BLOCKCHAIN_KEYS)?.includes(':')) {
          localStorage.removeItem(MorphWalletUtils.BLOCKCHAIN_KEYS);
        }

        encryptedKeys.current = localStorage.getItem(MorphWalletUtils.BLOCKCHAIN_KEYS);
      } else {
        encryptedKeys.current = sessionStorage.getItem(MorphWalletUtils.BLOCKCHAIN_KEYS);
      }

      // this check is necessary for web view, even though we normally clear keys, because of PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS
      if (!encryptedKeys.current) {
        console.log(`${log}.1: No keys found.`);

        if (ThinClientUtils.isThinClient() || TESTING_MOBILE_APP_FLOW_ON_WEB) {
          const getPublicAddresses = async () => {
            const log = 'getPublicAddresses';

            // in mobile app, check if the user has previously created a wallet on another device
            try {
              const { walletAddresses } = await axiosCall({
                url: `user/${userId}`,
                method: 'GET'
              });

              if (walletAddresses?.length > 0) {
                setActivePage(Page.RecoverAccount);
                return;
              }
            } catch (err) {
              console.error(`${log}: ${err.message}`);
            }

            handleCreateWallet();
          }

          getPublicAddresses();
          return;
        }

        // in web view, generate and set code for linking device
        const generateLinkDeviceCode = async () => {
          // stop trying to generate a new link device code if we have already logged in
          if (encryptedKeys.current) {
            return;
          }

          linkDeviceCode.current = [];
          for (let i = 0; i < MorphWalletUtils.LINK_DEVICE_CODE_LENGTH; i++) {
            linkDeviceCode.current.push(String(Math.floor(Math.random() * 9)));
          }

          // generate new code every numSeconds seconds
          const numSeconds = 30;
          for (let seconds = 0; seconds < numSeconds; seconds++) {
            await new Promise(resolve => setTimeout(resolve, 1000));

            // update the progress timer every second
            setLinkDeviceTimerProgress(prevLinkDeviceTimerProgress => prevLinkDeviceTimerProgress - MAX_TIMER_PROGRESS/numSeconds);
          }

          generateLinkDeviceCode();
          setLinkDeviceTimerProgress(MAX_TIMER_PROGRESS);
        }

        generateLinkDeviceCode();

        // receive data from mobile app
        const receiveMobileData = (e: any) => {
          const log = 'receiveMobileData';
          const { linkDeviceInput, encryptedMobileKeys } = e?.data;
          
          const getError = () => {
            if (!linkDeviceInput) {
              return 'No link device code found.';
            } else if (!encryptedMobileKeys) {
              return 'No keys found.';
            } else if (linkDeviceInput !== linkDeviceCode.current.join('')) {
              return UiText.errLinkDeviceCode;
            }
          }

          const error = getError();

          // return response to the mobile app
          socketContext.messagesSocket.current?.emit('emit-blockchain-response', { error });

          if (error) {
            console.error(`${log}: ${error}`);
            return;
          }

          socketContext.messagesSocket.current?.off('blockchain-data-emitted', receiveMobileData);

          encryptedKeys.current = encryptedMobileKeys;

          // store the encrypted keys in the browser's storage
          if (PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS) {
            localStorage.setItem(MorphWalletUtils.BLOCKCHAIN_KEYS, encryptedMobileKeys);
          } else {
            sessionStorage.setItem(MorphWalletUtils.BLOCKCHAIN_KEYS, encryptedMobileKeys);
          }

          goToAccessPin(AccessPinFunction.Login); 
        }

        // listen for data from mobile app
        socketContext.messagesSocket.current?.on('blockchain-data-emitted', receiveMobileData);

        setActivePage(Page.LinkDeviceDesktop);
        return;
      }

      // if keys found, proceed to login
      console.log(`${log}.2: Keys found.`);
      goToAccessPin(AccessPinFunction.Login);
    }

    checkKeys();

    // begin asynchronously loading the preview fields for NFT list view
    getPreviewFields();
  }

  /**
   * @description Get the list of preview fields to show for each NFT type, which will appear in the list view.
   * */
  const getPreviewFields = async () => {
    previewFieldsStatus.current = AsyncStatus.Waiting;
    previewFields.current = await MorphWalletUtils.loadPreviewFields();
    previewFieldsStatus.current = AsyncStatus.Success;
  }

  /**
   * @description Recover the user's blockchain account, given their mnemonic recovery phrase.
   * */
  const handleRecoverAccount = useCallback(() => {
    const log = 'handleRecoverAccount';

    setDisplayedError(null);
    setLoading(true);

    // clear error indicators from previous input
    for (const mnemonicInputId of mnemonicInputIds) {
      document.getElementById(mnemonicInputId).style.borderColor = Color.Violet;
    }

    // check if any mnemonic words are missing
    for (const mnemonicInputId of mnemonicInputIds) {
      const element = document?.getElementById(mnemonicInputId) as HTMLInputElement;
      if (!element?.value) {
        // mark earliest missing word with red border
        element.style.borderColor = Color.Red;
        setDisplayedError(UiText.errMissingWord);
        setLoading(false);
        return;
      }
    }

    // get the mnemonic phrase as one string array from the input elements
    const getMnemonicPhraseInput = () => {
      const mnemonicPhraseInput: string[] = [];

      for (const mnemonicInputId of mnemonicInputIds) {
        if (document?.getElementById(mnemonicInputId)) {
          mnemonicPhraseInput.push((document?.getElementById(mnemonicInputId) as HTMLInputElement)?.value?.toLowerCase());
        }
      }

      return mnemonicPhraseInput.join(' ').split('');
    }

    const mnemonicPhraseInput = getMnemonicPhraseInput();

    // derive private key and public address from mnemonic phrase
    const response = MorphWalletUtils.getPrivateKeyFromPhrase(mnemonicPhraseInput);

    if (response?.error || !response?.privateKey || !response?.publicAddress) {
      const { error } = response;

      if (error?.startsWith(MorphWalletUtils.INVALID_WORD)) {
        // mark earliest invalid word (i.e. non-BIP39-compatible word) with red border
        document.getElementById(mnemonicInputIds[Number(error?.split(MorphWalletUtils.INVALID_WORD)[1])]).style.borderColor = Color.Red;
        setDisplayedError(UiText.errIllegalWord);
      } else if (error === MorphWalletUtils.INVALID_CHECKSUM) {
        setDisplayedError(UiText.errIncorrectPhrase);
      } else if (error) {
        console.error(`${log}.1: ${error}`);
      } else {
        console.error(`${log}.2: Missing keys.`);
      }

      setLoading(false);
      return;
    }

    keys.current = {
      mnemonicPhrase: mnemonicPhraseInput,
      privateKey: response.privateKey,
      publicAddress: response.publicAddress
    };

    setLoading(false);
    goToAccessPin(AccessPinFunction.RecoverAccount);
  }, [AccessPinFunction.RecoverAccount, goToAccessPin, mnemonicInputIds]);

  /**
   * @description Create a new blockchain wallet.
   * */
  const handleCreateWallet = useCallback(() => {
    const log = 'handleCreateWallet';

    // generate a new mnemonic phrase, private key, and public address
    const response = MorphWalletUtils.generateWallet();

    if (response?.error || !response?.mnemonicPhrase || !response?.privateKey || !response?.publicAddress) {
      console.error(`${log}: ${response.error || 'No keys found.'}`);
      setDisplayedError(UiText.errCreateWallet);
      return;
    }

    keys.current = {
      mnemonicPhrase: response.mnemonicPhrase,
      privateKey: response.privateKey,
      publicAddress: response.publicAddress
    };
    
    goToViewRecoveryPhrase(false);
  }, [goToViewRecoveryPhrase]);

  /**
   * @description Handle entry of mnemonic recovery phrase and navigation through input fields.
   */
  const handleEnterMnemonic = (e: any) => {
    if (e.key === 'Enter') {
      const currentInputNum = Number(document?.activeElement?.id.split('-').pop());

      // go to next square if not on last square, otherwise continue
      if (currentInputNum < MorphWalletUtils.NUM_MNEMONIC_WORDS - 1) {
        document.getElementById(mnemonicInputIds[currentInputNum + 1])?.focus();
      } else {
        handleRecoverAccount();
      }
    }
  }

  /**
   * @description Render the input boxes in which the user will enter their mnemonic recovery phrase.
   * */
  const renderMnemonicInputs = () => {
    let mnemonicInputRows = [];
    const mnemonicInputsPerRow = isMobile() ? (MorphWalletUtils.smallMobileScreen ? 2 : 3) : 6;
    const mnemonicInputWidth = MorphWalletUtils.smallMobileScreen ? 100 : 80;

    for (let row = 0; row < MorphWalletUtils.NUM_MNEMONIC_WORDS / mnemonicInputsPerRow; row++) {
      mnemonicInputRows.push(
        <div key={`mnemonic-input-row-${String(row)}`}
             className={Styles.row + ' ' + Styles.rightSlide}>
          {mnemonicInputIds.slice(row * mnemonicInputsPerRow, (row + 1) * mnemonicInputsPerRow).map((mnemonicInputId, index) => (
            <div key={mnemonicInputId}
                 className={Styles.row}
                 style={{ marginBottom: 5, alignItems: 'center' }}>
              <p style={{ color: Color.Orange, fontSize: '0.8rem', textAlign: 'center', width: 21, padding: 3, margin: 2 }}>
                {row * mnemonicInputsPerRow + index + 1}
              </p>
              <input
                id={mnemonicInputId}
                autoComplete='off'
                autoCorrect='off'
                autoFocus={mnemonicInputId === mnemonicInputIds[0]}
                onKeyDown={handleEnterMnemonic}
                className={Styles.input}
                style={{ borderColor: Color.Violet, width: mnemonicInputWidth }}
              />
            </div>
          ))}
        </div>
      )
    }

    return (
      <div className={Styles.column}>
        {mnemonicInputRows}
      </div>
    );
  }

  /**
   * @description Render the boxes in which the mnemonic recovery phrase will be displayed.
   * */
  const renderMnemonicDisplay = () => {
    let mnemonicDisplayRows = [];
    const mnemonicWordsPerRow = isMobile() ? 3 : 6;

    for (let row = 0; row < MorphWalletUtils.NUM_MNEMONIC_WORDS / mnemonicWordsPerRow; row++) {
      mnemonicDisplayRows.push(
        <div key={`mnemonic-display-row-${String(row)}`}
             className={Styles.row + ' ' + Styles.rightSlide}>
          {keys.current?.mnemonicPhrase?.join('').split(' ').slice(row * mnemonicWordsPerRow, (row + 1) * mnemonicWordsPerRow).map((word, index) => (
            <div key={word}
                 className={Styles.mnemonicWord}>
              <p style={{ color: Color.Orange, fontSize: '0.8rem', marginRight: 5 }}>
                {row * mnemonicWordsPerRow + index + 1}
              </p>
              <p style={{ color: Color.White, fontSize: '0.8rem' }}>
                {word}
              </p>
            </div>
          ))}
        </div>
      )
    }
    
    return (
      <div className={Styles.column}>
        {mnemonicDisplayRows}
      </div>
    );
  }

  /**
   * @description Move to the previous square in the code input, i.e., the one to the left.
   * */
  const goToPreviousCodeSquare = () => {
    // get the zero-indexed number of the currently focused code digit
    const currentInputNum = Number(document.activeElement.id.split('-').pop());

    // go to previous square (if not on first square)
    if (currentInputNum > 0) {
      document.getElementById(getInputIds()[currentInputNum - 1])?.focus();
    }
  }

  /**
   * @description Move to the next square in the code input, i.e., the one to the right.
   * */
  const goToNextCodeSquare = () => {
    // get the zero-indexed number of the currently focused code digit
    const currentInputNum = Number(document.activeElement.id.split('-').pop());

    // go to next square (if not on last square)
    if (currentInputNum < getInputIds()?.length - 1) {
      document.getElementById(getInputIds()[currentInputNum + 1])?.focus();
    }
  }

  /**
   * @description Handle deleting the code input or navigating through it with the arrow keys.
   * */
  const handleCodeKeyDown = (e: any) => {
    switch (e?.key) {
      case 'Backspace':
        // this seems to be the most intuitive way for the UI to handle backspace
        const activeElement = document?.activeElement as HTMLInputElement;
        const currentInputNum = Number(activeElement.id.split('-').pop());
        if (activeElement?.value) {
          activeElement.value = null;
          getCodeInput()[currentInputNum] = undefined;
        } else {
          goToPreviousCodeSquare();
          if (currentInputNum > 0) {
            getCodeInput()[currentInputNum - 1] = undefined;
          }
        }
        break;
      case 'ArrowLeft':
        goToPreviousCodeSquare();
        break;
      case 'ArrowRight':
        goToNextCodeSquare();
        break;
    }
  }

  /**
   * @description Handle entering code (i.e., access PIN); this only runs after the input has already been registered.
   * */
  const handleEnterCode = async (e: any) => {
    const log = 'handleEnterCode';
    const input = e?.nativeEvent?.data;

    if (input) {
      // numeric characters only
      if (input >= '0' && input <= '9') {
        getCodeInput()[Number(document.activeElement.id.split('-').pop())] = input;
        
        // replace digit with bullet point in UI, for privacy
        (document.activeElement as HTMLInputElement).value = '\u2022';

        goToNextCodeSquare();
      } else {
        // remove newly entered non-numeric character, if one was entered
        const activeElement = document.activeElement as HTMLInputElement;
        if (activeElement) {
          activeElement.value = activeElement?.value?.replace(input, '');
        }
      }
    }

    if (activePage === Page.AccessPin) {
      // show Clear button when there is something entered; otherwise don't show
      setShowClearPin(getCodeInput().filter(digit => digit !== undefined).length !== 0);
    }

    // continue only if user has finished entering code
    if (getCodeInput().includes(undefined)) {
      return;
    }

    setLoading(true);

    // get all the code digits as one string
    const code = getCodeInput().join('');

    // submit the code wherever needed
    const submitCode = async () => {
      switch (activePage) {
        case Page.AccessPin:
          // submit access PIN for encryption/decryption
          return await submitAccessPin(code);
        case Page.LinkDeviceMobile:
          // send link device code to web app
          return await handleLinkDevice(code);
      }
    }

    const response = await submitCode();

    if (response?.error) {
      handleResetCode();
      setLoading(false);
      console.error(`${log}: ${response.error}`);
      return;
    }

    setLoading(false);
  }

  /**
   * @description Translate and set the UI text.
   * */
  const translateUiText = useCallback(async () => {
    const log = 'translateUiText';
    const language = LanguageUtils.fetchLocalizationLanguageSetting() || 'en';

    if (language === currentLanguage.current || !LanguageUtils.fetchTranslationEnabledSetting()) {
      return;
    }

    currentLanguage.current = language;

    // always translate from the set of predetermined English texts so the meaning is not corrupted through multiple translations
    const englishTexts = Object.keys(UiText);
    const englishTextArr = [];

    // get all the UI text that we intend to translate
    englishTexts.forEach((text, index) => {
      englishTextArr.push({ name: text, text: Object.values(UiText)[index] });
    });

    if (language === 'en') {
      setTranslatedUiText(englishTextArr);
      return;
    }

    try {
      const translatedTextArr = [];

      // translate the UI text
      for (const englishText of englishTextArr) {
        translatedTextArr.push({
          name: englishText.name,
          text: await LanguageUtils.translateOne(englishText.text, language)
        });
      }

      // store the translated UI text
      setTranslatedUiText(translatedTextArr);
    } catch (err) {
      console.error(`${log}: ${err.message}.`);
      setTranslatedUiText(englishTextArr);
    }
  }, []);

  /**
   * @description Handle errors from operations performed after the access PIN is entered, and translation.
   * */
  useEffect(() => {
    // reset the access PIN and revert the text above the access PIN if an error occurs during access PIN entry
    if (activePage === Page.AccessPin && displayedError && accessPinText === UiText.PleaseWait) {
      setAccessPinText(prevAccessPinText.current);
      handleResetCode();
    }

    translateUiText();
  }, [activePage, displayedError, accessPinText, getSingleViewNft, handleResetCode, translateUiText]);

  /**
   * @description Go to previous page from access PIN input, depending on where they are viewing the page from.
   * */
  const goBackFromAccessPin = useCallback(async () => {
    setLoading(true);
    switch (accessPinFunction.current) {
      case AccessPinFunction.CreateAccount:
        goToViewRecoveryPhrase(false);
        break;
      case AccessPinFunction.RecoverAccount:
        setActivePage(Page.RecoverAccount);
        break;
      case AccessPinFunction.ViewRecoveryPhrase:
        await goToNftListView(NftList.MyNfts);
        break;
      case AccessPinFunction.UpdatingEnterOldPin:
      case AccessPinFunction.UpdatingEnterNewPin:
        await goToNftListView(NftList.MyNfts);
        break;
      case AccessPinFunction.ApproveAccount:
      case AccessPinFunction.RemoveApprovedAccount:
        setActivePage(Page.ApprovedAccounts);
        break;
      case AccessPinFunction.DecryptQrCode:
      case AccessPinFunction.ToggleNftPublicness:
      case AccessPinFunction.BurnNft:
        await goToNftSingleView(NftList.MyNfts);
        break;
      case AccessPinFunction.AcceptBid:
        await goToNftSingleView(NftList.BidsOnNft);
        break;
      case AccessPinFunction.MintBidNft:
        await goToNftSingleView(NftList.PublicNfts);
        break;
    }

    setLoading(false);
  }, [AccessPinFunction, goToNftListView, goToNftSingleView, goToViewRecoveryPhrase]);

  /**
   * @description Get the array of NFTs contained within a given NFT list.
   * @param {NftList} nftList The NFT list.
   * */
  const getNftList = (nftList: NftList) => {
    switch (nftList) {
      case NftList.MyNfts:
        return myNfts.current;
      case NftList.BidsOnNft:
        return bidsOnNft.current;
      case NftList.PublicNfts:
        return publicNfts.current;
    }
  }

  /**
   * @description Get the currently selected NFT.
   * @param {NftList} nftList The NFT list which contains that token.
   * */
  const getSelectedNft = (nftList: NftList) => {
    switch (nftList) {
      case NftList.MyNfts:
        return selectedMyNft.current;
      case NftList.BidsOnNft:
        return selectedBidOnNft.current;
      case NftList.PublicNfts:
        return selectedPublicNft.current;
    }
  }

  /**
   * @description Set the NFT currently selected from a list of tokens.
   * @param {NftList} nftList The NFT list which contains that token.
   * @param {Token} token The Token object.
   * */
  const setSelectedNft = (nftList: NftList, token: Token) => {
    switch (nftList) {
      case NftList.MyNfts:
        selectedMyNft.current = token;
        break;
      case NftList.BidsOnNft:
        selectedBidOnNft.current = token;
        break;
      case NftList.PublicNfts:
        selectedPublicNft.current = token;
        break;
    }
  }

  /**
   * @description Validate the code entered for linking a device (usually desktop), send it to the recipient device, and await possible errors.
   * @param {string} linkDeviceInput The code that was entered by the user to link the device. 
   * */
  const handleLinkDevice = useCallback(async (linkDeviceInput: string) => {
    const log = 'handleLinkDevice';

    setLoading(true);

    // validate provided link device code
    const response = MorphWalletUtils.validateCode(linkDeviceInput, MorphWalletUtils.LINK_DEVICE_CODE_LENGTH);
    if (response?.error) {
      setDisplayedError(UiText.errIllegalAccessPin);
      setLoading(false);
      return { error: `${log}.1: ${response.error}` };
    }

    if (!encryptedKeys.current) {
      setDisplayedError(UiText.errLinkDevice);
      setLoading(false);
      return { error: `${log}.2: No keys found.` };
    }

    // send code to device
    socketContext.messagesSocket.current?.emit('emit-blockchain-data', {
      linkDeviceInput,
      encryptedMobileKeys: encryptedKeys.current
    });

    // clear error from previous failed attempt
    setDisplayedError(null);

    // whether we have received a response from the device
    let responseReceived = false;

    // receive a response from the device
    const receiveResponse = (e: any) => {
      responseReceived = true;
      const log = 'receiveResponse';
      const { error } = e?.data;

      if (error) {
        // when the wallet is hardcoded to appear in ConversationFooter this may momentarily show before the correct code is received
        console.error(`${log}: ${error}`);
        setDisplayedError(error === UiText.errLinkDeviceCode ? UiText.errLinkDeviceCode : UiText.errLinkDevice);
      } else {
        setLoading(false);
        setActivePage(Page.LinkDeviceMobileSuccess);
      }
    }

    // listen for error from device
    socketContext.messagesSocket.current?.on('blockchain-response-emitted', receiveResponse);

    // wait up to 10 seconds for response
    for (let i = 0; i < 20; i++) {
      if (responseReceived) {
        break;
      }

      // if no response, wait 0.5 seconds
      await new Promise(resolve => setTimeout(resolve, 500));
    }

    // stop listening for error
    socketContext.messagesSocket.current?.off('blockchain-response-emitted', receiveResponse);

    if (!responseReceived) {
      setDisplayedError(UiText.errLinkDevice);
      setLoading(false);
      return { error: `${log}.3: No response from device.` };
    }
    
    handleResetCode();
    setLoading(false);
  }, [handleResetCode, socketContext.messagesSocket]);

  /**
   * @description Get the function that will run when the back button is pressed.
   * */ 
  const getBackFunc = useCallback(() => {
    switch (activePage) {
      case Page.ViewRecoveryPhrase:
        // only show back button if we are seeing keys from wallet, not from creation
        return encryptedKeys.current ? continueFromViewRecoveryPhrase : null;
      case Page.AccessPin:
        return accessPinFunction.current === AccessPinFunction.Login ? null : goBackFromAccessPin;
      case Page.MyNftsSingleView:
      case Page.LinkDeviceMobile:
      case Page.LinkDeviceMobileSuccess:
        return () => goToNftListView(NftList.MyNfts);
      case Page.BidsOnNftListView:
      case Page.AcceptedBidSingleView:
      case Page.AttachedNftSingleView:
      case Page.ApprovedAccounts:
        return () => goToNftSingleView(NftList.MyNfts);
      case Page.BidsOnNftSingleView:
        return () => goToNftListView(NftList.BidsOnNft);
      case Page.PublicNftsListView:
        return () => goToNftListView(NftList.MyNfts, true);
      case Page.PublicNftsSingleView:
        return () => goToNftListView(NftList.PublicNfts);
    }
  }, [AccessPinFunction, activePage, continueFromViewRecoveryPhrase,
      goBackFromAccessPin, goToNftListView, goToNftSingleView]);

  /**
   * @description Get the function that is run when the refresh button is pressed in the list view.
   * */
  const getRefreshNftListFunction = useCallback(() => {
    switch (activePage) {
      case Page.MyNftsListView:
        return () => goToNftListView(NftList.MyNfts, true);
      case Page.PublicNftsListView:
        return () => goToNftListView(NftList.PublicNfts, true);
      case Page.BidsOnNftListView:
        return () => goToNftListView(NftList.BidsOnNft, true);
    }
  }, [activePage, goToNftListView]);

  /**
   * @description Refresh the NFT list when the refresh button is pressed.
   * */
  const handleRefreshNftList = useCallback(async () => {
    setRefreshingNftList(true);

    const refreshNftListFunction = getRefreshNftListFunction();
    if (refreshNftListFunction) {
      await refreshNftListFunction();
    }

    setRefreshingNftList(false);
  }, [getRefreshNftListFunction]);

  /**
   * @description Get the blockchain accounts approved to view an NFT and then proceed to the Approved Accounts page.
   * */
  const goToApprovedAccounts = useCallback(async () => {
    const log = 'goToApprovedAccounts';

    setLoading(true);
    setDisplayedError(null);
    setApprovedAccounts([]);

    const handleError = () => {
      setDisplayedError(UiText.errGetApprovedAccounts);
      setLoading(false);
    }
    
    if (activePage !== Page.AccessPin) {
      accountToBeApproved.current = null;
    }

    const token = selectedMyNft.current;

    if (!token) {
      console.error(`${log}.1: Token not found.`);
      handleError();
      return;
    }

    // get the accounts approved on the NFT
    try {
      const { error, userList } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getApprovedAccounts`,
        method: 'POST',
        data: {
          contractAddress: token?.contractAddress,
          tokenId: token?.tokenId
        }
      });

      if (error) {
        console.error(`${log}.2: ${error}`);
        handleError();
        return;
      }

      const approvedAccountList = [];
      userList.forEach((account: any) => {
        if (account?.userApproved && account?.userAddress) {
          approvedAccountList.push(account.userAddress);
        }
      });

      setApprovedAccounts(approvedAccountList);
    } catch (err) {
      console.error(`${log}.3: ${err.message}`);
      handleError();
      return;
    }

    setActivePage(Page.ApprovedAccounts);
    setLoading(false);
  }, [activePage]);

  /**
   * @description Get the function that is triggered by a specific prompt.
   * @param {UiText} prompt The prompt that is being followed or clicked.
   * */
  const getPromptFunc = useCallback((prompt: UiText) => {
    switch (prompt) {
      case UiText.HelpBubbleTypePhrase:
        return handleRecoverAccount;
      case UiText.HelpBubblePhraseContinue:
        return continueFromViewRecoveryPhrase;
      case UiText.HelpBubbleApprovedAccounts:
        return goToApprovedAccounts;
      case UiText.HelpBubbleViewBids:
        return () => goToNftListView(NftList.BidsOnNft, true);
      case UiText.HelpBubbleViewAcceptedBid:
        return goToViewAcceptedBid;
      case UiText.HelpBubbleViewAttachedNft:
        return goToViewAttachedNft;
      case UiText.HelpBubbleAcceptBid:
        return () => goToAccessPin(AccessPinFunction.AcceptBid);
      case UiText.FooterUpdatePin:
        return () => goToAccessPin(AccessPinFunction.UpdatingEnterOldPin);;
      case UiText.FooterViewRecoveryPhrase:
        return () => goToViewRecoveryPhrase();
      case UiText.FooterLinkDevice:
        return () => setActivePage(Page.LinkDeviceMobile);
      case UiText.FooterGoToMarketplace:
        return () => goToNftListView(NftList.PublicNfts, true);
    }
  }, [AccessPinFunction, handleRecoverAccount, goToApprovedAccounts, goToNftListView,
      continueFromViewRecoveryPhrase, goToAccessPin, goToViewRecoveryPhrase ]);

  /**
   * @description Receive messages sent from the chat box.
   * */
  const receiveChatSend = useCallback((e: any) => {
    if (loading) {
      return;
    }

    if (e?.type !== Event.ChatSend) {
      return;
    }

    const input: string = e?.detail?.input?.toLowerCase();

    if (!input) {
      return;
    }

    if (input?.includes(tWallet(UiText.pBack))) {
      const backFunc = getBackFunc();

      if (backFunc) {
        backFunc();
        return;
      }
    }

    // in most cases, return if prompt triggers action (even if unsuccessful), break if not, so we can show the "unrecognized prompt" error when applicable
    switch (activePage) {
      case Page.ViewRecoveryPhrase:
        if (input.includes(tWallet(UiText.pContinue))) {
          getPromptFunc(UiText.HelpBubblePhraseContinue)();
        } else if (input.includes(tWallet(UiText.pLogin))) {
          setActivePage(Page.RecoverAccount);
        } else {
          break;
        }
        return;
      case Page.RecoverAccount:
        if (input.includes(tWallet(UiText.pContinue))) {
          getPromptFunc(UiText.HelpBubbleTypePhrase)();
        } else if (input.includes(tWallet(UiText.pCreate))) {
          handleCreateWallet();
        } else if (input.includes(tWallet(UiText.pDontHave)) || input.includes(tWallet(UiText.pCantFind))) {
          setDisplayedError(UiText.errLostPhrase);
        } else {
          break;
        }
        return;
      case Page.MyNftsListView:
        if (ThinClientUtils.isThinClient() || TESTING_MOBILE_APP_FLOW_ON_WEB) {
          if (input.includes(tWallet(UiText.pSecret)) || input.includes(tWallet(UiText.pAccess)) || input.includes(tWallet(UiText.pRecover)) ||
              input.includes(tWallet(UiText.pPhrase)) || input.includes(tWallet(UiText.pWords)) || input.includes(tWallet(UiText.pMnemonic))) {
            getPromptFunc(UiText.FooterViewRecoveryPhrase)();
          } else if (input.includes(tWallet(UiText.pPin)) || input.includes(tWallet(UiText.pChange)) || input.includes(tWallet(UiText.pUpdate))) {
            getPromptFunc(UiText.FooterUpdatePin)();
          } else if (input.includes(tWallet(UiText.pLink)) || input.includes(tWallet(UiText.pConnect)) ||
                     input.includes(tWallet(UiText.pDevice)) || input.includes(tWallet(UiText.pDesktop))) {
            getPromptFunc(UiText.FooterLinkDevice)();
          } else if (input.includes(tWallet(UiText.pRefresh))) {
            handleRefreshNftList();
          } else {
            break;
          }
        }
        return;
      case Page.MyNftsSingleView:
        switch (selectedMyNft.current?.nftType) {
          case NftType.TRUAPP:
            // if owner of NFT, allow viewing and updating approved accounts
            if (selectedMyNft.current?.userType === NftUserType.OWNER) {
              if (input.includes(tWallet(UiText.pApproved)) || input.includes(tWallet(UiText.pUsers))) {
                getPromptFunc(UiText.HelpBubbleApprovedAccounts)();
                return;
              }
            }

            // go to view accepted bid (if one exists)
            if (acceptedBidNft.current) {
              if (input.includes(tWallet(UiText.pAccepted))) {
                getPromptFunc(UiText.HelpBubbleViewAcceptedBid)();
                return;
              }
            }

            // if public, go to view bids
            if (selectedMyNft.current?.isPublic) {
              if (input.includes(tWallet(UiText.pBid))) {
                getPromptFunc(UiText.HelpBubbleViewBids)();
                return;
              }
            }

            break;
          case NftType.TRUBID:
            if (input.includes(tWallet(UiText.pAttached))) {
              getPromptFunc(UiText.HelpBubbleViewAttachedNft)();
              return;
            }

            break;
        }
        break;
      case Page.ApprovedAccounts:
        accountToBeApproved.current = input;
        goToAccessPin(AccessPinFunction.ApproveAccount);
        return;
      case Page.PublicNftsSingleView:
        setLoading(true);
        const token = getSingleViewNft(NftList.PublicNfts);

        // make a bid on a public TRUAPP NFT
        if (token.nftType === NftType.TRUAPP) {
          if (!input) {
            setDisplayedError(UiText.errNoPurchasePrice);
            setLoading(false);
            return;
          }
      
          // check that offer only includes digits, period, commas, and dollar sign
          if (input?.match(/[^\d.,$]/)) {
            setDisplayedError(UiText.errInvalidPurchasePrice);
            setLoading(false);
            return;
          }
      
          // remove commas and dollar sign from offer
          purchaseOffer.current = Number(input.replace(/[,$]/g, ''));
      
          nftToBid.current = token;
          goToAccessPin(AccessPinFunction.MintBidNft);
        }

        setLoading(false);
        return;
      case Page.BidsOnNftSingleView:
        if (input.includes(tWallet(UiText.pAccept))) {
          getPromptFunc(UiText.HelpBubbleAcceptBid)();
          return;
        }

        break;
    }

    setDisplayedError(UiText.errUnrecognizedPrompt);
  }, [activePage, AccessPinFunction, getSingleViewNft, goToAccessPin, getBackFunc, getPromptFunc,
      handleRefreshNftList, handleCreateWallet, tWallet, loading, TESTING_MOBILE_APP_FLOW_ON_WEB]);

  /**
   * @description Watch messages typed in the chat box.
   * */
  const receiveChatInput = useCallback((e: any) => {
    if (e?.type !== Event.ChatInput) {
      return;
    }

    const input: string = e?.detail?.input?.toLowerCase();

    if (!input) {
      return;
    }

    switch (activePage) {
      case Page.AccessPin:
        if (input[0] >= '0' && input[0] <= '9') {
          setDisplayedError(UiText.errTypePin);
        }
        return;
      case Page.LinkDeviceMobile:
        if (input[0] >= '0' && input[0] <= '9') {
          setDisplayedError(UiText.errTypeCode);
        }
        return;
      case Page.ViewRecoveryPhrase:
        // make sure the user has not accidentally started typing their recovery phrase; if so, warn them and delete it
        for (const mnemonicWord of keys.current?.mnemonicPhrase?.join('').split(' ')) {
          if (input?.includes(mnemonicWord)) {
            setDisplayedError(UiText.errTypePhrase);
            return;
          }
        }
        return;
    }
  }, [activePage]);

  /**
   * @description Handle use of the chat box with the wallet.
   */
  useEffect(() => {
    // listen for text sent from the chat box
    window.addEventListener(Event.ChatSend, receiveChatSend);

    // listen for text input from the chat box
    window.addEventListener(Event.ChatInput, receiveChatInput);

    return () => {
      window.removeEventListener(Event.ChatSend, receiveChatSend);
      window.removeEventListener(Event.ChatInput, receiveChatInput);
    };
  }, [receiveChatSend, receiveChatInput, setDisplayedError]);

  /**
   * @description Begin the flow for removing an approved blockchain account from an NFT.
   * @param {string} account The approved account being removed.
   * */
  const handleRemoveApprovedAccount = (account: string) => {
    setDisplayedError(null);
    approvedAccountToBeRemoved.current = account;
    goToAccessPin(AccessPinFunction.RemoveApprovedAccount);
  }

  /**
   * @description Render the error message that may appear on a page.
   * */
  const renderError = () => {
    return (
      <div style={{ fontSize: '0.8rem', marginTop: 10, marginBottom: 10 }}>
        {displayedError && (
          <div className={Styles.row}
               style={{ alignItems: 'center', border: `1px solid ${Color.Red}`, borderRadius: 5, padding: 3 }}>
            <ErrorIcon style={{ color: Color.Red, marginRight: 5 }}/>
            <div className={Styles.column}>
              {tWallet(displayedError)?.split('|')?.map((errorSentence) => (
                <p style={{ color: Color.White, textAlign: 'center', padding: 1 }}>
                  {errorSentence}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  /**
   * @description Render a help bubble with a warning symbol. This should be wrapped in a div with className={Styles.helpBubbleDiv}.
   * @param {UiText} text The text to be included in the help bubble.
   * @param {number} marginBottom The margin below the help bubble.
   * */
  const renderImportantHelpBubble = (text: UiText, marginBottom: number) => {
    return (
      <div className={Styles.helpBubble + ' ' + Styles.row}
           style={{ border: `2px solid ${Color.Red}`, alignItems: 'center', marginBottom }}>
        <Warning style={{ color: Color.Red, marginRight: 3 }}/>
        {tWallet(text)}
      </div>
    );
  }

  /**
   * @description Render the UI header with text and refresh button.
   * */
  const renderHeader = () => {
    // get the name and onClick function of the header button; if null, won't show header button
    const getHeaderButtonData = () => {
      if (!encryptedKeys.current) {
        switch (activePage) {
          case Page.ViewRecoveryPhrase:
            return { name: UiText.LogIn, func: () => setActivePage(Page.RecoverAccount) };
          case Page.RecoverAccount:
            return { name: UiText.CreateWallet, func: handleCreateWallet };
        }
      }
    }

    const headerButtonData = getHeaderButtonData();

    return (
      <div style={MorphWalletUtils.getHeaderStyling()}>
        {renderExitButton()}
        {/* header text and icon (i.e. "Angel Wallet") */}
        <div className={Styles.row}
             style={{ alignItems: 'center', position: 'absolute', zIndex: 1, top: 3,
                      left: (isMobile() ? 10 : 30) + (getBackFunc() ? MorphWalletUtils.UI.backButtonWidth : 0) }}>
          <img src={`${process.env.REACT_APP_MORGAN_CDN}/Images/Blockchain/WalletLockOrange.png`} alt='Lock Icon' />
          <p style={{ fontSize: '0.8rem', color: Color.White, marginTop: 5, marginLeft: 5 }}>
            {UiText.AngelWallet}
          </p>
        </div>
        {/* absolutely positioned parts of the header which we want to disable when something is loading */}
        <div style={{ opacity: loading && 0.5, pointerEvents: loading ? 'none' : 'auto' }}>
          {/* back arrow */}
          {getBackFunc() && (
            <div onClick={getBackFunc()}
                 className={Styles.backButton}
                 style={{ borderRadius: `${MorphWalletUtils.UI.borderTopRadius}px 0px`, minWidth: MorphWalletUtils.UI.backButtonWidth }}>
              <ArrowBack style={{ width: 20 }}/>
            </div>
          )}
          {/* refresh button (only on list views) */}
          {getRefreshNftListFunction() && (
            <div style={{ position: 'absolute', top: 5, right: isMobile() ? 10 : 20 }}>
              {refreshingNftList ? (
                <p style={{ marginTop: 10, fontSize: '0.8rem' }}>
                  {tWallet(UiText.Refreshing)}
                </p>
              ) : (
                <Refresh onClick={handleRefreshNftList}
                         className={Styles.refreshButton}/>
              )}
            </div>
          )}
          {/* header button (e.g. "Log In", "Create Wallet") */}
          {headerButtonData && (
            <button className={Styles.headerButton}
                    onClick={headerButtonData.func}
                    style={{ color: Color.White, right: isMobile() ? 15 : 30 }}>
              {tWallet(headerButtonData.name)}
            </button>
          )}
        </div>
      </div>
    );
  }

  /**
   * @description Handle autoscroll of footer prompts.
   * */
  useEffect(() => {
    if (activePage === Page.MyNftsListView) {
      // on mobile, automatically scroll the footer prompts so user knows they're scrollable
      const autoScrollFooterPrompts = async () => {
        const footerPromptsDiv = footerPromptsRef.current;

        // wait 3 seconds before beginning to auto-scroll
        await new Promise(resolve => setTimeout(resolve, 3000));

        while (footerPromptsDiv && activePage === Page.MyNftsListView) {
          // if the user has touched the footer prompts or we have reached the end of the prompts, stop auto-scrolling
          if (hasUserTouchedFooterPrompts.current || footerPromptsDiv.scrollLeft + footerPromptsDiv.clientWidth >= footerPromptsDiv.scrollWidth - 1) {
            return;
          }

          // auto-scroll
          footerPromptsDiv.scrollLeft = footerPromptsDiv.scrollLeft + 1;
          await new Promise(resolve => setTimeout(resolve, 100));
        }
      }

      if (isMobile()) {
        autoScrollFooterPrompts();
      }
    }
  }, [activePage]);

  /**
   * @description Render the footer with the suggested prompts.
   * */
  const renderFooter = () => {
    // get the prompts that we want to appear in the footer; will appear in exact listed order (or will not appear at all, if none provided)
    const getFooterPrompts = () => {
      if (activePage === Page.MyNftsListView) {
        if (ThinClientUtils.isThinClient() || (isMobile() && TESTING_MOBILE_APP_FLOW_ON_WEB)) {
          return [
            UiText.FooterViewRecoveryPhrase,
            UiText.FooterLinkDevice,
            UiText.FooterUpdatePin
          ];
        }

        // TODO: add scrollbar to desktop before adding any new prompts
        return [
          UiText.FooterViewRecoveryPhrase
        ];
      }
    }

    return (
      <>
        {getFooterPrompts() && (
          <div className={Styles.column}
               style={{ backgroundColor: Color.DarkBlue, height: isMobile() ? 50 : 42, fontSize: '0.8rem', paddingTop: 12, paddingBottom: isMobile() && 3,
                        marginBottom: !isMobile() && -20, width: MorphWalletUtils.getMorphWalletWidth(), marginLeft: -MorphWalletUtils.UI.paddingSide, zIndex: 2 }}>
            <div style={{ position: 'absolute', bottom: isMobile() ? 34 : 23, color: Color.Orange, fontSize: '0.8rem', marginBottom: 5,
                          borderRadius: isMobile() ? 10 : 20, padding: isMobile() ? '4px 12px' : '6px 50px', backgroundColor: Color.DarkBlue }}>
              <p style={{ opacity: loading && 0.5 }}>
                {tWallet(getFooterPrompts()?.length === 1 ? UiText.FooterTryPrompt : UiText.FooterTryPrompts)}
              </p>
            </div>
            <div ref={footerPromptsRef}
                 className={Styles.row + ' ' + Styles.footerPrompts}
                 onTouchStart={() => { hasUserTouchedFooterPrompts.current = true; }}
                 style={{ maxWidth: MorphWalletUtils.getMorphWalletWidth() * (isMobile() ? 0.9 : 0.8), zIndex: 2 }}>
              {getFooterPrompts().map((prompt) => (
                <button key={prompt}
                        className={Styles.footerPrompt}
                        disabled={loading}
                        onClick={getPromptFunc(prompt)}
                        style={{ maxHeight: 30, opacity: loading && 0.5 }}>
                  {tWallet(prompt)}
                </button>
              ))}
            </div>
          </div>
        )}
        {!isMobile() && (
          <>
            <div style={{ position: 'absolute', backgroundColor: getFooterPrompts() ? Color.DarkBlue : Color.Blue, height: 30, bottom: -10,
                          width: MorphWalletUtils.getMorphWalletWidth() * 0.8, left: 0, right: 0, marginInline: 'auto', zIndex: 1 }}/>
            <div style={{ position: 'absolute', backgroundColor: getFooterPrompts() ? Color.DarkBlue : Color.Blue, width: 20, borderRadius: '100%',
                          height: 30, bottom: -10, left: -MorphWalletUtils.getMorphWalletWidth() * 0.8, right: 0, marginInline: 'auto', zIndex: 1 }}/>
            <div style={{ position: 'absolute', backgroundColor: getFooterPrompts() ? Color.DarkBlue : Color.Blue, width: 20, borderRadius: '100%',
                          height: 30, bottom: -10, right: -MorphWalletUtils.getMorphWalletWidth() * 0.8, left: 0, marginInline: 'auto', zIndex: 1 }}/>
          </>
        )}
      </>
    );
  }

  /**
   * @description Render the exit button on the top of the blockchain UI.
   */
  const renderExitButton = () => {
    const handleExit = () => {
      errandContext.setMorphType(MorphType.None);
      window.removeEventListener(Event.ChatSend, receiveChatSend);
      window.removeEventListener(Event.ChatInput, receiveChatInput);
    }

    return (
      <div onClick={handleExit}
           className={Styles.exitButton}>
        <ArrowDropDown style={{ margin: -5, zIndex: 3, fontSize: '1.6rem' }}/>
        <div style={{ backgroundColor: Color.White, borderColor: Color.DarkBlue, borderLeft: '1px solid',
                      borderRadius: '100%', width: 6, height: 6, position: 'absolute', top: 0, left: -2 }}/>
        <div style={{ backgroundColor: Color.White, borderColor: Color.DarkBlue, borderRight: '1px solid',
                      borderRadius: '100%', width: 6, height: 6, position: 'absolute', top: 0, right: -2 }}/>
        <div style={{ backgroundColor: Color.White, borderColor: Color.DarkBlue, borderTop: '1px solid', width: 50, height: 6, position: 'absolute', top: 0 }}/>
        <div style={{ backgroundColor: Color.DarkBlue, width: 18, height: 15, position: 'absolute', bottom: 0, left: -2, borderTopRightRadius: '100%' }}/>
        <div style={{ backgroundColor: Color.DarkBlue, width: 18, height: 15, position: 'absolute', bottom: 0, right: -2, borderTopLeftRadius: '100%' }}/>
        <div style={{ backgroundColor: Color.DarkBlue, width: 52, height: 8, position: 'absolute', bottom: -1, left: -1 }}/>
        <div style={{ backgroundColor: Color.White, width: 12, height: 6, position: 'absolute',
                      bottom: 3.5, left: 14, borderBottomLeftRadius: '100%', zIndex: 1 }}/>
        <div style={{ backgroundColor: Color.White, width: 12, height: 6, position: 'absolute',
                      bottom: 3.5, right: 14, borderBottomRightRadius: '100%', zIndex: 1 }}/>
      </div>
    );
  }

  /**
   * @description Render the given list of NFTs.
   * @param {NftList} nftList The NFT list to render.
   * */
  const renderNftListView = (nftList: NftList) => {
    // whether the NFT list has any tokens in it
    const isNftListPopulated = () => {
      return getNftList(nftList)?.length > 0;
    }

    // the text to show above (or instead of) the NFT list
    const getListHeaderText = () => {
      switch (nftList) {
        case NftList.MyNfts:
          return isNftListPopulated() ? UiText.HeaderMyNfts : UiText.HeaderNoMyNfts;
        case NftList.BidsOnNft:
          return isNftListPopulated() ? UiText.HeaderBids : UiText.HeaderNoBids;
        case NftList.PublicNfts:
          return isNftListPopulated() ? UiText.HeaderPublicTokens : UiText.HeaderNoPublicTokens;
      }
    }

    // check whether the given token should be shown in the list
    const showToken = (token: Token) => {
      // only show tokens with NFT type selected in the filter
      if (!displayedNftTypes.includes(token.nftType)) {
        return false;
      }

      // prevent user from seeing their own NFT in the marketplace
      if (nftList === NftList.PublicNfts && token.owner === keys.current?.publicAddress) {
        return false;
      }

      return true;
    }

    const renderScrollableNftList = () => {
      if (!isNftListPopulated() || !displayedNftTypes) {
        return <></>;
      }

      const nftsToDisplay = getNftList(nftList)?.filter(token => showToken(token));
      const numNftsPerRow = isMobile() ? 1 : 2;
      const nftWidth = isMobile() ? (MorphWalletUtils.getMorphWalletWidth() - MorphWalletUtils.UI.paddingSide * 2) * 0.85 : 300;
      const nftRows = [];

      // render the rows of NFTs in the scrollable list
      let i = 0;
      while (i < nftsToDisplay.length) {
        const nftRow = [];

        // add each NFT to the row of NFTs
        do {
          const token = nftsToDisplay[i++];

          // render the individual NFT as it will appear in the list view
          const renderIndividualNft = () => {
            switch (token.nftType) {
              case NftType.TICKET:
                const metaData = MorphWalletUtils.compileMetaData(token.metaData, null);
                const scanned = metaData.find(metaData => metaData.name === UiText.mdScanned)?.value;

                return (
                  <>
                    <div className={Styles.Column}>
                      <div className={Styles.rowSpaceBetween}
                           style={{ width: nftWidth * 0.9, textAlign: 'left', padding: 10 }}>
                        <div>
                          <p style={{ fontSize: '0.7rem', marginBottom: 5 }}>
                            {tWallet(UiText.mdEventName)}
                          </p>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                              {token.metaData.eventDisplay}
                            </p>
                          </div>
                        </div>
                        <img src={getGifUrl(token.metaData.eventName)} style={{ width: '65px', height: '65px' }} alt='NFT gif'/>
                      </div>
                      <hr style={{ border: 'dashed 1px', color: '#4263CF', marginLeft: 12, marginRight: 12 }}/>
                      <div className={Styles.row}
                           style={{ fontSize: '0.8rem', marginTop: 2, paddingLeft: 8 }}>
                        <div className={Styles.row}
                             style={{ width: nftWidth * 0.5 }}>
                          <p style={{ padding: 6, opacity: 0.5 }}>
                            ID
                          </p>
                          <p style={{ padding: 6, minWidth: 50 }}>
                            {token.tokenId}
                          </p>
                        </div>
                        {scanned !== null && (
                          <div className={Styles.row}>
                            <p style={{ padding: 6, opacity: 0.5 }}>
                              {tWallet(UiText.mdScanned)}
                            </p>
                            <p style={{ padding: 6 }}>
                              {scanned}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div style={{ position: 'absolute', zIndex: 1, width: 15, height: 15, backgroundColor: Color.Blue,
                                  left: -5, top: 56.5, borderRadius: '100%', cursor: 'pointer', userSelect: 'none' }}/>
                    <div style={{ position: 'absolute', zIndex: 1, width: 15, height: 15, backgroundColor: Color.Blue,
                                  right: -5, top: 56.5, borderRadius: '100%', cursor: 'pointer', userSelect: 'none' }}/>
                  </>
                );
              default:
                return (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }}>
                      <p style={{ fontSize: '0.6rem' }}>
                        {tWallet(MorphWalletUtils.getNftUserTypeDisplay(token.userType))}
                      </p>
                      <p style={{ fontSize: '0.6rem' }}>
                        {token.name}
                      </p>
                    </div>
                    <div className={Styles.row}
                         style={{ justifyContent: 'center', alignItems: 'center' }}>
                      <p>
                        {tWallet(MorphWalletUtils.getNftTypeDisplay(token.nftType))}
                      </p>
                      <img src={`${process.env.REACT_APP_MORGAN_CDN}/Images/Blockchain/TruAppCoin.png`} alt='Lock Icon' style={{ width: 30, marginLeft: 5 }}/>
                    </div>
                    {previewFields.current?.length > 0 && (
                      renderNftMetaData(nftList, token, previewFields.current?.find(previewFields => previewFields.nftType === token.nftType)?.fields, true)
                    )}
                  </>
                );
            }
          }

          const getNftStyling = () => {
            switch (token.nftType) {
              case NftType.TICKET:
                return Styles.listTicketItem;
              default:
                return Styles.listItem;
            }
          }

          nftRow.push(
            <div key={`token-${token.tokenId}`}
                 onClick={() => goToNftSingleView(nftList, token, true)}
                 className={getNftStyling()}
                 style={{ minWidth: nftWidth, maxWidth: nftWidth }}>
              {renderIndividualNft()}
            </div>
          );

          // TODO: after last NFT, maybe show a dotted line "blank" NFT to incentivize minting more NFTs
        } while (i % numNftsPerRow !== 0 && i < nftsToDisplay.length);

        nftRows.push(
          <div key={`nft-row-${i}`}
               className={Styles.row}
               style={{ marginRight: !isMobile() && 3 }}>
            {nftRow}
          </div>
        );
      }

      return (
        <div className={Styles.scrollableList + (isMobile() ? '' : ` ${Styles.scrollBar}`)}>
          {nftRows}
        </div>
      );
    }

    return (
      <div className={Styles.column}>
        <p style={{ fontSize: '1.0rem', marginTop: 12, marginBottom: activePage === Page.PublicNftsListView && (isNftListPopulated() ? 10 : 20) }}>
          {tWallet(getListHeaderText())}
        </p>
        {/* TODO: show the filter on Page.PublicNftsListView when we have more types of NFTs in the marketplace */}
        {activePage === Page.MyNftsListView && isNftListPopulated() && (
          renderNftFilter(nftList)
        )}
        {activePage === Page.MyNftsListView && !isNftListPopulated() && UiText.HelpBubbleEmptyWallet && (
          <div className={Styles.helpBubbleDiv + ' ' + Styles.helpBubble}
               style={{ marginTop: 15, marginBottom: 10 }}>
            {tWallet(UiText.HelpBubbleEmptyWallet)}
          </div>
        )}
        {renderScrollableNftList()}
        <div style={{ marginBottom: 10 }}>
          {renderError()}
        </div>
      </div>
    );
  }

  /**
   * @description Set up and render the filter for the NFT list view.
   * @param {NftList} nftList The list containing the NFTs being filtered.
   * */
  const renderNftFilter = (nftList: NftList) => {
    const options = {};

    // count how many times each NFT type appears
    getNftList(nftList).forEach((token) => {
      options[token.nftType] ? options[token.nftType]++ : (options[token.nftType] = 1);
    });

    const ALL_NFTS_TEXT = tWallet(UiText.FilterAllNfts);

    // apply the filter to the NFT list
    const filterNftList = (e: any) => {
      const selectedOption = e?.target?.value;
      const nftTypeDisplay = selectedOption?.substring(0, selectedOption?.lastIndexOf(' '));

      if (!nftTypeDisplay || nftTypeDisplay === ALL_NFTS_TEXT) {
        // show all NFTs
        setDisplayedNftTypes(Object.keys(NftType));
      } else {
        // get NFT type from display name and set it
        Object.keys(NftType).forEach((nftType: NftType) => {
          if (MorphWalletUtils.getNftTypeDisplay(nftType) === nftTypeDisplay) {
            setDisplayedNftTypes([nftType]);
          }
        })
      }
    }
    
    return (
      <div style={{ marginBottom: 5, marginTop: 10 }}>
        <select onChange={filterNftList}
                className={Styles.listFilter}
                style={{ paddingLeft: 3 }}>
          <option style={{ color: Color.White }}>
            {ALL_NFTS_TEXT} ({getNftList(nftList)?.length})
          </option>
          {Object.keys(options).map((nftType: NftType, index) => (
            <option id={nftType}
                    key={nftType}
                    style={{ color: Color.White }}>
              {`${tWallet(MorphWalletUtils.getNftTypeDisplay(nftType))} (${Object.values(options)[index]})`}
            </option>
          ))}
        </select>
      </div>
    );
  }

  /**
   * @description Get and render the metadata for an NFT.
   * @param {NftList} nftList The list from which that NFT was selected; if null, assume viewing NFT from elsewhere (e.g., an attached NFT).
   * @param {Token} token The token whose metadata is being displayed.
   * @param {string[]} selectedFields Any specific fields that should be shown. If provided, all other fields will be ignored.
   * @param {boolean} listView Whether we are viewing the metadata from an NFT list view.
   * */
  const renderNftMetaData = (nftList: NftList, token: Token, selectedFields: string[] = null, listView: boolean = false) => {
    // add the smart contract address for viewing in metadata
    if (!token?.metaData?.isEncrypted) {
      token.metaData.smartContractAddress = token.contractAddress;
    }

    const compiledMetaData = MorphWalletUtils.compileMetaData(token.metaData, selectedFields);

    // may be manually overwritten by compileMetaData() for certain longer values
    const fontSize = listView ? '0.7rem' : '0.8rem';

    // whether to show the field names and values side-by-side; otherwise, will show value below field
    const flexDirection = listView ? 'row' : 'column';

    // list of hidden metadata (i.e., those behind "Show" buttons) and the state variables for that metadata
    const hiddenMetaData: { name: UiText, shown: boolean, setShown: Dispatch<SetStateAction<boolean>> }[] = [
      { name: UiText.mdSmartContractAddress, shown: showSmartContractAddress, setShown: setShowSmartContractAddress },
      { name: UiText.mdHashString, shown: showConfirmationCode, setShown: setShowConfirmationCode }
    ]

    return (
      <div key={`token-${token?.tokenId}-data`}
           onClick={listView ? () => goToNftSingleView(nftList, token, true) : null}
           style={{ padding: 5 }}>
        {/* TODO: when not in list view, organize all the TRUAPP interviewer fields in their own section, and remove 'interviewer' from field names */}
        {compiledMetaData.filter(data => !(token?.nftType === NftType.TICKET && data?.name === UiText.mdEventName)).map(data => (
          <div key={`${token?.tokenId}-${data?.name}`}
               style={{ display: 'flex', flexWrap: 'wrap', flexDirection }}>
            <p style={{ textAlign: 'left', margin: 3, height: 12, fontSize }}>
              {tWallet(data?.name)}
            </p>
            {hiddenMetaData?.find(metaData => metaData?.name === data?.name)?.shown === false ? (
              <button onClick={() => hiddenMetaData?.find(metaData => metaData?.name === data?.name)?.setShown(true)}
                      style={{ color: Color.LightGray, textAlign: 'center', backgroundColor: Color.Violet, width: 80,
                               height: 14, borderRadius: 2, padding: '1.2px 0px 0.8px', margin: 2, fontSize: '0.6rem' }}>
                {tWallet(UiText.Show)}
              </button>
            ) : (
              <p style={{ textAlign: 'left', height: 12, fontSize: data.fontSize || fontSize, color: Color.Orange, wordBreak: 'break-word',
                          margin: 3, maxWidth: (MorphWalletUtils.getMorphWalletWidth() - MorphWalletUtils.UI.paddingSide * 2) * 0.8 }}>
                {data?.value}
              </p>
            )}
          </div>
        ))}
      </div>
    );
  }

  /**
   * @description Set up and render the single view for a currently-selected NFT.
   * @param {NftList} nftList The list from which that NFT was selected; if null, assume viewing NFT from elsewhere (e.g., an attached NFT).
   * */
  const renderNftSingleView = (nftList: NftList = null) => {
    const token = getSingleViewNft(nftList);

    // height of div which contains the NFT metadata (labeled in the UI as "NFT Data")
    const getNftMetaDataHeight = () => {
      switch (token?.nftType) {
        case NftType.TRUAPP:
          return MorphWalletUtils.smallMobileScreen && 160;
      }
    }

    const singleViewWidth = MorphWalletUtils.getMorphWalletWidth() - MorphWalletUtils.UI.paddingSide * 2;
    const itemWidth = singleViewWidth * (MorphWalletUtils.smallMobileScreen ? 0.8 : 0.42);

    const renderQrCode = () => {
      // if NFT contains a decrypted QR code that is ready to show, show it
      if (qrCode) {
        return <img style={{ width: 150, height: 150, margin: 'auto', marginBottom: 10, marginTop: 10 }}
                    src={qrCode}
                    alt='NFT QR code'/>
      }

      // if NFT contains a decrypted QR code but is not able to show it (because showQrCode failed to set it), don't show it
      if (token?.metaData?.hashString) {
        return (
          <div className={Styles.row}
               style={{ alignItems: 'center', margin: 'auto', marginBottom: 5, marginTop: !isMobile() && 5,
                        height: 30, backgroundColor: Color.Blue,borderRadius: 5, padding: '2px 4px' }}>
            <ErrorIcon style={{ color: Color.Red, width: 15, marginRight: 5 }}/>
            {tWallet(UiText.NoQrCode)}
          </div>
        )
      }

      // if NFT contains encrypted QR code, show "Show QR Code" button
      if (token?.metaData?.encryptedHashString) {
        const handleDecryptQrCode = () => {
          goToAccessPin(AccessPinFunction.DecryptQrCode);
        }

        return (
          <div onClick={handleDecryptQrCode}
               className={Styles.row}
               style={{ color: Color.LightGray, cursor: 'pointer', userSelect: 'none', backgroundColor: Color.Violet,
                        alignItems: 'center', height: 30, margin: 'auto', borderRadius: 2,
                        padding: 5, fontSize: '0.8rem', marginTop: !isMobile() && 5, marginBottom: 5 }}>
            <QrCode style={{ width: 20, marginRight: 5 }}/>
            {tWallet(UiText.ShowQrCode)}
          </div>
        );
      }

      return <></>;
    }

    return (
      <div className={Styles.column}
           style={{ textAlign: 'center', fontSize: '0.8rem', marginTop: 12, marginBottom: isMobile() ? 20 : 5,
                    border: `1px solid ${Color.Violet}`, borderRadius: 5, backgroundColor: Color.DarkBlue }}>
        <div className={MorphWalletUtils.smallMobileScreen ? Styles.column : Styles.rowSpaceBetween}
             style={{ width: singleViewWidth * 0.9, marginBottom: 15, marginTop: 15 }}>
          {/* burn NFT */}
          {token.nftType === NftType.TRUAPP && token.userType === NftUserType.OWNER && (
            <div onClick={() => goToAccessPin(AccessPinFunction.BurnNft)}
                 className={Styles.row + ' ' + Styles.burnNftButton}
                 style={{ top: MorphWalletUtils.getHeaderStyling().height + 4, right: MorphWalletUtils.UI.paddingSide + 10 }}>
              {tWallet(UiText.BurnNft)}
              <Delete style={{ color: Color.Red, width: 15, height: 15, marginLeft: 3 }}/>
            </div>
          )}
          {/* left column */}
          <div className={Styles.columnSpaceBetween}>
            {/* NFT type */}
            <div className={Styles.singleViewItem}
                 style={{ width: token.nftType === NftType.TICKET && !MorphWalletUtils.smallMobileScreen ? itemWidth * 1.25 : itemWidth }}>
              <p>
                {tWallet(UiText.NftType)}
              </p>
              <p style={{ color: Color.Orange }}>
                {tWallet(MorphWalletUtils.getNftTypeDisplay(token.nftType))}
              </p>
            </div>
            {/* event name (TICKET) */}
            {nftList === NftList.MyNfts && token.nftType === NftType.TICKET && (
              <div className={Styles.singleViewItem}
                   style={{ width: token.nftType === NftType.TICKET && !MorphWalletUtils.smallMobileScreen ? itemWidth * 1.25 : itemWidth }}>
                <p>
                  {tWallet(UiText.Event)}
                </p>
                <p style={{ color: Color.Orange }}>
                  {MorphWalletUtils.convertToTitleCase(token.metaData?.eventDisplay)}
                </p>
              </div>
            )}
            {/* user type (e.g. owner, approved) */}
            {nftList === NftList.MyNfts && token.nftType !== NftType.TICKET && (
              <div className={Styles.singleViewItem}
                   style={{ width: itemWidth }}>
                <p>
                  {tWallet(UiText.UserType)}
                </p>
                <p style={{ color: Color.Orange }}>
                  {tWallet(MorphWalletUtils.getNftUserTypeDisplay(token.userType))}
                </p>
              </div>
            )}
          </div>
          {/* right column */}
          <div className={Styles.columnSpaceBetween}>
            {/* token ID */}
            <div className={Styles.singleViewItem}
                 style={{ width: token.nftType === NftType.TICKET && !MorphWalletUtils.smallMobileScreen ? itemWidth * 0.75 : itemWidth }}>
              <p>
                ID:
              </p>
              <p style={{ color: Color.Orange }}>
                {token.tokenId}
              </p>
            </div>
            {/* event date (TICKET) */}
            {nftList === NftList.MyNfts && token.nftType === NftType.TICKET && token.metaData?.eventDate && (
              <div className={Styles.singleViewItem}
                   style={{ width: token.nftType === NftType.TICKET && !MorphWalletUtils.smallMobileScreen ? itemWidth * 0.75 : itemWidth }}>
                <p>
                  {tWallet(UiText.Date)}
                </p>
                <p style={{ color: Color.Orange }}>
                  {token.metaData?.eventDate}
                </p>
              </div>
            )}
            {/* make NFT public / private (TRUAPP) */}
            {nftList === NftList.MyNfts && token.nftType === NftType.TRUAPP && token.userType === NftUserType.OWNER && (
              <button onClick={() => goToAccessPin(AccessPinFunction.ToggleNftPublicness)}
                      className={Styles.singleViewButton}
                      style={{ width: itemWidth }}>
                {tWallet(toggleNftPublicnessText)}
              </button>
            )}
          </div>
        </div>
        <div style={{ position: 'relative', width: 0, height: 0 }}>
          <div style={{ color: Color.Violet, backgroundColor: Color.DarkBlue, border: `1px solid ${Color.Violet}`, borderRadius: 5,
                        position: 'absolute', zIndex: 1, left: -singleViewWidth * 0.38, top: -8, padding: '0px 2px', opacity: 1 }}>
            {tWallet(UiText.NftData)}
          </div>
        </div>
        <div className={Styles.scrollableList + (isMobile() ? '' : ` ${Styles.scrollBar}`)}
             style={{ border: `1px solid ${Color.Violet}`, borderColor: Color.Violet, borderRadius: 5, display: !isMobile() && 'flex',
                      flexDirection: isMobile() ? 'column' : 'row', width: singleViewWidth * 0.9, minHeight: getNftMetaDataHeight(),
                      maxHeight: getNftMetaDataHeight(), paddingTop: 5 }}>
          {renderNftMetaData(nftList, token)}
          {renderQrCode()}
        </div>
        {/* help bubble(s) with prompt suggestion(s) */}
        <div className={Styles.helpBubbleDiv}
             style={{ width: singleViewWidth * 0.9, marginTop: 12 }}>
          {nftList === NftList.MyNfts && (
            <>
              {token.nftType === NftType.TRUAPP && (
                <>
                  {token.userType === NftUserType.OWNER && (
                    <div className={Styles.helpBubble}
                         onClick={getPromptFunc(UiText.HelpBubbleApprovedAccounts)}
                         style={{ marginBottom: (token.isPublic || acceptedBidNft.current) && 10, cursor: 'pointer' }}>
                      {tWallet(UiText.HelpBubbleApprovedAccounts)}
                    </div>
                  )}
                  {token.isPublic && (
                    <div className={Styles.helpBubble}
                         onClick={getPromptFunc(UiText.HelpBubbleViewBids)}
                         style={{ marginBottom: acceptedBidNft.current && 10, cursor: 'pointer' }}>
                      {tWallet(UiText.HelpBubbleViewBids)}
                    </div>
                  )}
                  {acceptedBidNft.current && (
                    <div className={Styles.helpBubble}
                         onClick={getPromptFunc(UiText.HelpBubbleViewAcceptedBid)}
                         style={{ cursor: 'pointer' }}>
                      {tWallet(UiText.HelpBubbleViewAcceptedBid)}
                    </div>
                  )}
                </>
              )}
              {token.nftType === NftType.TRUBID && (
                <div className={Styles.helpBubble}
                     onClick={getPromptFunc(UiText.HelpBubbleViewAttachedNft)}
                     style={{ cursor: 'pointer' }}>
                  {tWallet(UiText.HelpBubbleViewAttachedNft)}
                </div>
              )}
            </>
          )}
          {nftList === NftList.PublicNfts && token.nftType === NftType.TRUAPP && (
            <div className={Styles.helpBubble}
                 style={{ marginBottom: !displayedError && -5 }}>
              {tWallet(UiText.HelpBubblePurchaseOffer)}
            </div>
          )}
          {nftList === NftList.BidsOnNft && selectedMyNft.current?.userType === NftUserType.OWNER && (
            <div className={Styles.helpBubble}
                 onClick={getPromptFunc(UiText.HelpBubbleAcceptBid)}
                 style={{ marginBottom: !displayedError && -5, cursor: 'pointer' }}>
              {tWallet(UiText.HelpBubbleAcceptBid)}
            </div>
          )}
        </div>
        {renderError()}
      </div>
    );
  }

  /**
   * @description Go to view the accepted Purchase Bid on the currently selected TRU-Approval NFT
   * */
  const goToViewAcceptedBid = async () => {
    const log = 'goToViewAcceptedBid';

    const handleError = () => {
      setDisplayedError(UiText.errAccessToken);
      setLoading(false);
    }

    setLoading(true);

    if (!acceptedBidNft.current) {
      console.error(`${log}.1: No accepted bid found.`);
      handleError();
      return;
    }

    // get the rest of the data for the accepted bid
    if (!acceptedBidNft.current?.owner || !acceptedBidNft.current?.metaData) {
      const { error, nftOwner, nftMetaData } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getNft`,
        method: 'POST',
        data: {
          contractAddress: acceptedBidNft.current?.contractAddress,
          nftType: NftType.TRUBID,
          tokenId: acceptedBidNft.current?.tokenId,
          publicAddress: keys.current?.publicAddress
        }
      });

      if (error || !nftOwner || !nftMetaData) {
        console.error(`${log}.2: ${error || 'NFT data not found.'}`);
        handleError();
        return;
      }

      // set the accepted bid data
      acceptedBidNft.current.owner = nftOwner;

      const bidMetaData = nftMetaData?.bidData;

      // instead of showing the ID and contract address of the TRU-Approval NFT, show the simplified name
      bidMetaData.truTokenName = MorphWalletUtils.getNftName(bidMetaData?.truContractAddress, bidMetaData?.truTokenId);
      bidMetaData.bidStatus = 'Accepted';

      acceptedBidNft.current.metaData = bidMetaData;
    }
    
    setActivePage(Page.AcceptedBidSingleView);
    setLoading(false);
  }

  /**
   * @description View the attached TRU-Approval NFT from the selected Purchase Bid NFT.
   * */
  const goToViewAttachedNft = async () => {
    const log = 'goToViewAttachedNft';

    setLoading(true);

    // get attached NFT data
    const { error, tokenId, contractAddress, nftOwner, nftMetaData, isPublicData } = await axiosCall({
      url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getNft`,
      method: 'POST',
      data: {
        contractAddress: selectedMyNft.current?.metaData?.truContractAddress,
        nftType: NftType.TRUAPP,
        tokenId: selectedMyNft.current?.metaData?.truTokenId,
        publicAddress: keys.current?.publicAddress
      }
    });

    if (error) {
      console.error(`${log}: ${error || `Missing metadata for token ${selectedMyNft.current?.metaData?.truTokenId}.`}`);
      setDisplayedError(UiText.errAccessToken);
      setLoading(false);
      return;
    }

    // set the attached NFT data
    attachedNft.current = {
      tokenId,
      contractAddress,
      name: MorphWalletUtils.getNftName(contractAddress, tokenId),
      nftType: NftType.TRUAPP,
      userType: null,
      owner: nftOwner,
      metaData: nftMetaData?.truApprovalObjJson,
      isPublic: isPublicData,
      isBurned: false
    };

    setActivePage(Page.AttachedNftSingleView);
    setLoading(false);
  }

  return (
    <div className={Styles.morphWalletWrapper}
         style={MorphWalletUtils.getMorphWalletStyling()}>
      {renderHeader()}
      <div style={{ opacity: loading && 0.5, pointerEvents: loading ? 'none' : 'auto' }}>
        {/* welcome */}
        {activePage === Page.Welcome && (
          <>
            {loadWallet()}
          </>
        )}
        {/* recover account */}
        {activePage === Page.RecoverAccount && (
          <div className={Styles.column}>
            {renderError()}
            <p style={{ fontSize: '1.0rem', fontWeight: 500, marginBottom: 10 }}>
              {tWallet(UiText.EnterPhrase)}
            </p>
            {renderMnemonicInputs()}
            <div className={Styles.helpBubbleDiv + ' ' + Styles.helpBubble}
                 onClick={getPromptFunc(UiText.HelpBubbleTypePhrase)}
                 style={{ marginTop: 5, marginBottom: 15, cursor: 'pointer' }}>
              {tWallet(UiText.HelpBubbleTypePhrase)}
            </div>
          </div>
        )}
        {/* view recovery phrase */}
        {activePage === Page.ViewRecoveryPhrase && (
          <div className={Styles.column}>
            {renderError()}
            <p style={{ fontSize: '1.2rem', fontWeight: 500, marginBottom: 12, marginTop: -2 }}>
              {tWallet(UiText.YourPhrase)}
            </p>
            <div className={isMobile() ? Styles.column : Styles.row}
                 style={{ marginBottom: 10 }}>
              {renderMnemonicDisplay()}
            </div>
            <div className={Styles.helpBubbleDiv}>
              {renderImportantHelpBubble(UiText.HelpBubbleStorePhrase, 10)}
              {!encryptedKeys.current && (
                <div className={Styles.helpBubble}
                     onClick={getPromptFunc(UiText.HelpBubblePhraseContinue)}
                     style={{ cursor: 'pointer', marginBottom: 15 }}>
                  {tWallet(UiText.HelpBubblePhraseContinue)}
                </div>
              )}
            </div>
            {encryptedKeys.current && (
              <div style={{ marginBottom: 10, minHeight: 23 }}>
                {showPublicAddress ? (
                  <p style={{ textAlign: 'left', marginTop: 5, fontSize: '0.7rem', color: Color.White,
                              maxWidth: (MorphWalletUtils.getMorphWalletWidth() - MorphWalletUtils.UI.paddingSide * 2) }}>
                    {keys.current?.publicAddress}
                  </p>
                ) : (
                  <button onClick={() => setShowPublicAddress(true)}
                          style={{ color: Color.LightGray, textAlign: 'center', backgroundColor: Color.Violet,
                                   borderRadius: 5, padding: '4px 8px 3px 8px', fontSize: '0.8rem' }}>
                    {tWallet(UiText.SeePublicAddress)}
                  </button>
                )}
              </div>
            )}
            {SHOW_PRIVATE_KEY && (
              <p style={{ fontSize: isMobile() ? '0.5rem' : '0.7rem', marginBottom: 10 }}>
                {keys.current?.privateKey.join('')}
              </p>
            )}
          </div>
        )}
        {/* enter access PIN */}
        {activePage === Page.AccessPin && (
          <div className={Styles.column}>
            {renderError()}
            <p style={{ fontSize: '1.2rem', marginBottom: 12 }}>
              {tWallet(accessPinText)}
            </p>
            <div style={{ marginBottom: 15 }}>
              {accessPinInputIds.map((accessPinInputId) => (
                <input
                  key={accessPinInputId}
                  id={accessPinInputId}
                  type='tel'
                  autoComplete='off'
                  autoFocus={accessPinInputId === accessPinInputIds[0]}
                  onKeyDown={handleCodeKeyDown}
                  onChange={handleEnterCode}
                  disabled={loading}
                  className={Styles.codeInput}
                  style={{ marginLeft: accessPinInputId === accessPinInputIds[0] ? 0 : 5 }}
                />
              ))}
            </div>
            <div style={{ minHeight: 24, marginBottom: isMobile() ? 15 : 5 }}>
              {showClearPin && (
                <div onClick={handleResetCode}
                     className={Styles.row}
                     style={{ color: Color.LightGray, cursor: 'pointer', userSelect: 'none', alignItems: 'center', fontSize: '0.8rem' }}>
                  <Clear style={{ width: 15, marginRight: 5 }}/>
                  {tWallet(UiText.ClearPin)}
                </div>
              )}
            </div>
            {(accessPinText === UiText.EnterNewPin || prevAccessPinText.current === UiText.EnterNewPin) && (
              <div className={Styles.helpBubbleDiv}>
                {renderImportantHelpBubble(UiText.HelpBubbleAccessPin, 15)}
              </div>
            )}
            {accessPinFunction.current === AccessPinFunction.BurnNft && (
              <div className={Styles.helpBubbleDiv}>
                {renderImportantHelpBubble(UiText.HelpBubbleBurnNft, 15)}
              </div>
            )}
            {accessPinFunction.current === AccessPinFunction.MintBidNft && (
              <div className={Styles.helpBubbleDiv + ' ' + Styles.helpBubble}
                   style={{ marginBottom: 15 }}>
                {tWallet(UiText.HelpBubbleMakeBid)}
              </div>
            )}
          </div>
        )}
        {/* link device (mobile app view) */}
        {activePage === Page.LinkDeviceMobile && (
          <div className={Styles.column}>
            {renderError()}
            <div style={{ marginBottom: 20 }}>
              {linkDeviceCodeInputIds.map((linkDeviceCodeInputId) => (
                <input
                  key={linkDeviceCodeInputId}
                  id={linkDeviceCodeInputId}
                  type='tel'
                  autoComplete='off'
                  autoFocus={linkDeviceCodeInputId === linkDeviceCodeInputIds[0]}
                  onKeyDown={handleCodeKeyDown}
                  onChange={handleEnterCode}
                  disabled={loading}
                  className={Styles.codeInput}
                  style={{ marginLeft: linkDeviceCodeInputId === linkDeviceCodeInputIds[0] ? 0 : 5 }}
                />
              ))}
            </div>
            <div className={Styles.helpBubbleDiv}>
              <div className={Styles.helpBubble}
                   style={{ marginBottom: 15 }}>
                {tWallet(UiText.HelpBubbleLinkDevice)}
              </div>
              <div className={Styles.helpBubble}
                   style={{ marginBottom: 15 }}>
                {tWallet(UiText.HelpBubbleSameAccount)}
              </div>
            </div>
          </div>
        )}
        {/* link device (mobile app view, after success) */}
        {activePage === Page.LinkDeviceMobileSuccess && (
          <div className={Styles.column}>
            <p style={{ fontSize: '1.0rem', marginBottom: 10, marginTop: 15 }}>
              {tWallet(UiText.LinkSuccess)}
            </p>
            <p style={{ fontSize: '1.0rem', marginBottom: 20 }}>
              {tWallet(UiText.ReturnToDevice)}
            </p>
          </div>
        )}
        {/* link device (web app view, usually desktop) */}
        {activePage === Page.LinkDeviceDesktop && (
          <div className={Styles.column}>
            <p style={{ color: Color.Orange, fontSize: '1.4rem', fontWeight: 500, marginTop: 10, marginBottom: 20 }}>
              {tWallet(UiText.AngelWallet)}
            </p>
            <p style={{ textAlign: 'center', fontSize: '1.0rem', marginBottom: 20, lineHeight: 1.5 }}>
              {tWallet(UiText.OpenMobile)}
            </p>
            <p style={{ fontSize: '1.0rem', marginBottom: 10 }}>
              {tWallet(UiText.EnterCode)}
            </p>
            <p style={{ color: Color.Orange, fontWeight: 500, fontSize: '2.0rem', marginBottom: 5}}>
              {linkDeviceCode.current.join(' ')}
            </p>
            <LinearProgress variant='determinate'
                            value={linkDeviceTimerProgress}
                            sx={{ backgroundColor: Color.Blue, '& .MuiLinearProgress-bar': { backgroundColor: Color.Orange }, width: 120, height: 3, marginBottom: 2 }}/>
            <p style={{ fontSize: '0.8rem', marginBottom: isMobile() ? 20 : 10 }}>
              {tWallet(UiText.Waiting)}
            </p>
          </div>
        )}
        {/* list of NFTs on which the user is an owner or approved user */}
        {activePage === Page.MyNftsListView && (
          renderNftListView(NftList.MyNfts)
        )}
        {/* single NFT view from MY_NFTS list */}
        {activePage === Page.MyNftsSingleView && (
          renderNftSingleView(NftList.MyNfts)
        )}
        {/* see and update approved blockchain accounts on an NFT */}
        {activePage === Page.ApprovedAccounts && (
          <div className={Styles.column}>
            <p style={{ fontSize: '1.0rem', marginBottom: approvedAccounts?.length > 0 && 12, marginTop: 12 }}>
              {tWallet(approvedAccounts?.length > 0 ? UiText.ApprovedAccounts : UiText.NoApprovedAccounts)}
            </p>
            {approvedAccounts?.length > 0 && (
              <div className={Styles.scrollableList + (isMobile() ? '' : ` ${Styles.scrollBar}`)}>
                {approvedAccounts.map((account) => (
                  <div key={account}
                       className={Styles.rowSpaceBetween}
                       style={{ color: Color.White, borderRadius: 5, margin: 3, padding: 5, alignItems: 'center', backgroundColor: Color.DarkBlue,
                                width: isMobile() ? (MorphWalletUtils.getMorphWalletWidth() - MorphWalletUtils.UI.paddingSide * 2) * 0.85 : 230 }}>
                    <p style={{ fontSize: '0.8rem', marginLeft: 5 }}>
                      {tWallet(UiText.AddressEndingIn)}&nbsp;&nbsp;<span style={{ color: Color.Orange }}>{account.slice(-8)}</span>
                    </p>
                    <Clear onClick={() => handleRemoveApprovedAccount(account)}
                           style={{ width: 15, height: 15, color: Color.Gray, cursor: 'pointer' }}/>
                  </div>
                ))}
              </div>
            )}
            {renderError()}
            <div className={Styles.helpBubbleDiv + ' ' + Styles.helpBubble}
                 style={{ marginBottom: 10 }}>
              {tWallet(UiText.HelpBubbleApproveAccount)}
            </div>
          </div>
        )}
        {/* see the TRUAPP NFT attached to a TRUBID NFT */}
        {activePage === Page.AttachedNftSingleView && (
          renderNftSingleView()
        )}
        {/* see the bids made on a token */}
        {activePage === Page.BidsOnNftListView && (
          renderNftListView(NftList.BidsOnNft)
        )}
        {/* see a bid made on a token */}
        {activePage === Page.BidsOnNftSingleView && (
          renderNftSingleView(NftList.BidsOnNft)
        )}
        {/* see the accepted bid on a token */}
        {activePage === Page.AcceptedBidSingleView && (
          renderNftSingleView()
        )}
        {/* list of NFTs on which the user can place a bid */}
        {activePage === Page.PublicNftsListView && (
          renderNftListView(NftList.PublicNfts)
        )}
        {/* single NFT view from PublicNfts list */}
        {activePage === Page.PublicNftsSingleView && (
          renderNftSingleView(NftList.PublicNfts)
        )}
      </div>
      {renderFooter()}
    </div>
  );
};

export default MorphWallet;