import React from 'react';
import Styles from '@styles/MessageContent/BlockchainWelcomeMessageContent.module.css';
import { useUserContext } from '@contexts/user';
import Sanitized from '@components/Sanitized';
import { useTranslation } from 'react-i18next';

/**
 * Returns the full CDN path of the icon
 * @param iconFileName The name or relative path of the icon
 */
const getIconPath = (iconFileName: string) => `${process.env.REACT_APP_MORGAN_CDN}/Images/${iconFileName}`;

const BlockchainWelcomeMessageContent = ({ message, errand }) => {
  const { isOperator } = useUserContext();
  const russellImage = `${process.env.REACT_APP_MORGAN_CDN}/Images/WUM_Images/rptop.png`;
  const { t } = useTranslation();

  if (!message) {
    console.info('Message is undefined');
    return <></>;
  }

  console.log(message);

  let messageTitle = message?.title;
  let messageSubtitle = message?.subtitle;
  let steps = message?.steps;
  let footer = message?.footer;
  let delimIdx = footer?.indexOf(':');
  const footer1 = footer?.substring(0, delimIdx);
  const footer2 = footer?.substring(delimIdx + 1);
  // If true, uses the default orange color instead of the blockchain blue
  const useDefaultColor = message?.useDefaultColor === true;

  // If this event has a special gif, it will be named after the event.
  // Otherwise, use the default gif for this component
const gifName = (message.specialGif ? message.eventName : 'default') + 'NftEventAngel';
  const gifUrl = getIconPath(gifName + '.gif');

  return (
    <div className={[Styles.welcomeContainer, ...(isOperator ? [Styles.operatorView] : [])].join(' ')}>
      {message.specialGif && (
        <div className={Styles.russellIntroImg}>
          <img src={russellImage} alt="Tick Icon" />
          <div className={Styles.swagText}>{t('relaxSwag')}</div>
        </div>
      )}
      <div>
        <div className={Styles.topCircle}>
          <div className={Styles.innerCircle} />
          <img src={gifUrl} alt="AngelAi logo" className={Styles.image} />
        </div>
        <div className={`${Styles.top}${useDefaultColor ? ' ' + Styles.useDefaultBackgroundColor : ''}`}>
          {messageTitle}
        </div>
        <div
          className={`${Styles.bottom}${useDefaultColor ? ' ' + Styles.useDefaultBorderColor : ''}
            `}
        >
          <>
            <div className={Styles.bottomTop}>
              <div className={Styles.bottomTopLeft}>{messageSubtitle}</div>
              <div className={Styles.bottomTopMiddle} />
              <div
                className={`${Styles.bottomTopRight}${useDefaultColor ? ' ' + Styles.useDefaultBackgroundColor : ''}`}
              />
            </div>
            <div className={Styles.bottomBottom}>
              {Array.isArray(steps) &&
                steps.map((step, idx) => (
                  <div key={idx} className={idx === 0 ? Styles.step1 : Styles.stepAfter}>
                    <div
                      className={`${Styles.stepCircle}${
                        useDefaultColor ? ' ' + Styles.useDefaultBackgroundColor : ''
                      }`}
                    >
                      <div className={Styles.imgCircle}>
                        <img src={getIconPath(step.icon)} alt={step.icon} />
                      </div>
                    </div>
                    <div>
                      <Sanitized html={step.message} />
                    </div>
                  </div>
                ))}
            </div>
          </>
          {footer1 && footer2 && (
            <div className={Styles.footer}>
              <span className={Styles.footer1}>{footer1}:</span> {footer2}
            </div>
          )}
        </div>
      </div>
    </div>
  ); 
};

export default BlockchainWelcomeMessageContent;
