import { isMobileOrTablet } from "@common/deviceTypeHelper";

export const styles: Record<string, React.CSSProperties> = {
    popup: {
        position: "relative",
        zIndex: 8,
        width: '250px',
    },
    popupText: {
        zIndex: 9,
        fontSize: '16px',
        color: 'black',
        position: 'absolute',
        textAlign: 'center',
        marginLeft: '38px',
        marginBottom: '15px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        lineHeight: 1.2,
    },
    popupContainer: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        position: "absolute",
        transform: "scale(0)",
        top: '-33px',
        zIndex: 8,
        animation: 'popupShowsUp 1s forwards linear 1.5s',
    },
    bannerContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1500,
    },
    bannerImage: {
        width: isMobileOrTablet() ? "100%" : "98%",
        height: '170px',
        objectFit: 'cover',
        position: 'absolute',
        bottom: 0,
        left: isMobileOrTablet() ? 0 : "1%",
        zIndex: 0,
        borderRadius: '20px 20px 0 0'
    },
    circleBackgroundImage: {
        position: "absolute",
        width: "270px",
        height: "270px",
        backgroundColor: "#18114c",
        zIndex: 7,
        borderRadius: "50%"
    },
    circleImage: {
        objectFit: "cover",
        maxWidth: "270px",
        width: '240px',
        height: "auto",
        zIndex: 7,
        animation: 'rotateZ 25s infinite linear',
    },
    circleImageContainer: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: isMobileOrTablet() ? '75px' : '135px'
    },
    mainContainer: {
        width: "100%",
        color: 'white',
        zIndex: 5,
        position: 'absolute',
        left: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        padding: '12px 3rem'
    },
    middleCirclePoints: {
        objectFit: "cover",
        maxWidth: "270px",
        height: "auto",
        zIndex: 7,
        position: 'absolute'
    },
    middleCircleTwoPoints: {
        width: '270px',
        animation: 'rotateZ 7.5s infinite linear', // Infinite rotation animation
    },
    middleCircleSinglePoints: {
        width: '256px',
        animation: 'rotateZ 5s infinite linear', // Infinite rotation animation
    },
    clickMeButton: {
        position: 'absolute',
        zIndex: 8,
        bottom: '-90px',
        backgroundColor: '#150932',
        color: 'white',
        fontSize: '28px',
        padding: '8px 40px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        borderRadius: '15px', // Keep the rounded corners
        border: '2px solid transparent', // Border thickness
        background: 'linear-gradient(#150932, #150932) padding-box, linear-gradient(to right, #FD9131, #8E2397) border-box', // Gradient border
    },
    angel: {
        zIndex: 9,
        position: 'absolute',
        bottom: "-29px",
        width: "165px"
    },
    numberTextStyle: {
        marginRight: 2,
        padding: '6px 12px',
        backgroundColor: "#FF7400", // Primary color background
        color: '#fff', // White text
        borderRadius: '50%', // Circular shape
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
        fontSize: '1.2rem', // Slightly larger font size for emphasis
        fontWeight: 'bold', // Bold text to make it stand out
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '40px', // Ensures it stays circular even for smaller numbers
        height: '40px', // Ensures consistent height
    }
};