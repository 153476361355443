/**
 * @file CalculatorResultMessageContent.tsx
 * @description This file defines the CalculatorResultMessageContent component used to
 * display loan estimation values
 * workflow
 * @author Hunter Gordon <hunter.gordon@swmc.com>
 */

import React, { useState, memo, useRef, useEffect, useMemo } from 'react';

import { IErrand } from '@interfaces/Conversation';
import { CalculatorResultMessage } from '@mTypes/Conversation';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import MessageTextStyle from '@styles/MessageTextStyle';
import { useTranslation } from 'react-i18next';
import Styles from '@styles/MessageContent/CalculatorMessageContent.module.css';
import Sanitized from '@components/Sanitized';
import Allign from '@styles/Allign';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import axiosCall from '@services/axios';
import { ValidatorFunctions } from '@common/Validators';
import { copyObj } from '@common/userMessagesUtils';
const CalculatorIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/Calculator.png';

type TCalculatorResultProps = {
  errand: IErrand;
  message: CalculatorResultMessage;
};

type TCalculatorResultDisclaimerProps = {
  message: CalculatorResultMessage;
};

type TToggleDisclaimerIconProps = {
  isDisclaimerOpen: boolean;
};

//the main result bubble.
const PropertyList = memo(({ message, t }) => {
  return Object.keys(message.result).map(
    (key) =>
      key !== 'expandedDetails' &&
      key !== 'workflowActions' &&
      key !== 'disclaimer' && (
        <MessageTextStyle key={key}>
          <strong>{t(key)}</strong>: {message.result[key]}
        </MessageTextStyle>
      )
  );
});

const ToggleDisclaimerIcon = ({ isDisclaimerOpen }: TToggleDisclaimerIconProps) => {
  return (
    <div
      className={[Styles.disclaimerIconWrapper, ...(isDisclaimerOpen ? [Styles.disclaimerIconWrapperOpen] : [])].join(
        ' '
      )}
    >
      <ChatBubbleStyle className={Styles.disclaimerIcon}>
        {isDisclaimerOpen ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
      </ChatBubbleStyle>
    </div>
  );
};

const CalculatorResultDisclaimer = ({ message }: TCalculatorResultDisclaimerProps) => {
  const { t } = useTranslation();
  const disclaimerMessage = message.result.disclaimer ?? t('calculatorDisclaimerFallback');

  return (
    <Allign className={Styles.outerContainer}>
      <ChatBubbleStyle
        sx={{
          border: 1,
          borderColor: 'var(--orange700)',
          background: 'var(--peach600)',
        }}
        className={Styles.chatBubbleContainer}
      >
        <MessageTextStyle sx={{ paddingTop: '4px' }}>
          {/* Render the expanded details if provided */}
          <>
            {message.result.expandedDetails &&
              Object.keys(message.result.expandedDetails).map((key) => (
                <p key={key}>
                  <strong>{t(key)}</strong>: {message.result.expandedDetails[key]}&nbsp;
                </p>
              ))}
          </>
          {/* Render the disclaimer message (don't show "Disclaimer: " if there are no expanded details) */}
          {!!Object.entries(message.result.expandedDetails ?? {}).length && (
            <>
              <strong>{t('disclaimer')}</strong>:{' '}
            </>
          )}
          {disclaimerMessage}
        </MessageTextStyle>
      </ChatBubbleStyle>
    </Allign>
  );
};

const CalculatorResultMessageContent = ({ errand, message }: TCalculatorResultProps) => {
  // const errandContext = useContext<IErrandContext>(ErrandContext);
  // const rootContext = useRootContext();
  const { t } = useTranslation();
  const selectOpenRef = useRef(false);
  const tableRef = useRef(null);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);

  useEffect(() => {
    const table = tableRef.current;
    if (!table) {
      return;
    }

    const controller = new AbortController();

    // For credit reapir workflow support, but can be used to support any
    // CalculatorResult type message that is meant to be user-interactive
    // with checkboxes and select dropdowns. Can also be expanded to support
    // more input types
    const updateMessage = async (e) => {
      if (!e.target.name || !['checkbox', 'select-one'].includes(e.target.type)) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      selectOpenRef.current = !selectOpenRef.current;

      try {
        let regex = '';
        let replacement = '';
        if (e.target.type === 'checkbox') {
          const valueWhenChecked = 'name="' + e.target.name + '" checked="true"';
          const valueWhenNotChecked = 'name="' + e.target.name + '"';

          const isChecked = message.result.htmlContent.indexOf(valueWhenChecked) !== -1;

          const creditorCheckboxPrefix = 'creditor';
          if (!isChecked && e.target.name.startsWith(creditorCheckboxPrefix)) {
            const rowIndex = e.target.name.split('-')[1];
            // If the creditor checkbox is selected, then the bureau checkboxes need to be selected as well
            const bureauCodes = ['TU', 'EQFX', 'EXP'];
            const disabledInputs = new Set(
              Array.from(table.firstChild.querySelectorAll('input'))
                .filter((elem) => elem.disabled && elem.name.endsWith(rowIndex))
                .map((elem) => elem.name)
            );
            // Select the bureau checkboxes
            bureauCodes.forEach((bureauCode) => {
              const inputName = `${bureauCode}-${rowIndex}`;
              // Don't select checkboxes that are disabled
              if (disabledInputs.has(inputName)) {
                return;
              }

              const regex = 'name="' + inputName + '"';
              const replacement = 'name="' + inputName + '" checked="true"';
              message.result.htmlContent = message.result.htmlContent.replace(regex, replacement);
            });
          }

          regex = isChecked ? valueWhenChecked : valueWhenNotChecked;
          replacement = isChecked ? valueWhenNotChecked : valueWhenChecked;
        } else if (e.target.type === 'select-one') {
          if (selectOpenRef.current) {
            // User is selecting an option, wait (otherwise, the message will be updated and the
            // select dropdown will automatically close)
            return;
          }

          // Update the message in the database with the currently selected option from the list
          regex = 'name="' + e.target.name + '" value="true"';
          replacement = 'name="' + e.target.name + `" value="${e.target.value}"`;
          // Could be multiple dropdowns in the same message (expected format of name property:
          // <name>-<index>)
          let index = e.target.name.substring(e.target.name.indexOf('-') + 1);

          const currentRowOptionElements = Array.from(e.target.options).filter(
            (option) => option.dataset.name.substring(option.dataset.name.indexOf('-') + 1) === index
          );
          // De-select all options in the row
          currentRowOptionElements.forEach((optionElement) => {
            const optionRegex = `data-name="${optionElement.dataset.name}" selected="true"`;
            const optionReplacement = `data-name="${optionElement.dataset.name}"`;
            message.result.htmlContent = message.result.htmlContent.replace(optionRegex, optionReplacement);
          });

          // Also need to update the option itself so the select dropdown displays properly
          const optionElement = Array.from(e.target.options).find(
            (option) =>
              option.value === e.target.value &&
              option.dataset.name.substring(option.dataset.name.indexOf('-') + 1) === index
          );
          const optionRegex = `data-name="${optionElement.dataset.name}"`;
          const optionReplacement = `data-name="${optionElement.dataset.name}" selected="true"`;
          message.result.htmlContent = message.result.htmlContent.replace(optionRegex, optionReplacement);
        }

        // Update the table
        message.result.htmlContent = message.result.htmlContent.replace(regex, replacement);

        // Update message document on backend to update currently checked + unchecked values
        const request = {
          url: `chat/${message.chat}/message/${message._id}`,
          method: 'PUT',
          data: {
            ...message,
            message: message.result.htmlContent.replace(/'/g, '&apos;').replace(/"/g, '&quot;'),
          },
        };

        await axiosCall(request);
      } catch (error) {
        console.error(error);
      }
    };

    table.addEventListener('click', updateMessage);
    table.addEventListener('change', updateMessage);

    return () => {
      controller.abort();
      table.removeEventListener('click', updateMessage);
      table.removeEventListener('change', updateMessage);
    };
  }, [message]);

  const badResult = useMemo(() => {
    if(ValidatorFunctions.isNotUndefinedNorNull(message?.result?.badResult)) {
      return message.result.badResult;
    } else {
      return false;
    }
  }, [message, message?.result]);

  return (
    <div className={Styles.calculatorMessageContentContainer}>
      <div className={[Styles.wrapper, ...(message.operatorView ? [Styles.operatorView] : [])].join(' ')}>
        <div className={`${Styles.chatBubble}`}>
          {!badResult ? (
            <>
              <p className={Styles.titleText}>{t(message.result.title || 'calculationResult')}</p>
              {!message.result.htmlContent && <PropertyList message={message} t={t} />}
              {/* Render the server-side HTML if htmlContent is present */}
              {message.result.htmlContent && (
                <div ref={tableRef} className={Styles.tableContainer}>
                  <Sanitized translate={true} html={message.result.htmlContent} className={Styles.tableStyles} />
                </div>
              )}
              <img alt="Calculator Icon" src={CalculatorIcon} className={Styles.calculatorIconStyle}></img>
            </>
          ) : (
            <div className={Styles.errorText}>
              <MessageTextStyle sx={{ width: '280px' }}>{t('loanProductNotFound')}</MessageTextStyle>
              <img alt="Calculator Icon" src={CalculatorIcon} className={Styles.calculatorIconStyle}></img>
            </div>
          )}
        </div>

        <div className={Styles.calcFooter}>
          {!badResult && (
            <div className={Styles.disclaimerFooterSection} onClick={() => setIsDisclaimerOpen((prev) => !prev)}>
              <ToggleDisclaimerIcon isDisclaimerOpen={isDisclaimerOpen} />
              <p style={{ marginLeft: '42px' }} className={Styles.paragraphStyles}>
                {isDisclaimerOpen ? t('readLess') : t('readMore')}
              </p>
            </div>
          )}
        </div>
      </div>
      {isDisclaimerOpen && <CalculatorResultDisclaimer message={message} />}
    </div>
  );
};

export default CalculatorResultMessageContent;
