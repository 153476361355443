/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 *
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 *
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React, { Dispatch, SetStateAction, lazy, Suspense } from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphBorrowerSelectorSkeleton.module.css';
import ErrorBoundary from "@components/ErrorBoundary";

const LazyMorphBorrowerSelector = lazy(() => import('@components/MorphBorrowerSelector'));

const MorphBorrowerSelectorFallback = () => {
  return <div className={Skeleton.loader + ' ' + Styles.style} />;
};
// The render component below lazy loads the corresponding component using the skeleton above
type TMorphBorrowerSelectorSkeletonProps = {
  setText: Dispatch<SetStateAction<string>>;
};
const MorphBorrowerSelectorSkeleton = ({ setText }: TMorphBorrowerSelectorSkeletonProps) => {
  const MorphBorrowerSelectorSkeletonProps = {
    setText,
  };

  return (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphBorrowerSelectorSkeleton.tsx`}>
      <Suspense fallback={<MorphBorrowerSelectorFallback />}>
        <LazyMorphBorrowerSelector {...MorphBorrowerSelectorSkeletonProps} />
      </Suspense>
    </ErrorBoundary>
  );

};
export default MorphBorrowerSelectorSkeleton;
