import React, { lazy, Suspense } from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphShareCustomLinkSkeleton.module.css';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from "@components/ErrorBoundary";

const LazyMorphShareCustomLink = lazy(() => import('@components/MorphShareCustomLink'));

const MorphShareCustomLinkFallback = () => {
  const { t } = useTranslation();
  return (
    <div className={Styles.shareCustomLinkSkeletonContainer}>
      <div className={Styles.qrCircle1} title={t('tLoading')}>
        <div className={Styles.qrCircle2} aria-hidden>
          <div className={Styles.qrCircle3}>
            <div className={Styles.qrCircle4}>
              <div className={Styles.qr + ' ' + Skeleton.loader}></div>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.copyIcon}>
      </div>
      <div className={Styles.line} />
      <div className={Styles.urlBox}>
      </div>
      <div className={Styles.indentSvg}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 40 8">
          <path d="M 3 0 L 8 0 L 11 0 C 13 0 14 0 15 2 C 16 4 18 4.3 19 4.32 C 20 4.3 22 4 23 2 C 24 0 25 0 27 0 L 30 0 L 35 0 A 1 1 0 0 1 35 6 L 3 6 A 1 1 0 0 1 3 0"
            stroke="var(--orange500)" strokeWidth="0.25" className={`${Styles.svgPath2}`} />
          <linearGradient id="linear-gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: 'var(--orange500)' }} />
            <stop offset="70%" style={{ stopColor: 'var(--gray000)' }} />
          </linearGradient>
        </svg>
      </div>
      <div className={Styles.indentContainer}>
        <div>Email</div>
        <div>SMS</div>
      </div>
    </div>
  );
};
type TMorphShareCustomLinkSkeletonProps = {
  cancelAction: (key: any, clear: boolean) => void;
}
const MorphShareCustomLinkSkeleton = ({ cancelAction }: TMorphShareCustomLinkSkeletonProps) => {
  const MorphShareCustomLinkProps = {
    cancelAction
  };

  return (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphShareCustomLinkSkeleton.tsx`}>
      <Suspense fallback={<MorphShareCustomLinkFallback />}>
        <LazyMorphShareCustomLink {...MorphShareCustomLinkProps} />
      </Suspense>
    </ErrorBoundary>
  );
};
export default MorphShareCustomLinkSkeleton;
