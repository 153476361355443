import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { PropsWithChildren, useContext, useState, lazy, Suspense } from 'react';
import { Styles } from '../Styles/EmojiSelectorStyles';
import { IFooterContext, FooterContext } from '@contexts/FooterContext';
import SentimentEmoji from '@components/SentimentEmoji';
import EmojiPickerReactSkeleton from './Skeletons/EmojiPickerReactSkeleton';
import { IEmojiPickerProps } from 'emoji-picker-react';
import ErrorBoundary from "@components/ErrorBoundary";

const LazyEmojiPicker = lazy(() => import('emoji-picker-react'));

// jsx chunks - separate component preferred

const EmojiSelector: React.FC<PropsWithChildren<any>> = (props) => {
  // external utils like useTranslation, useNavigation
  // react state managment - context, state, ref
  const footerContext = useContext<IFooterContext>(FooterContext);
  const [anchorEl, setAnchorEl] = useState(null);
  // derrived state, useMemo
  // functions / useCallbacks
  const handleEmojiClose = () => setAnchorEl(null);
  const handleEmojiClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  // useEffects
  // lazy components
  const EmojiPickerProps: IEmojiPickerProps = {
    onEmojiClick: props.handleEmoji,
    disableSearchBar: true
  }

  return (
    <Styles>
      <IconButton
        className="ESIconButton"
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleEmojiClick}>
        {anchorEl 
          ? <HighlightOffOutlinedIcon  className='ESColor' /> 
          : <SentimentEmoji sentiment={footerContext.sentimentPreview} />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl === null ? false : true}
        onClose={handleEmojiClose}
        keepMounted={true}
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className='mui-transparent-background'
        style={{
          zIndex: '1050',
        }}>
        <MenuItem style={{ background: 'none' }}>
          {anchorEl && (
            <ErrorBoundary debug={`./src/Components/EmojiSelector.tsx`}>
              <Suspense fallback={<EmojiPickerReactSkeleton />}>
                <LazyEmojiPicker
                  {...EmojiPickerProps}
                />
              </Suspense>
           </ErrorBoundary>
          )}
        </MenuItem>
      </Menu>
    </Styles>
  );
};
export default EmojiSelector;
