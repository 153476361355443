import React, { useCallback, useMemo, useState } from 'react';
import { AttachLoan, LoanAttached, RemoveAttached } from '@assets/Icons';
import { CircularProgress, Menu, MenuItem, Snackbar, Tooltip } from '@mui/material';

import axiosCall from '@services/axios';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { sendWorkflow } from '@common/WorkflowsUtils';
import { IErrand, IMessage } from '@interfaces/Conversation';
import ThinClientUtils from '@common/ThinClientUtils';
import Sanitized from './Sanitized';
import MessageTime from './MessageTime';
import tStyles from '@styles/Typography.module.css';
import Styles from '@styles/DocumentBubble.module.css';
import { getPrimaryParticipant } from '@common/errandUtils';
import { AccessType } from '@common/AccessType';
import { useUserContext } from '@contexts/user';
import { WORKFLOW_NAMES } from '@constants/WorkflowNames';
const DocumentPreview = process.env.REACT_APP_MORGAN_CDN + '/Images/DOC.png';

type TDocumentBubbleProps = {
  errand: IErrand;
  message: IMessage;
  document: number;
  isLastFileAttachment?: boolean;
}

const DocumentBubble = ({
  errand, message, document, isLastFileAttachment
}: TDocumentBubbleProps) => {
  const { t } = useTranslation();
  const { _id, ssAccessToken } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element>(null);
  const [error, setError] = useState('');
  const isPrimaryParticipant = useMemo(() => {
    const primaryParticipant = getPrimaryParticipant(errand);
    const primaryUserId = primaryParticipant?.userData?._id;
    return primaryUserId === message?.userId;
  }, [errand?.participants, message?.userId]);

  const response = useQuery([document, message.updatedAt], async ({ signal }) => {
    let request = {
      url: `chat/${errand._id}/document/${document}`,
    };
    let config = {
      signal,
    };
    return await axiosCall(request, config);
  });

  // adds a loan number to the chat
  const handleAddLoanNumber = useCallback(async (e) => {
    e?.stopPropagation();
    if (!message?.visible || !response?.data) return;
    const activeUsers = errand?.participants?.filter((x) => x.active && x.userType === 'User');
    const recipients = activeUsers?.length > 1 ? [_id] : [];
    // Old logic for checking auth is now being handled in the workflow logic itself 
    sendWorkflow('', WORKFLOW_NAMES.ASSOCIATE_LOAN_TO_DOCUMENT, errand?._id, recipients, AccessType.public, message?.userId, message?.operatorView, null);
  }, [errand?.participants, errand?._id, message?.userId, message?.operatorView, message?.visible, response?.data, _id]);

  const handleAttachLoanNumber = useCallback(async (loanNumber) => {
    if (isLoading || !message.visible || !response?.data) return;
    if (!message.operatorView && !ssAccessToken) {
      const activeUsers = errand.participants.filter((x) => x.active && x.userType === 'User');
      const recipients = activeUsers.length > 1 ? [_id] : [];
      sendWorkflow('', WORKFLOW_NAMES.AUTHENTICATE, errand._id, recipients, AccessType.public, message.userId, message.operatorView, null);
    } else {
      setIsLoading(true);
      const AngelAi = errand.participants.find((p) => p?.active && p?.ai)?.userData?._id;
      let updatePayload = {
        url: `chat/${errand._id}/document/${response?.data?._id}`,
        method: 'PUT',
        data: {
          loanNumber: loanNumber,
          sessionToken: ssAccessToken,
          sender: AngelAi || message.userId,
          senderType: AngelAi || message.operatorView ? 'Operator' : 'User',
          message: `The file ${response?.data?.filename} was attached to loan ${loanNumber}`,
          messageType: 'Text',
        },
      };
      try {
        await axiosCall(updatePayload);
      } catch (e) {
        if (e instanceof Error) {
          console.error(`Couldn't update document ${e}`);
        }
        setError(t('loginErrorOther'));
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading, errand?.participants, ssAccessToken, errand?._id, message?.userId, message?.operatorView, message?.visible, response?.data?._id]);

  const handleRemoveLoanNumber = useCallback(async (e) => {
    e?.stopPropagation();
    if (isLoading || !message.visible || !response?.data) return;
    if (!message.operatorView && !ssAccessToken) {
      const activeUsers = errand.participants.filter((x) => x.active && x.userType === 'User');
      const recipients = activeUsers.length > 1 ? [_id] : [];
      sendWorkflow('', WORKFLOW_NAMES.AUTHENTICATE, errand._id, recipients, AccessType.public, message.userId, message.operatorView, null);
    } else {
      setIsLoading(true);
      const AngelAi = errand.participants.find((p) => p?.active && p?.ai)?.userData?._id;
      let updatePayload = {
        url: `chat/${errand._id}/document/${response?.data?._id}`,
        method: 'PUT',
        data: {
          loanNumber: '',
          sessionToken: ssAccessToken,
          sender: AngelAi || message.userId,
          senderType: AngelAi || message.operatorView ? 'Operator' : 'User',
          message: `The file ${response?.data?.filename} was detached from loan ${response?.data?.loanNumber}`,
          messageType: 'Text',
        },
      };
      try {
        await axiosCall(updatePayload);
      } catch (e) {
        if (e instanceof Error) {
          console.error(`Couldn't update document ${e.message}`);
        }
        setError(t('loginErrorOther'));
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading, errand?.participants, ssAccessToken, errand?._id, message?.userId, message?.operatorView, message?.visible, response?.data?._id]);
  
  /* On click handler for downloading a document */
  const handleClickDownload = useCallback(async (e: { stopPropagation: () => void; }) => {
    e?.stopPropagation();
    if (!response.data || !response.data.data) { // Check if the data exists
      console.error("Unable to download document");
      return; 
    }
    const base64Data = response.data.data;

    // Decode base64 string to binary
    const byteCharacters = atob(base64Data); // Decode base64
    const byteNumbers = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    };
    const blob = new Blob([byteNumbers], { type: response.data.mimeType });
    const url = URL.createObjectURL(blob); // Create a URL for the Blob
    const link = window.document.createElement('a');
    link.href = url;
    link.setAttribute('download', response?.data?.filename); // Specify the filename for download
    window.document.body.appendChild(link);
    link.click(); // Simulate a click to trigger the download
    window.document.body.removeChild(link); // Release the object URL after download
    
    // Thin Client specific event to allow document download
    if (ThinClientUtils.isThinClient()){
      let url = `data:${response?.data?.mimeType};base64,${base64Data}`;
      window.dispatchEvent(new CustomEvent('documentDownload', {
        detail: {
          key: 'documentDownload',
          url: url,
          mimeType: response?.data?.mimeType,
          filename: response?.data?.filename,
        }
      }));
    }
    URL.revokeObjectURL(url);  // Release the object URL to free up memory
  }, [message.visible, response?.data?.mimeType, response?.data?.data, response?.data?.filename]); 

  const loanNumbers = useMemo(() => {
    if (!errand.context || errand.context?.length === 0) return [];
    return errand.context?.filter((x: any) => x?.indexField?.name === 'loanNumber')
        .map((x: any) => x?.indexField?.value.slice(0, -2) + '-' + x?.indexField?.value.slice(-2));
  }, [errand.context]);

  return (
    <>
      <Snackbar
        open={error ? true : false}
        onClose={() => setError('')}
        message={error}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
        <div
          className={[
            Styles.wrapper,
            ...(isLastFileAttachment ? [Styles.isLastFileAttachment] : []),
            ...(message.accessType === AccessType.internal ? [Styles.internal] : []),
            ...(message.alignByCurrentUser ? [Styles.alignByCurrentUser] : []),
            ...(isLoading ? [Styles.isLoading] : []),
          ].join(' ')}
          style={{
            backgroundImage: `url(${
              response?.data?.mimeType?.split('/')[0] === 'image'
                ? `data:${response?.data?.mimeType};base64,${response?.data?.data}`
                : DocumentPreview
            })`,
          }}
        >
          {(message.alignByCurrentUser || isPrimaryParticipant) && message.visible && (
            <>
              {response?.data?.loanNumber ? (
                <>
                  <Tooltip
                    title={`${t('attachedTo')} ${response?.data?.loanNumber}`}
                    placement={message.sender?._id !== _id ? 'right' : 'left'}>
                    <div className={Styles.loanNumberWrapper}>
                      <div className={Styles.loanNumber}>
                        <LoanAttached />
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={t('detachMedia')}
                    placement={message.sender?._id !== _id ? 'right' : 'left'}>
                    <button
                      onClick={handleRemoveLoanNumber}
                      className={Styles.clickHandler}
                    >
                      <div className={Styles.button}>
                        <RemoveAttached />
                      </div>
                    </button>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip
                    title={t('attachMedia')}
                    placement={message.alignByCurrentUser ? 'right' : 'left'}>
                    {loanNumbers.length > 0 ? (
                      <button
                        className={Styles.clickHandler}
                        id='positioned-button'
                        aria-controls={anchorEl ? 'positioned-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={anchorEl ? 'true' : undefined}
                        onClick={(event) => {
                          event?.stopPropagation();
                          setAnchorEl(event.currentTarget as Element);
                        }}
                      >
                        <div className={Styles.button}>
                          <AttachLoan />
                        </div>
                      </button>
                    ) : (
                      <button
                        className={Styles.clickHandler}
                        onClick={handleAddLoanNumber}
                      >
                        <div className={Styles.button}>
                          <AttachLoan />
                        </div>
                      </button>
                    )}
                  </Tooltip>

                  <Menu
                    id='positioned-menu'
                    aria-labelledby='positioned-button'
                    anchorEl={anchorEl}
                    open={anchorEl !== null}
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}>
                      {/* For multiple attachments: the page crashes otherwise. We are actually doing the same thing above this. */}
                    {loanNumbers?.map((loanNumber, index) => (
                      <MenuItem
                        key={index}
                        onClick={(event) => {
                          event?.stopPropagation();
                          handleAttachLoanNumber(loanNumber);
                          setAnchorEl(null);
                        }}
                        sx={{ cursor: 'pointer' }}
                        dense={true}>
                        {loanNumber}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </>
          )}
          {isLoading && (
            <div className={Styles.loading}>
              <CircularProgress size='2em' />
            </div>
          )}
          <Tooltip
            title={`${t('monitoringDownload')} ${response?.data?.filename}`}
            placement={message.alignByCurrentUser ? 'right' : 'left'}>
            <div className={Styles.container}>
              <Sanitized
                className={tStyles.base}
                html={response?.data?.filename}
                visible={message.visible}
                tag="p"
              />
              <MessageTime message={message} options={{ month: 'long', day: '2-digit', year: 'numeric' }} />
            </div>
          </Tooltip>
          <svg
            onClick={handleClickDownload}
            xmlns='http://www.w3.org/2000/svg'
            width={202}
            height={108}
            viewBox='0 0 202 108'
            fill='none'
            style={{
              cursor: 'pointer',
              position: 'absolute',
              bottom: '0',
              pointerEvents: 'all'
            }}>
            <mask
              id='mask0_800_7975'
              style={{ maskType: 'alpha' }}
              maskUnits='userSpaceOnUse'
              x={0}
              y={9}
              width={202}
              height={126}>
              <path
                d='M202 38.3478H104.731C102.82 38.3478 100.936 37.8991 99.2301 37.0379L46.2925 10.3099C44.5867 9.44869 42.7025 9 40.7916 9H7.87973e-05V135H202V38.3478Z'
                fill='url(#paint0_linear_800_7975)'
              />
            </mask>
            <g mask='url(#mask0_800_7975)'>
              <path
                d='M202 44.2179H104.731C102.82 44.2179 100.936 43.7693 99.2301 42.908L46.2925 16.1801C44.5867 15.3188 42.7025 14.8701 40.7916 14.8701H7.87973e-05V117.783H202V44.2179Z'
                fill='url(#paint1_linear_800_7975)'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M-155.829 131.5L-139.589 129.499C-123.349 127.499 -90.8692 123.498 -58.3892 113.495C-25.9093 103.492 6.57068 87.4869 39.0506 85.4863C71.5306 83.4857 104.011 95.4893 136.491 93.4887C168.97 91.4881 201.45 75.4833 217.69 67.4809L233.93 59.4785V143.504H217.69C201.45 143.504 168.97 143.504 136.491 143.504C104.011 143.504 71.5306 143.504 39.0506 143.504C6.57068 143.504 -25.9093 143.504 -58.3892 143.504C-90.8692 143.504 -123.349 143.504 -139.589 143.504H-155.829V131.5Z'
                fill='url(#paint2_linear_800_7975)'
                fillOpacity='0.2'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M249.352 -57.9541L238.652 -30.191C228.264 -2.64903 206.865 52.8771 161.997 77.964C117.129 103.051 48.1698 98.1407 6.69926 127.544C-34.7712 156.948 -49.3763 221.108 -56.3671 252.967L-63.6696 285.047L-43.2867 310.948L-22.3955 296.136C-1.81619 281.545 39.9661 251.92 81.4366 222.516C122.907 193.112 164.689 163.487 206.16 134.084C247.63 104.68 289.413 75.0549 309.992 60.4636L330.883 45.6511L249.352 -57.9541Z'
                fill='url(#paint3_linear_800_7975)'
                fillOpacity='0.2'
              />
            </g>
            <rect x={17} width={33} height={33} rx='7.14086' fill='var(--gray000)' />
            <path
              d='M39.0697 19.4297L32.9997 25.4997L26.9297 19.4297'
              stroke='var(--gray920)'
              strokeWidth='1.5'
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M33 8.5V25.33'
              stroke='var(--gray920)'
              strokeWidth='1.5'
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <defs>
              <linearGradient
                id='paint0_linear_800_7975'
                x1={202}
                y1='36.7826'
                x2='-11.5393'
                y2='159.222'
                gradientUnits='userSpaceOnUse'>
                <stop stopColor='var(--gray920)' />
                <stop offset={1} stopColor='var(--gray920)' />
              </linearGradient>
              <linearGradient
                id='paint1_linear_800_7975'
                x1={202}
                y1='36.7832'
                x2='-11.5393'
                y2='159.223'
                gradientUnits='userSpaceOnUse'>
                <stop stopColor='var(--orange600)' />
                <stop offset={1} stopColor='var(--peach500)' />
              </linearGradient>
              <linearGradient
                id='paint2_linear_800_7975'
                x1='39.0506'
                y1='59.4785'
                x2='39.0506'
                y2='143.504'
                gradientUnits='userSpaceOnUse'>
                <stop stopColor='var(--gray000)' />
                <stop offset={1} stopColor='var(--gray000)' stopOpacity={0} />
              </linearGradient>
              <linearGradient
                id='paint3_linear_800_7975'
                x1='286.123'
                y1='-198.294'
                x2='51.3368'
                y2='235.052'
                gradientUnits='userSpaceOnUse'>
                <stop stopColor='var(--gray000)' />
                <stop offset='0.288894' stopColor='var(--gray000)' stopOpacity='0.670059' />
                <stop offset={1} stopColor='var(--gray000)' stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
    </>
  );
};

export default DocumentBubble;
