/**
 * @file This file contains all text used on the UI (except
 * mnemonic-related text), for translation purposes. For
 * predefined translations see getPredefinedTranslation()
 * in MorphWalletUtils.ts.
 * @author Timur Bickbau
 */

/**
 * IMPORTANT: Don't use the word "NFT" anywhere in the UI. Use "token" instead.
 * */

enum UiText {
  // general
  AngelWallet = 'Angel Wallet',
  Show = 'Show',
  // NFT types
  nftTRUAPP = 'TRU Approval',
  nftTRUBID = 'Purchase Bid',
  nftTICKET = 'Event Ticket',
  // NFT user types
  uOwner = 'Owner',
  uApproved = 'Approved',
  uApprovedUser = 'Approved User',
  // Page.ViewRecoveryPhrase
  LogIn = 'Log In',
  YourPhrase = 'Your secret phrase',
  HelpBubbleStorePhrase = 'IMPORTANT: Store your secret phrase in a secure physical location. Without it, you will lose access to your wallet and assets.',
  HelpBubblePhraseContinue = 'After storing your phrase, type "Continue".',
  SeePublicAddress = 'See My Public Address',
  // Page.RecoverAccount
  CreateWallet = 'Create Wallet',
  EnterPhrase = 'Enter your secret phrase',
  HelpBubbleTypePhrase = 'Enter your 12-word secret phrase above to access your account. Then, type "Continue" below.',
  // Page.AccessPin
  EnterOldPin = 'Enter your old PIN',
  EnterNewPin = 'Enter a new PIN',
  EnterYourPin = 'Enter your PIN',
  ClearPin = 'Clear PIN',
  PleaseWait = 'Please wait...',
  HelpBubbleAccessPin = 'You will be asked to enter this PIN whenever you open your wallet or try to perform certain actions in your wallet.',
  HelpBubbleBurnNft = 'IMPORTANT: If you burn your token, you will not be able to access it again. This cannot be undone.',
  HelpBubbleMakeBid = 'Once your bid is minted, you\'ll be able to find it in your wallet as a token.',
  // Page.LinkDeviceMobile
  HelpBubbleLinkDevice = 'Enter the code shown in your web browser to temporarily access your account on that device.',
  HelpBubbleSameAccount = 'Make sure you are signed into the same AngelAi account on both devices!',
  // Page.LinkDeviceMobileSuccess
  LinkSuccess = 'Your wallet has been linked!',
  ReturnToDevice = 'Please return to your other device.',
  // Page.LinkDeviceDesktop
  OpenMobile = 'Please open your wallet in the AngelAi mobile app and type "Link Device".',
  EnterCode = 'Then enter this code:',
  Waiting = 'Waiting...',
  // Page._ListView
  FilterAllNfts = 'All Tokens',
  Refreshing = 'Refreshing...',
  HeaderMyNfts = 'My Tokens',
  HeaderNoMyNfts = 'Your Angel Wallet is empty.',
  HeaderBids = 'Bids',
  HeaderNoBids = 'There are no bids on this token.',
  HeaderPublicTokens = 'Public Tokens',
  HeaderNoPublicTokens = 'No public tokens to view.',
  FooterTryPrompt = 'Try this prompt.',
  FooterTryPrompts = 'Try some of these prompts.',
  FooterUpdatePin = 'Update PIN',
  FooterViewRecoveryPhrase = 'See My Secret Phrase',
  FooterLinkDevice = 'Link Device',
  FooterGoToMarketplace = 'Go to Marketplace',
  HelpBubbleEmptyWallet = '', // include steps for the user to get a token; blank to not show
  // Page._SingleView
  NftType = 'Type:',
  Event = 'Event:',
  UserType = 'User:',
  ApprovedAccounts = 'Approved Users',
  Date = 'Date:',
  NftData = 'Data',
  ShowQrCode = 'Show QR Code',
  NoQrCode = 'No QR code for this event.',
  MakeNftPublic = 'Make Public',
  MakeNftPrivate = 'Make Private',
  BurnNft = 'Burn',
  HelpBubbleApprovedAccounts = 'To see the users approved to view this token, type "View Approved Users".',
  HelpBubbleViewBids = 'To see the bids that have been made on this token, type "View Bids".',
  HelpBubbleViewAcceptedBid = 'To see the bid that has been accepted on this token, type "View Accepted Bid".',
  HelpBubbleViewAttachedNft = 'To view the token attached to this bid, type "View Attached Token".',
  HelpBubbleAcceptBid = 'To accept this bid, type "Accept Bid".',
  HelpBubblePurchaseOffer = 'Enter your purchase offer below.',
  // Page.ApprovedAccounts
  NoApprovedAccounts = 'No approved users.',
  AddressEndingIn = 'Address ending in',
  HelpBubbleApproveAccount = 'To approve a user on this token, enter their public address below.',
  // input prompts
  pContinue = 'continue',
  pBack = 'back',
  pAccess = 'access',
  pLogin = 'login',
  pCreate = 'create',
  pDontHave = 'don\'t have',
  pCantFind = 'can\'t find',
  pSecret = 'secret',
  pRecover = 'recover',
  pPhrase = 'phrase',
  pWords = 'words',
  pMnemonic = 'mnemonic',
  pPin = 'pin',
  pChange = 'change',
  pUpdate = 'update',
  pLink = 'link',
  pConnect = 'connect',
  pDevice = 'device',
  pDesktop = 'desktop',
  pRefresh = 'refresh',
  pApproved = 'approved',
  pUsers = 'users',
  pAccepted = 'accepted',
  pBid = 'bid',
  pAttached = 'attached',
  pAccept = 'accept',
  pMarketplace = 'marketplace',
  // errors (use pipes to separate lines)
  errUnrecognizedPrompt = 'Sorry, I didn\'t get that. Could you rephrase?',
  errCreateWallet = 'There was an issue creating the wallet.|Please try again later.',
  errIllegalWord = 'Invalid word.',
  errMissingWord = 'Missing word.',
  errIncorrectPhrase = 'Incorrect access phrase.',
  errPastePhrase = 'There was an issue pasting your mnemonic phrase.',
  errInvalidPastePhrase = 'The phrase you are trying to paste is invalid.',
  errLostPhrase = 'You can find your secret phrase in your Angel Wallet. If you don\'t have access to your wallet and you have lost your secret phrase, then we cannot recover your account.',
  errTypePhrase = 'Your secret phrase is for your eyes only!|Don\'t type it here!',
  errSetAccessPin = 'There was an issue setting the PIN.|Please try again later.',
  errSubmitAccessPin = 'There was an issue submitting your PIN.|Please try again later.',
  errIllegalAccessPin = 'The submitted code is not valid.',
  errIncorrectAccessPin = 'Incorrect PIN.',
  errTypePin = 'Please type your PIN in the boxes.',
  errTypeCode = 'Please type your code in the boxes.',
  errGetNfts = 'There was an issue getting your tokens.|Please try again later.',
  errLinkDevice = 'There was an issue linking your wallet.|Please try again later.',
  errLinkDeviceCode = 'The code you entered was incorrect.',
  errAccessToken = 'There was an issue accessing the token.|Please try again later.',
  errDecryptQrCode = 'There was an error decrypting the QR code.|Please try again later.',
  errGetApprovedAccounts = 'There was an issue getting the approved users.|Please try again later.',
  errApproveAccount = 'There was an issue adding the user.|Please try again later.',
  errRemoveApprovedAccount = 'There was an issue removing the user.|Please try again later.',
  errBurnNft = 'There was an issue burning the token.|Please try again later.',
  errAccessBidsOnNft = 'There was an issue getting the bids.|Please try again later.',
  errAcceptBid = 'There was an issue accepting the bid.|Please try again later.',
  errAccessMarketplace = 'There was an issue accessing the marketplace.|Please try again later.',
  errMintBidNft = 'There was an issue making the bid.|Please try again later.',
  errTransferEventNft = 'There was an issue transferring your ticket.|Please try again later.',
  errNoTicketsRemaining = 'I\'m sorry, there are no tickets remaining for this event.',
  errRequest = 'There was an issue with your request. Please try again later.',
  errMakeNftPublic = 'There was an issue making the token public. Please try again later.',
  errMakeNftPrivate = 'There was an issue making the token private. Please try again later.',
  errNoPurchasePrice = 'No purchase price provided.',
  errInvalidPurchasePrice = 'Invalid purchase price.',
  errUserAlreadyOwnsNft = 'You already own a token for this event.',
  // general metadata
  mdSmartContractAddress = 'Smart Contract Address',
  // TICKET metadata
  mdEventName = 'Event',
  mdScanned = 'Attended',
  mdAdditionalGuests = 'Additional Guests',
  mdHashString = 'Confirmation Code',
  // TRUBID metadata
  mdBidStatus = 'Bid Status',
  mdTruTokenName = 'TRU Approval Token',
  // TRUAPP metadata
  mdTruApprovalDate = 'TRU Approval Date',
  mdBorrowerName = 'Borrower Name',
  mdPropCode = 'Property Code',
  mdPropDesc = 'Property Description',
  mdLoanAmount = 'Loan Amount',
  mdPurchasePrice = 'Purchase Price',
  mdInterestRate = 'Interest Rate',
  mdApr = 'APR',
  mdLtv = 'LTV',
  mdCltv = 'CLTV',
  mdProductCodeDesc = 'Product Code Description',
  mdRetail = 'Is Retail',
  mdTerm = 'Term',
  mdNewHazIns = 'New Hazard Insurance',
  mdNewTaxes = 'New Taxes',
  mdNewMi = 'New MI',
  mdNewHoa = 'New HOA Fees',
  mdNewFirstMortPi = 'New P&I on First Mortgage',
  mdNewOtherMortPi = 'New P&I on Other Mortgages',
  mdInterviewerName = 'Interviewer Name',
  mdInterviewerTitle = 'Interviewer Title',
  mdInterviewerEmail = 'Interviewer Email',
  mdInterviewerPhone = 'Interviewer Phone',
  mdInterviewerNmlsId = 'Interviewer NMLS ID',
  mdInterviewerCompCode = 'Interviewer Company Code',
  mdInterviewerCompAddress = 'Interviewer Company Address',
  mdInterviewerCompCityStateZip = 'Interviewer Company City/State/Zip',
  mdLenderCompCode = 'Lender Company Code',
  mdMolCompUrl = 'MOL Company URL'
}

export { UiText };