/**
 * File converted to Typescript. Here are a few references:
 *
 * https://www.typescriptlang.org/docs/handbook/basic-types.html
 *
 */
import { Paper, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  getChatId,
  getUserId,
} from '@storage/userStorage';
import RopeDrawer from '@components/RopeDrawer';
import Conversation from '@components/Conversation';
import ErrandsContainer from '@components/ErrandsContainer';
import { RootContext } from '@contexts/RootContext';
import { useSocketContext } from '@contexts/socket';
import { handlePreviews } from '@common/loadPreview';
import { prepareErrands } from '@common/errandUtils';
import {
  IErrand,
  IMessage,
  INotification,
  IBrowserNotification,
} from '@interfaces/Conversation';
import SlotMachine from '../../Components/SlotMachine';
import axiosCall from '@services/axios';
import triggerBrowserNotificationAlert from '@common/triggerBrowserNotificationAlert';
import ErrorBoundary from '@components/ErrorBoundary';
import getUnreadNotifications from '@common/getUnreadNotifications';
import useDebounce from '@common/hooks/useDebounce';
import { sendAction, sendWorkflow } from '@common/WorkflowsUtils';
import useWidget from '@common/hooks/useWidget';
import { MorphType } from '@common/MorphType';
import useDocumentVisibility from '@common/hooks/useDocumentVisibility';
import useWindowDimensions, { TWindowDimensions } from '@common/hooks/useWindowDimensions';
import Styles from '../../Styles/ConversationPage.module.css';
import useInitialMount from '@common/hooks/useInitialMount';
import { CustomWindow } from '@mTypes/Window';
import ThinClientUtils from '@common/ThinClientUtils';
import { AccessType } from '@common/AccessType';
import { useUserContext } from '@contexts/user';
import useAbortControllerV2 from '@common/hooks/useAbortControllerV2';
import ConversationSkeletonLoader from '@components/ConversationSkeletonLoader';
import { DarkUnclickableBackground } from '@components/DarkUnclickableBackground';
import { useAppContext } from '@contexts/AppContext';
import { ValidatorFunctions } from '@common/Validators';
import {
  ChatFetchStateManager,
  ChatFetchDefaultState,
  ChatFetchPositionMax,
  ChatFetchCheckIfAlreadyLoaded,
  ChatFetchEvent,
  ChatFetchRequestCallback,
  ChatFetchCountLoaded,
  ChatFetchParamType,
  ChatFetchHasMore,
  ChatFetchReloadView,
  ChatFetchBulkDeleteFromCache,
} from './ChatFetchStateManager';
import { userActiveChatStatus, userActiveChatType, GetEpochFromObjCreatedAt } from '@common/common';
import { ResetFooterUserAction } from '@common/common';

const ConversationPage = () => {
  const { t } = useTranslation();
  const queryClient = new QueryClient();
  const { isWidget } = useWidget();
  const { _id, logout, login } = useUserContext();
  const { isEventsConnected, isMessagesConnected, eventsSocket, messagesSocket, disconnectMessageSocket, disconnectEventSocket } = useSocketContext();
  const { showReloadButton } = useAppContext();
  const { getAbortSignal } = useAbortControllerV2();
  /**
   * This state holds the array of errands currently in use by the user.
   * It contains the Conversation primary errand, as well as all other errands
   * of varying types. At this point everything is an errand, even the main
   * conversation.
   * The errand interface now contains all other members (such as messages,
   * workflows, notifications, etc.) within itself instead of at the page or
   * component level.
   */
  const [errands, setErrands] = useState<IErrand[]>([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const getErrandsLength = (): number => {
    return errands.length;
  };

  // This refers to the checkbox in the consentBox component
  const [consentChecked, setConsentChecked] = useState(true);
  // TODO: replace localStorage usage with this state
  const [tpConsentGiven, setTpConsentGiven] = useState(true);
  // Helps control the shaking of the consent
  const [shakingConsent, setShakingConsent] = useState(false);
  // errands reload indicator
  const [viewReload, setViewReload] = useState<number>(0);

  // This state is pertinent to determining which errand is currently selected.
  // It is also crucial to the split screen setup and determining which second
  // errand to open.
  const [selectedIndex, setSelectedIndex] = useState([]);

  // Determine if messages in conversation are loading or not.
  // If loading has ended, set the status to false to stop rendering skeletons.
  const [messagesAreLoading, setMessagesAreLoading] = useState(true);

  // Handles rendering the mobile notification effect
  const [notificationAlert, showNotificationAlert] = useState(false);
  // notifications
  const [notifications, setNotifications] = useState<INotification[]>([]);
  // Used to display Peter's Captcha when we should be rate limiting
  const [openedCaptcha, setOpenedCaptcha] = useState(false);
  // Handles the slot machine showing or not showing
  const [showSlotMachine, setShowSlotMachine] = useState(false);
  // Handles which chat to replace with the slot machine window, since there can be split screen
  const [slotMachineErrandId, setSlotMachineErrandId] = useState(null);
  // Handles the useraction Id for each env so we don't have to search it when checking if it's resolved
  const [slotMachineUserAction, setSlotMachineUserAction] = useState(null);
  // The useRefs persist between renders and allows these to be updated without re-rendering the page.
  const [rootMorphType, setRootMorphType] = useState(MorphType.None);
  const morphedId = useRef(null);
  // This is used to allow formbodytype to affect errands container
  const [createSignatureMobileIsOn, setCreateSignatureMobileIsOn] = useState(false);
  // ref to hold the selected errand color
  const errandColorRef = useRef<string>(null);
  // Ref to hold the id of the errand whose name is being edited
  const editErrandId = useRef<string>(null);
  // The closed errands that have this chat as their parentId
  const [childErrands, setChildErrands] = useState([]);
  const userSelectedPromptRef = useRef(null);
  const [userSelectedPrompt, setUserSelectedPrompt] = useState('');
  const userSelectedVideoRef = useRef(null);
  const [videoMenuTitle, setVideoMenuTitle] = useState('');
  const [promptName, setPromptName] = useState('')
  const drawerRef = useRef(null);
  const conversationFooterRef = useRef(null);

  const chatFetchDataRef = useRef<ChatFetchParamType>({...ChatFetchDefaultState});

  //const sessionRestore = useRef(true); // this keeps track of if the authenticate is a sessionRestore or not to determine if to remove the errand etc.
  // Maverick 06/02 - React fundamentals require immutable state, while the let works for single joined chats this is more future proof and better aligns with react cannon
  const joinedChat = useRef([]); // used to control new message alerts, prevent sending new message notifications to chats were the user is actively listening on.
  const [showConfirmResendDialog, setShowConfirmResendDialog] = useState({
    open: false,
    chat: undefined,
    type: undefined,
    id: undefined,
    recipients: [],
    check: true,
  });

  // MRGN-877, Timur Bickbau
  const hasReceivedSmlsData = useRef(false);
  const authenticatingAsNewUser = useRef(false);

  /**
  * Handle action/workflow for a given chat
  * @async
  * @function
  * @param {string} id - The id of the action/workflow to handle
  * @param {string} type - The type of the action/workflow
  * @param {string} chatId - The id of the chat where the action/workflow needs to be handled
  */
  const handleActionWorkflow = async (id: string, type: string, chatId: string, recipients = []) => {
    // make an API call to get the participants of the chat
    const participants = await axiosCall({
      url: `chat/${chatId}/participant`,
    });

    // check if participants exist
    if (participants) {
      // check if the type is valid
      if (type !== null && type !== undefined && type !== '') {
        // check if the type is an action
        if (type === 'action') {
          // send action to the chat
          setShowConfirmResendDialog(
            await sendAction(
              id,
              '',
              chatId,
              recipients?.length > 0 ? recipients : [getUserId()],
              AccessType.public,
              getUserId(),
              false,
              showConfirmResendDialog.check
            )
          );
        }
        // check if the type is a workflow
        else if (type === 'workflow') {
          // send workflow to the chat
          setShowConfirmResendDialog(
            await sendWorkflow(
              id,
              '',
              chatId,
              recipients?.length > 0 ? recipients : [getUserId()],
              AccessType.public,
              getUserId(),
              false,
              showConfirmResendDialog.check
            )
          );
        }
      }
    }
  }

  const leaveFormGoToParentChat = useCallback((parentId: string): void => {
    setErrands((prev) => {
      const foundIndex = prev.findIndex((x) => x._id === parentId);

      if (foundIndex === -1) {
        console.info('form is not found!');
      } else {
        setSelectedIndex([foundIndex]);
      }
  
      return prev;
    })
  }, []);

  const clearNotifications = useCallback(() => {
    const markAsRead = async (readNotifications) => {
      for (const notif of readNotifications) {
        try {
          await axiosCall({
            url: `user/${notif.userId}/notification/${notif._id}`,
            method: 'put',
            data: {
              status: 'read',
            },
          });
        } catch (err) {
          if (err) {
            console.error(`Could not mark notification ${notif._id} as read`, err);
          }
        }
      }
    }

    // remove read notifications from the ui
    setNotifications((prevState) => {
      let currentChats = joinedChat.current;
      let readNotifications = prevState.filter((n) => currentChats.indexOf(n.chat?._id) !== -1);
      // mark read notifications as read in the db
      markAsRead(readNotifications);
      return [...prevState.filter((n) => currentChats.indexOf(n.chat?._id) === -1)];
    });
  }, []);

  const syncWindowState = useCallback((dimensions: TWindowDimensions) => {
    if (!dimensions.isDesktop) {
      setSelectedIndex((prev) => {
        return [prev[0]];
      });
    }
  }, []);

  //=====================================================

  // Slot machine requires several state updates to trigger properly, this function consolidates these updates.
  const triggerSlotMachine = useCallback((errandId: string, userActionId: string) => {
    setShowSlotMachine(true);
    setSlotMachineErrandId(errandId);
    setSlotMachineUserAction(userActionId);
  }, []);

  // Called by the SlotMachine component on exit
  const exitSlotMachine = useCallback((chatId: string, userActionId: string, resolved: boolean) => {
    if(resolved){
      if(chatId){
        setErrands((prev) => {
          const chatObj = prev.find((e) => e._id === chatId);

          if (chatObj) {
            ResetFooterUserAction(chatObj);
          }

          setRootMorphType(MorphType.None);
          return [...prev];
        });
      }
    }

    setShowSlotMachine(false);
    setSlotMachineErrandId(null);
    setSlotMachineUserAction(null);
  }, [])

  /**
   * This function what it does is update the message preview on the errands menu and
   * determines whenever the message should be blurred or not.
   * @param {IMessage} messageObj
   * @returns errands object with two additional attributes: preview and preview allowed
   */
  const updateErrandMessagePreview = useCallback((messageObj: IMessage) => {
    const chatId = messageObj?.chat;

    if (chatId === undefined || chatId === null || chatId === '') {
      console.info('updateErrandMessagePreview: chatId is not defined');
      return '';
    }

    if (messageObj === undefined || messageObj === null) {
      console.info('updateErrandMessagePreview: messageObj is not defined');
      return '';
    }

    const userId = getUserId();

    // when the chats are loaded the participant data is already initialized on getErrandsFromConversations
    // this case will cover the scenario after a user has joined a chat
    setErrands((prev) => {
      if (!Array.isArray(prev)) {
        console.warn('setErrands prev is not an array');
        prev = [];
      }
      // TODO: convert from map to findIndex
      const updatedErrands = prev.map((errand: IErrand) => {
        if (errand._id === chatId) {
          return {
            ...errand,
            lastMessageData: messageObj,
            previewAllowed:
              errand.participants[ // get the index of the logged in participant
                errand.participants.findIndex(
                  (participant) => participant?.active && participant?.userData?._id === userId
                )
              ]?.messageHistoryAllowed,
          };
        }
        return errand;
      });
      return updatedErrands;
    });
  }, []);

  // decide which errand would be the focus this logic assumes that user is a participant of the chat and tha chat exists (order matters)
  const getChatIndex = useCallback((localErrands: IErrand[], selectedChat: string): number[] => {
    try {
      if (!localErrands) return [0];

      // check if there was a selected chat and maintain the current chat when restoring the user session
      if (ValidatorFunctions.isNotEmptyStr(selectedChat)) {
        const selectedChatIndex = localErrands.findIndex((x) => x?._id === selectedChat);
        if (selectedChatIndex !== -1) return [selectedChatIndex];
      }
      console.error({selectedChat, localErrands: localErrands.map((e) => e._id)})

      const defaultChatIndex = localErrands.findIndex((x) => x?.isDefault === true);
      if (defaultChatIndex !== -1) return [defaultChatIndex];

      return [0];
    } catch (err) {
      console.log(`getChatIndex: `, err);
      return [0];
    }
  }, []);

  /**
   * @function setupErrands
   * @description
   * Retrieve all the chats that the user (parent user if restored) is in.
   * If there are chats
   *  then
   *    Format the errand data by adding previews etc.
   *    Determine which errand should be selected (Also done when errands are reopened.)
   *    Set the chat Id
   *    Set the chat data
   *  else
   *    Create a new chat with the userId and
   *    set the data, errands etc.
   * 
   * Throughout this function we are using the index of each object to set the selected index
   * This works because the prepareErrands function is returning the array sorted by order
   * Should this change, this function will have to be updated to rely on order rather than index.
   * @param {String/ObjectId} selectedChat
   */
  const setupErrands = useCallback(
    async (selectedChatId?: string) => {
      if (!_id) {
        return;
      }

      console.log('reloading errands!');

      const data = chatFetchDataRef.current;

      const nextAction = ChatFetchStateManager(data, ChatFetchEvent.RequestRestart);

      if (ChatFetchHasMore(nextAction) === false) {
        return;
      }

      /**
       * 2024/10/16 - bold: setMessagesAreLoading(false) is gonna be called on conversationBody
       * because we don't wanna show no messages in between errands fetch and messages fetch
       */
      setMessagesAreLoading(true);
      setRootMorphType(MorphType.None);

      // fetch chats
      // Abort any ongoing requests and instantiate a new AbortController
      const config = getAbortSignal('setupErrands');
      const errandsFetched: IErrand[] = await prepareErrands(_id, config, true, 0, data.limit);
      const errandsNew = ChatFetchRequestCallback(data, errandsFetched);

      // current selected chat
      const selectedChat = selectedChatId || getChatId();
      const chatIndex = getChatIndex(errandsNew, selectedChat);

      setViewReload((prev) => {
        setErrands(errandsNew);
        setSelectedIndex(chatIndex);
        setShowSkeleton(false);
        return ++prev;
      })
    },
    [_id, getAbortSignal, getChatIndex]
  );

  /**
   * sync user errands sequence to backend
   */
  const syncErrandsSequence = useCallback(async (chatIds: Array<string>) => {
    try {
      const payload = {
        url: `chat/bulk/update/position`,
        method: 'put',
        data: {
          type: userActiveChatType,
          status: userActiveChatStatus,
          chatIds,
        },
      };

      await axiosCall(payload);

      console.log("Errands sequence sync: SUCCESS");
    } catch (err) {
      console.error("Errands sequence sync: FAILED", err);
    }
  }, []);

  const loadMoreErrands = useCallback(async () => {
    const data = chatFetchDataRef.current;

    const nextAction = ChatFetchStateManager(data, ChatFetchEvent.RequestMore);

    if (ChatFetchHasMore(nextAction) === false) {
      return;
    }

    // how many chats currently loaded to UI
    const offset = ChatFetchCountLoaded(data);

    // fetch more chats
    const config = getAbortSignal('loadMoreErrands');
    const errandsFetched: IErrand[] = await prepareErrands(_id, config, true, offset, data.limit);
    const errandsNew = ChatFetchRequestCallback(data, errandsFetched);

    // if errandsNew is empty noneed to re-render
    if (ValidatorFunctions.isNotEmptyArray(errandsNew)) {
      setErrands((prev) => [...prev, ...errandsNew]);
    }
  }, [_id, getAbortSignal]);

  /**
   * This function consolidates logic required to update the selectedIndex state
   * when either a chat is closed or the user's participant is removed.
   * @param {number} index: index of chat being removed from the list of errands
   */
  const removeSelectedIndex = useCallback((index: number, parentIndex: number) => {
    setSelectedIndex((prevIndex) => {
      // The index of the selectedIndex that matches the provided index
      // Will be -1 if we are not currently on this errand
      let indexOfSelectedIndex = prevIndex.indexOf(index);
      // if the provided index is selected parentIndex exits and is not already selected, then select parent errand
      if (indexOfSelectedIndex !== -1 && parentIndex !== -1 && prevIndex.indexOf(parentIndex) === -1) {
        prevIndex[indexOfSelectedIndex] = parentIndex;
        return [...prevIndex];
      }

      let shouldSpread = false;
      for (let i = 0; i < prevIndex.length; i++) {
        if (prevIndex[i] > index) {
          prevIndex[i] = prevIndex[i] - 1;
          shouldSpread = true;
        } else if (prevIndex[i] === index) {
          prevIndex[i] = 0;
          shouldSpread = true;
        }
      }
      if (shouldSpread) {
        prevIndex.filter((v, i, a) => a.indexOf(v) === i);
        return [...prevIndex];
      }
      return prevIndex;
    });
  }, []);

  const startUserSession = useCallback(async (hashKey = '', allowContactSmls = true) => {
    const searchParams = new URLSearchParams(document.location.search);
    const isWidget = searchParams.get('widget') === 'true';
    const isSmlsWidget = isWidget && process.env.REACT_APP_SUNSOFT.includes(searchParams.get('origin'));

    if (ThinClientUtils.isThinClient()){
      const customWindow = window as CustomWindow;
      // Thin Client injects `window.handleThinClientMessage(msg)` into the UI.
      // handleThinClientMessage() is defined here
      // msg is an object with `type` and `data` properties
      customWindow.handleThinClientMessage = (msg) => {
        console.log('Received message from Thin Client:', JSON.stringify(msg, null, 2));
        switch (msg.type) {
          case 'reply':
            // user tapped on notification while app running in background
            const chatIdFromNotification = msg.data;
            let indexToSelect = 0;
            setErrands((prev) => {
              indexToSelect = prev.findIndex((errand) => errand._id === chatIdFromNotification);
              return prev;
            });
            setSelectedIndex([indexToSelect]);
            break;
          default:
            break;
        }
      };
    }
    
    // if we're using AngelAi widget in SMLS, alert SMLS so it can prepare necessary data and trigger authentication itself
    if (isSmlsWidget && allowContactSmls) {
      // reset values
      hasReceivedSmlsData.current = false;
      authenticatingAsNewUser.current = false;

      // alert SMLS
      console.log("Contacting SMLS from AngelAi");
      window.parent.postMessage({ source: 'AngelAi', functionality: 'sendDataToWidget', returnTarget: process.env.REACT_APP_PUBLIC_URL }, searchParams.get('origin'));
      
      // wait for 3 seconds, then check if response received from SMLS; if not, continue authentication without SMLS data
      await new Promise(resolve => setTimeout(resolve, 3000));
      if (hasReceivedSmlsData.current) {
        return;
      }
      authenticatingAsNewUser.current = true;
      console.log("No response from SMLS, continuing authentication");
    }

    await login(hashKey);
  }, [login]);

  /**
   * Fetches the closed chats by calling the `prepareErrands` function with the necessary parameters
   * and then updates the state with the result if it is an array.
   *
   * @returns {Promise<void>} - A promise that resolves when the closed chats are fetched and state is updated.
   */
  const fetchClosedChats = useCallback(async () => {
    const config = getAbortSignal('fetchClosedChats');
    const closedChats = await prepareErrands(_id, config, false, null, null);

    if (ValidatorFunctions.isTypeOfArray(closedChats)) {
      setChildErrands(closedChats);
    }
  }, [_id, getAbortSignal])
  

  // for managing authentication and setting of loan and user data between SMLS and the AngelAi widget
  const setUpWidgetFromSmls = useCallback(async (e) => {
    // only proceed if we are accessing from Sunsoft
    if (process.env.REACT_APP_SUNSOFT.includes(e.origin)) {
      if (typeof e.data !== 'string') {
        console.error("Invalid Sunsoft data");
        return;
      }

      console.log(`Message received from ${e.origin}`);

      if (authenticatingAsNewUser.current) {
        console.log("Already authenticating without SMLS data");
        return;
      }
      hasReceivedSmlsData.current = true;

      // false to prevent infinite loop
      await startUserSession(JSON.parse(e?.data)?.hashKey, false);
      return;
    }
  }, [startUserSession]);

  const chatBulkClose = useCallback(async(errands: Array<IErrand>) => {
    try {
      console.log("Conversation.tsx - chatBulkClose(): view syncing");

      if (!ValidatorFunctions.isNotEmptyArray(errands)) {
        return;
      }

      // close chats through morgan-core
      const config1 = getAbortSignal('chatBulkClose1');
      await axiosCall({
        url: 'chat/bulk/close',
        method: 'put',
        data: {
          chatIds: errands.map((x) => x._id),
        },
      }, config1);

      // Get currently loaded chat data and count
      const data = chatFetchDataRef.current;
      const loadedChatCount = ChatFetchCountLoaded(data);
      
      // remove chat from cache
      ChatFetchBulkDeleteFromCache(data, errands);

      // Fetch errands data with the abort signal
      const config2 = getAbortSignal('chatBulkClose2');
      const response = await prepareErrands(_id, config2, true, 0, loadedChatCount);

      if (!ValidatorFunctions.isNotEmptyArray(response)) return;

      setErrands((prevErrands) => {
        // Efficiently map previous errands by _id
        const prevErrandsMap = new Map(prevErrands.map((chat) => [chat._id, chat]));

        // Build the updated errands list using previous data where available
        const updatedErrands = response.map((newChat) => 
          prevErrandsMap.get(newChat._id) || newChat
        );

        setSelectedIndex((prevSelectedIndex) => {
          // Store selected chat _ids for easy comparison
          const selectedChatIds = new Set(
            prevSelectedIndex.map(index => prevErrands[index]?._id).filter(Boolean)
          );

          // Map through updated errands to rebuild the selected indices
          const updatedSelectedIndices = updatedErrands.reduce((indices, chat, index) => {
            if (selectedChatIds.has(chat._id)) {
              indices.push(index);
            }
            return indices;
          }, []);

          // Ensure at least one index is selected
          return updatedSelectedIndices.length ? updatedSelectedIndices : [0];
        });

        // Refresh cache with new data
        ChatFetchReloadView(data, response);

        fetchClosedChats();

        setRootMorphType(MorphType.None);

        return updatedErrands;
      });     
    } catch (err) {
        console.error(`Conversation.tsx - chatBulkClose():`, err.message)
    }
  }, [_id, fetchClosedChats, getAbortSignal]);

  useEffect(() => {
    //#startonconnectlogic
    if (!_id || !isEventsConnected) return;

    // check if the user was banned
    (async () => {
      const user = await axiosCall({ url: `user/${_id}?fields=active,banned` });
      if (user !== undefined && user !== null && user?.banned === true) {
        logout(true, true, 'Banned');
      }
    })();

    getUnreadNotifications(_id, setNotifications, showNotificationAlert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsConnected, _id]);

  /**
   * Handle eventsSocket core events
   */
  useEffect(() => {
    //#startonconnectlogic
    if (!_id || !isEventsConnected) return;
    
    // #eventssocketfunctions Each function corresponds to the socket event's name in cammelCase
    const onBan = (payload) => {
      console.info('Events Socket - ConversationPage - (ban)', payload);
      // if the user's obejct indicates that they are currently banned, return to the splashscreen
      if (payload?.data?.banned === true) {
        console.info(`Morgan Events - (ban): User has been banned`);
        logout(true, true, 'Banned');
      }
    }

    const onMonitorUserSession = (payload) => {
      console.info('Events Socket - ConversationPage - (monitor-user-session)', payload);
      logout();
    }

    /**
     * The primary purpose of the code below is to detect when errands are reopened
     * and chats of type form are created, load them on the ui and automatically select them.
     */
    const onChatStatusWaiting = async (payload) => {
      console.log('Events Socket - ConversationPage - (chat-status-waiting)', payload);
      const data = payload?.data;
      const chatId = data?._id;
      const position = data?.position;

      if (ValidatorFunctions.isUndefinedOrNull(chatId)) {
        return;
      }

      const chatFetchData = chatFetchDataRef.current;
      const chatAlreadyLoaded = ChatFetchCheckIfAlreadyLoaded(chatFetchData, chatId);

      // don't select waiting chats when they are found or we are loading errands
      if (chatAlreadyLoaded) {
        return;
      }

      // chat status change occured on unloaded chat so noneed to re-fetch
      if (ValidatorFunctions.isTypeOfNumber(position)) {
        const positionMax = ChatFetchPositionMax(chatFetchData);

        if (position < positionMax) {
          setupErrands(chatId);
        } else if (position === positionMax) {
          // get last chat that has positionMax
          const chatObj = errands.slice().reverse().find((x) => x.position === positionMax);

          if (ValidatorFunctions.isNotUndefinedNorNull(chatObj)) {
            // we have valid chatObj

            // calculated epoch time based on createdAt timestamp
            const epochLoaded = GetEpochFromObjCreatedAt(chatObj);
            const epochIncoming = GetEpochFromObjCreatedAt(data);

            if (epochLoaded < epochIncoming) {
              setupErrands(chatId);
            }
          }
        }
      }
    }

    const onNotificationsUpdate = async (payload) => {
      if (payload === undefined || payload === null || payload?.data === undefined || payload?.data === null) {
        return;
      }

      console.info('Events Socket - ConversationPage - (notifications-update)', payload);

      updateErrandMessagePreview(payload.data?.messageId);

      // if the chat is not currently active or if the document is hidden, add the notification to the array
      if (joinedChat.current.findIndex((id) => id === payload?.data?.chat?._id) === -1 || document.visibilityState === 'hidden') {
        setNotifications((prevState) => [...prevState, payload?.data]);
        showNotificationAlert(true);
      } else {
        // otherwise clear the notification in real time so that operators can see that the mesasge was read
        await axiosCall({
          url: `user/${_id}/notification/${payload?.data?._id}`,
          method: 'put',
          data: {
            status: 'read',
          },
        });
      }

      let senderName = '';
      // on the web browser notifications send the nickname of the user/operator otherwise send the firstname
      if (
        payload?.data?.messageId?.sender?.nickname !== undefined &&
        payload?.data?.messageId?.sender?.nickname !== null &&
        payload?.data?.messageId?.sender?.nickname !== ''
      ) {
        senderName = payload.data.messageId.sender.nickname;
      } else {
        senderName = payload.data.messageId.sender.firstname; // firstname should not be empty, it should be at least anonymous.
      }

      const title = senderName[0].toUpperCase() + senderName.substr(1).toLowerCase() + ':';
      const preview = handlePreviews(payload.data?.messageId);

      // triggering this directly removes a useEffect and useState from the root level and prevents additional rerenders
      triggerBrowserNotificationAlert({
        title: title,
        message: preview,
        chat: payload?.data?.chat?._id,
      } as IBrowserNotification);

      // Only increase the unread message count on the widget if we are viewing the site through the widget and the unread message is for the current chat
      if (isWidget && joinedChat.current.indexOf(payload?.data?.chat?._id) !== -1) {
        // This logic was changed on widget code but not here... fixed unread message count
        window.parent.postMessage({ message: payload?.data.messageId?._id, source: 'Morgan'});
      }
    }

    const onDisplayNameUpdates = (payload) => {
      console.log('Events Socket - ConversationPage - (display-name-update)', payload);

      // store the new chat status data in a variable
      let chatId = payload?.data?._id;
      let displayName = payload?.data?.displayName;

      // check if chat status exists
      if (!chatId || !displayName) {
        console.error('(display-name-update) invalid payload data', payload);
        return;
      }

      // update errands list to show the new display name
      setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        let index = prev.findIndex((c) => c._id === chatId);
        if (index === -1) return prev;
        prev[index] = { ...prev[index], name: displayName };
        return [...prev];
      });
    };

    const onChatColorUpdates = (payload) => {
      console.log('Events Socket - ConversationPage - (chat-color-update)', payload);

      // store the new chat status data in a variable
      let chatId = payload?.data?._id;
      let color = payload?.data?.color;

      // check if chat status exists
      if (!chatId || !color) {
        return;
      }

      // update errands list to show the new chat color
      setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        let index = prev.findIndex((c) => c._id === chatId);
        if (index === -1) return prev;
        prev[index] = { ...prev[index], color: color };
        return [...prev];
      });
    };

    const onChatStatusClosed = async(payload) => {
      console.log('Events Socket - ConversationPage - (chat-status-closed)', payload);

      const chatId = payload?.data?._id;

      const chatFetchData = chatFetchDataRef.current;
      const chatAlreadyLoaded = ChatFetchCheckIfAlreadyLoaded(chatFetchData, chatId);

      if (chatAlreadyLoaded) {
        chatBulkClose([{_id: chatId} as IErrand]);
      }
    };

    console.log(`Events Socket - ConversationPage - (on) - ${isEventsConnected ? 'connected' : 'disconnected'}`);
    eventsSocket.current?.on('ban', onBan);
    eventsSocket.current?.on('monitor-user-session', onMonitorUserSession);
    eventsSocket.current?.on('chat-status-closed', onChatStatusClosed);
    eventsSocket.current?.on('chat-status-waiting', onChatStatusWaiting);
    eventsSocket.current?.on('notifications-update', onNotificationsUpdate);
    eventsSocket.current?.on('display-name-update', onDisplayNameUpdates);
    eventsSocket.current?.on('chat-color-update', onChatColorUpdates);
    return () => {
      console.log(`Events Socket - ConversationPage - (off) - ${isEventsConnected ? 'connected' : 'disconnected'}`);
      eventsSocket.current?.off('ban', onBan);
      eventsSocket.current?.off('monitor-user-session', onMonitorUserSession);
      eventsSocket.current?.off('chat-status-closed', onChatStatusClosed);
      eventsSocket.current?.off('chat-status-waiting', onChatStatusWaiting);
      eventsSocket.current?.off('notifications-update', onNotificationsUpdate);
      eventsSocket.current?.off('display-name-update', onDisplayNameUpdates);
      eventsSocket.current?.off('chat-color-update', onChatColorUpdates);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsConnected, _id]);

  /**
   * Handle messagesSocket core events
   */
  useEffect(() => {
    if (!_id) {
      return;
    }

    if (!isMessagesConnected) {
      setRootMorphType(MorphType.None);
      return;
    }

    // #messagessocketfunctions Each function corresponds to the socket event's name in cammelCase
    const onJoinedChat = (payload) => {
      console.log(`Messages Socket - ConversationPage - (joined-chat) ${payload?.data}`, payload);
      joinedChat.current.push(payload?.data);
    }

    const onLeftChat = (payload) => {
      console.log(`Messages Socket - ConversationPage - (left-chat) ${payload?.data}`, payload);
      // remove read notifications from the ui
      setNotifications((prevState) => [...prevState.filter((n) => joinedChat.current.indexOf(n.chat?._id) === -1)]);
      joinedChat.current = joinedChat.current.filter((chat) => chat !== payload?.data);
    }

    const onParticipantActivityUpdate = async (payload) => {
      const data = payload?.data;
      const message = payload?.message;
      const userData = data?.userData;
      const chat = data?.chat;
      console.log(`Messages Socket - ConversationPage - (participant-activity-update)`, payload);

      if (ValidatorFunctions.isUndefinedOrNull(userData)) {
        return;
      }

      if (ValidatorFunctions.isUndefinedOrNull(chat)) {
        return;
      }

      // only trigger for the current user
      if (userData._id !== _id) {
        return;
      }

      // only trigger for new participant entries
      if (message.indexOf('inserted') === -1) {

        // Update the participant's messageHistoryAllowed for this errand if it has changed, and the user is not the primary user.
        setErrands((prev: IErrand[]) => {
          if (!Array.isArray(prev)) {
            console.warn('setErrands prev is not an array');
            prev = [];
          }
          // Get the index of the errand from the current IErrand array, if it is not found return the array as is.
          let errandIndex = prev.findIndex((e) => e._id === chat);
          if (errandIndex === -1) return prev;

          // Get the index of the current user participant. If the participant record is not found, return the array as is.
          let participantIndex = prev[errandIndex].participants.findIndex((p) => p._id === data?._id);
          if (participantIndex === -1) return prev;

          // If the current participant's messageHistoryAllowed does not equal the update event that has been captured, and the user is not the primary user, update the historyAllowed state.
          if(prev[errandIndex].participants[participantIndex].messageHistoryAllowed !== data.messageHistoryAllowed && !data.primary){
            prev[errandIndex].participants[participantIndex].messageHistoryAllowed = data.messageHistoryAllowed;
          }
          return [...prev];
        });

        return;
      }


      const chatFetchData = chatFetchDataRef.current;
      const chatAlreadyLoaded = ChatFetchCheckIfAlreadyLoaded(chatFetchData, chat);

      // don't select waiting chats when they are found or we are loading errands
      if (chatAlreadyLoaded) {
        return;
      }

      // reload errands
      setupErrands(chat);
    }

    const onRemoveParticipant = (payload) => {
      /*if (sessionRestore.current === true) {
        // if session restore is in progress don't remove the errand from the list.
        console.log(`Messages Socket - ConversationPage - (remove-participant) - canceled by session restore`, payload);
        return;
      }*/
      console.log(`Messages Socket - ConversationPage - (remove-participant)`, payload);
      // store the participant data in a variable
      let removeData = payload?.data;

      // check if the removeData is null or undefined
      if (!removeData) {
        console.info('(remove-participant) message is null', payload);
        return;
      }
      // Remove the chat from the list of errands if this participant was removed
      setErrands((prev: IErrand[]) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        let errandIndex = prev.findIndex((e) => e._id === removeData.chat);
        if (errandIndex === -1) return prev;
        let participantIndex = prev[errandIndex].participants.findIndex((p) => p._id === removeData._id);
        if (participantIndex === -1) return prev;
        if (removeData.userId === _id) {
          // get the parentId so we can find the parentIndex.
          let parentId = prev[errandIndex].parentId
          prev.splice(errandIndex, 1); // splice here to make sure parentIndex is correct
          let parentIndex = prev.findIndex((e) => e._id === parentId);
          removeSelectedIndex(errandIndex, parentIndex);
        } else {
          prev[errandIndex].participants.splice(participantIndex, 1);
        }
        // return the list without the index
        return [...prev];
      });
    }
    const onStartSessionRestore = (payload) => {
      console.log("Messages Socket - ConversationPage - (start-session-restore)");
      console.log(payload);
      if (payload.data.userId === _id.toString()) {
        setShowSkeleton(true);
      }
    };

    const onAuthActivityUpdate = async (payload) => {
      try {
        const data = payload?.data;
        const child = data?.child;
        const lastActiveChat = data?.lastActiveChat;

        // Only enact the authentication on the user that submitted the request.
        if (_id.toString() !== child) return;

        console.info(`Messages Socket - ConversationPage - (auth-activity-update) ${JSON.stringify(data, null, 2)}`);

        const request = {
          url: `hashkey`,
          method: `POST`,
          data: {
            parameters: {
              _id: data?._id,
              chatId: lastActiveChat,
            },
          },
        };

        /**
         * 2024/10/16 - bold: We authenticate here, and the old user _id is replaced with the new user _id. If we don't
         * manually disconnect the sockets at this point, multiple events will be triggered on the old user _id, causing
         * duplicated auth-activity-update events and the socket to reconnect twice.
         */
        disconnectMessageSocket();
        disconnectEventSocket();

        const config = getAbortSignal('onAuthActivityUpdate');
        const response = await axiosCall(request, config);

        // update user information
        await login(response.key);
      } catch (error) {
        console.error(`Messages Socket - ConversationPage - (auth-activity-update) ${error.message}`);
      }
    }

    console.log(`Messages Socket - ConversationPage - (on) - ${isMessagesConnected ? 'connected' : 'disconnected'}`);
    messagesSocket.current?.on('joined-chat', onJoinedChat);
    messagesSocket.current?.on('left-chat', onLeftChat);
    messagesSocket.current?.on('participant-activity-update', onParticipantActivityUpdate);
    messagesSocket.current?.on('remove-participant', onRemoveParticipant);
    messagesSocket.current?.on('start-session-restore', onStartSessionRestore);
    messagesSocket.current?.on('auth-activity-update', onAuthActivityUpdate);
    return () => {
      console.log(`Messages Socket - ConversationPage - (off) - ${isMessagesConnected ? 'connected' : 'disconnected'}`);
      messagesSocket.current?.off('joined-chat', onJoinedChat);
      messagesSocket.current?.off('left-chat', onLeftChat);
      messagesSocket.current?.off('participant-activity-update', onParticipantActivityUpdate);
      messagesSocket.current?.off('remove-participant', onRemoveParticipant);
      messagesSocket.current?.off('start-session-restore', onStartSessionRestore);
      messagesSocket.current?.off('auth-activity-update', onAuthActivityUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, _id]);

  useEffect(() => {
    setupErrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setupErrands]);

  useEffect(() => {
    window.addEventListener('message', setUpWidgetFromSmls);
    return () => window.removeEventListener('message', setUpWidgetFromSmls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUpWidgetFromSmls]);

  useInitialMount(startUserSession);

  // mark notifications as read when the visibility changes
  useDocumentVisibility(clearNotifications);

  useWindowDimensions(syncWindowState);

  // Clears the notification alert after 4.5 seconds
  useDebounce(() => showNotificationAlert(false), 4500, [notificationAlert]);

  return (
    <ErrorBoundary debug={`./src/Pages/Conversation/Conversation.tsx`}>
      {showReloadButton && <DarkUnclickableBackground />}
      {showSkeleton && <ConversationSkeletonLoader />}
      {/* Until the messages have fully loaded, render a skeleton loading screen instead.
       This will cover the entire conversation page, including conversation title,
       conversation body, errands, and input text field. */}
      <RootContext.Provider
        value={{
          conversationFooterRef,
          messagesAreLoading,
          setMessagesAreLoading,
          openedCaptcha,
          operatorView: false,
          setOpenedCaptcha,
          selectedIndex,
          setSelectedIndex,
          getErrandsLength,
          loadMoreErrands,
          syncErrandsSequence,
          leaveFormGoToParentChat,
          createSignatureMobileIsOn,
          setCreateSignatureMobileIsOn,
          editErrandId,
          errands,
          setErrands,
          rootMorphType,
          setRootMorphType,
          morphedId,
          childErrands,
          errandColorRef,
          userSelectedPromptRef,
          userSelectedPrompt,
          setUserSelectedPrompt,
          userSelectedVideoRef,
          videoMenuTitle,
          setVideoMenuTitle,
          promptName,
          setPromptName,
          handleActionWorkflow,
          triggerSlotMachine,
          drawerRef,
          isWidget,
          viewReload,
          chatFetchDataRef,
          fetchClosedChats,
          chatBulkClose,
          consentChecked,
          setConsentChecked,
          tpConsentGiven,
          setTpConsentGiven,
          shakingConsent,
          setShakingConsent
        }}
    >
          <div className={Styles.PageSection}>
            {errands && errands?.length > 0 && (
              <>
                {!createSignatureMobileIsOn && !isWidget &&(
                  <RopeDrawer isConnected={isEventsConnected && isMessagesConnected}>
                  <ErrandsContainer
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                      notifications={notifications}
                      errands={errands}
                      setErrands={setErrands}
                      triggerSlotMachine={triggerSlotMachine}
                      showSlotMachine={showSlotMachine}
                      exitSlotMachine={exitSlotMachine}
                    />
                  </RopeDrawer>
                )}
                <QueryClientProvider client={queryClient}>
                    <div className={Styles.ConversationsContainer}>
                      {showSlotMachine && slotMachineErrandId && slotMachineUserAction && (
                        <SlotMachine
                          errand={errands[errands?.map((e) => e._id)?.findIndex((id) => id === slotMachineErrandId)]}
                          chatId={slotMachineErrandId}
                          userActionId={slotMachineUserAction}
                          userId={getUserId()}
                          exitSlotMachine={exitSlotMachine}
                        />
                      )}
                      {errands.filter((v, index, a) => (selectedIndex || []).indexOf(index) !== -1).length > 0 ? (
                        errands
                          .filter((v, index, a) => (selectedIndex || []).indexOf(index) !== -1)
                          .map((errand, index) => {
                            return (
                              <Conversation
                                key={errand?._id || index}
                                index={index}
                                selectedIndex={selectedIndex}
                                errand={errand}
                                errands={errands}
                                setErrands={setErrands}
                                notifications={notifications}
                                setNotifications={setNotifications}
                                setSelectedIndex={setSelectedIndex}
                                notificationAlert={notificationAlert}
                                triggerSlotMachine={triggerSlotMachine}
                                showSlotMachine={showSlotMachine}
                                exitSlotMachine={exitSlotMachine}
                                handleActionWorkflow={handleActionWorkflow}
                                showConfirmResendDialog={showConfirmResendDialog}
                                setShowConfirmResendDialog={setShowConfirmResendDialog}
                              />
                            )
                          })
                      ) : (
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            height: '100%',
                            borderRadius: '8px',
                            width: '100%',
                            boxShadow: '0px 0px 0px 1px var(--gray040)',
                            backgroundColor: 'var(--peach000)',
                          }}
                        >
                          <Paper sx={{ p: 1.5 }}>
                            <Typography>{t('conversationErrandsSelectCreate')}</Typography>
                          </Paper>
                        </Stack>
                      )}
                    </div>
                </QueryClientProvider>
              </>
            )}
          </div>
      </RootContext.Provider>
    </ErrorBoundary>
  );
};

export default ConversationPage;
