import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { Style } from '@styles/MorphErrandStyles';
import { MorphType } from '@common/MorphType';
import axiosCall from '@services/axios';
import { TMorphErrandProps } from '@mTypes/TMorphErrand';
import { IRootContext, RootContext } from '@contexts/RootContext';
import { NoArchiveIcon } from '@assets/Icons';
import Styles from '@styles/MorphErrand.module.css';
import { useUserContext } from '@contexts/user';
import { getNewChatPosition } from '@common/errandUtils';

// define default parameters to prevent crashing without the need of optional operators
const MorphErrandTopBox = ({ errand, setSelectedClosedErrand }: TMorphErrandProps) => {
  const { t, i18n } = useTranslation();
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const rootContext = useContext<IRootContext>(RootContext);
  const { _id } = useUserContext();
  const closedErrands = rootContext.childErrands?.filter((errand) => ['closed'].includes(errand.status));
  const [selectedErrand, setSelectedErrand] = useState(closedErrands[0]);
  const lengthRef = useRef(0);
  const isDragging = useRef(-1);
  const firstErrandRef = useRef(null);

  const handleSlideClick = (isSelected: boolean, index: number) => {
    // Check if the errand is selected and not currently being dragged
    if (isSelected && isDragging.current === -1) {
      // Make an API call to update the errand status
      axiosCall({
        url: `chat/${closedErrands[index]?._id}`,
        method: 'put',
        data: {
          // Set the status to 'waiting-updates' to reopen the errand
          status: 'waiting-updates',
          // Update the last opened timestamp
          lastOpened: new Date(Date.now()).toISOString(),
          // Set the position to the end of the current errands list
          position: getNewChatPosition(rootContext.errands),
        },
      });

      // Reset the MorphType to None for both errand and root contexts
      // This allows the errand to be opened with a single click
      errandContext.setMorphType(MorphType.None);
      rootContext.setRootMorphType(MorphType.None);
    } // without the following the selected closed errand won't expand 
    else if (closedErrands[index]) { 
      // Update the selected closed errand index
      setSelectedClosedErrand(index);
      // Set the selected errand to the clicked closed errand
      setSelectedErrand(closedErrands[index]);
    }
  };

  useEffect(() => {
    if (lengthRef.current === 0 && lengthRef.current !== closedErrands.length) {
      lengthRef.current = closedErrands.length
      handleSlideClick(false, 0);
      firstErrandRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[closedErrands?.length]);

  const handleScroll = (e) => {
    e.currentTarget.scrollLeft += (e.deltaY);
  }

  const handleStart = (e) => {
    let x = e?.changedTouches?.[0]?.clientX || e?.clientX
    isDragging.current = x;
  };

  const handleEnd = (e) => {
    isDragging.current = -1;
  };

  const handleMove = (e) => {
    if (isDragging.current !== -1) {
      let x = e?.changedTouches?.[0]?.clientX || e?.clientX
      e.currentTarget.scrollLeft -= (x - isDragging.current)/8;
    }
  };

  return (
    <Style className="Style">
      <div 
        className="morphErrandSwiper" 
        onWheel={(e) => handleScroll(e)}
        onMouseDown={(e) => handleStart(e)}
        onMouseUp={(e) => handleEnd(e)}
        onMouseMove={(e) => handleMove(e)}
      >
        <div>
          {closedErrands &&
            closedErrands.map((errand, index, array) => {
              return (
                <button
                  key={index}
                  ref={index === 0 ? firstErrandRef : null}
                  onClick={() => handleSlideClick(errand?._id === selectedErrand?._id, index)}
                  className={errand?._id === selectedErrand?._id ? 'details' : 'date'}
                  onKeyDown={(e) => {
                    if (
                      (index === 0 && e.key === 'Tab' && e.shiftKey) ||
                      (index === array.length - 1 && e.key === 'Tab' && !e.shiftKey)
                    ) {
                      e.preventDefault();
                      errandContext?.footerInputRef?.current?.focus();
                      errandContext?.setMorphType(MorphType.None);
                    }
                    if (e.key === 'Escape') {
                      e.preventDefault();
                      errandContext?.footerInputRef?.current?.focus();
                      errandContext?.setMorphType(MorphType.None);
                    }
                  }}
                >
                  <div>
                    <strong>{new Date(errand.createdAt)?.toLocaleDateString(i18n.language, { day: '2-digit' })}</strong>
                    <span>{new Date(errand.createdAt)?.toLocaleDateString(i18n.language, { weekday: 'short' })}</span>
                  </div>
                  <div>
                    <div>{errand.displayName || 'AngelAi'}</div>
                    <div>{errand.preview}</div>
                    <div>{t('clickToOpen')}</div>
                  </div>
                </button>
              )
            })}
        </div>
        {closedErrands.length === 0 && (
          <span className="noClosedErrands">
            <NoArchiveIcon className="noArchiveIcon" />
            {t('noClosedErrands')}
          </span>
        )}
      </div>
    </Style>
  );
};

const MorphErrandIndent = ({ selectedClosedErrand }) => {
  const { t, i18n } = useTranslation();
  const rootContext = useContext<IRootContext>(RootContext);
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const closedErrands = rootContext.childErrands?.filter((errand) => ['closed'].includes(errand.status));

  const handleCloseArchive = () => {
    errandContext.setMorphType(MorphType.None);
    rootContext.setRootMorphType(MorphType.None);
  };

  const displayErrandDate = () => {
    return (
      <div>
        {new Date(closedErrands[selectedClosedErrand]?.createdAt || 0)?.toLocaleDateString(i18n.language, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
      </div>
    );
  };

  const noClosedErrands = () => {
    return (
      <div className={Styles.exitButton} onClick={handleCloseArchive}>
        {t('exitButton')}
      </div>
    );
  };

  return closedErrands.length === 0 ? noClosedErrands() : displayErrandDate();
};

export { MorphErrandTopBox, MorphErrandIndent };
