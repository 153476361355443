import { Box, styled } from '@mui/system';
import { Button } from '@mui/material';
import React, { useContext, useState } from 'react';
import { PlayButton } from '@assets/Icons';
import { useMorphContext } from '@contexts/MorphContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { getUserConsent, getUserPlayedToday } from '@storage/userStorage';

const StyledButton = styled(Button)({
  position: 'relative',
  fontSize: '0.75rem',
  fontWeight: 'normal',
  zIndex: '2',
  alignContent: 'center',
  width: '120%',
  height: '25px',
  backgroundColor: 'var(--orange500)',
  border: '1px solid var(--orange500)',
  color: 'var(--gray000)',
  borderRadius: '5px',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  boxShadow: 'none',
  bottom: '12px',
  '&:hover': {
    backgroundColor: 'orange',
    color: 'var(--gray000)',
  },
});

const StyledWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  flexDirection: column;
  alignItems: center;
  margin: 1px auto 6px;
  height: 10px;
  width: ${useMediaQuery(theme.breakpoints.up('sm')) ? '950px' : 'inherit'};
`);

const PlayButtonWrapper = styled(Box)({
  height: '40px',
  width: '40px',
  borderRadius: '50%',
  backgroundColor: 'var(--peach900)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '5px',
  marginRight: '5px',
});

const StyledPlayButton = styled(PlayButton)({
  height: '20px',
  width: '20px',
  fill: 'var(--gray000)',
})

const MorphSlotMachine = () => {
  const { t } = useTranslation();
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const { openSlotMachine } = useMorphContext();
  const [preventPlay, setPreventPlay] = useState(getUserPlayedToday() || false);

  const handleClick = async () => {
    if (getUserConsent() !== 'true') return;
    if(preventPlay){
      errandContext.setMorphType(MorphType.None);
    } else {
      let hasPlayedToday = await openSlotMachine();
      if (hasPlayedToday) {
        setPreventPlay(true);
        errandContext.setMorphType(MorphType.None);
      }
    }
  }

  return (
    <StyledWrapper>
        <StyledButton
          variant="contained"
          onClick={handleClick}
          sx={{cursor: getUserConsent() !== 'true' ? 'not-allowed' : 'pointer'}}
          >
          
          {t('slotMachineClickToPlay')} {/*Click Here to*/}
          <PlayButtonWrapper sx={{cursor: getUserConsent() !== 'true' ? 'not-allowed' : 'pointer'}}>
            <StyledPlayButton sx={{cursor: getUserConsent() !== 'true' ? 'not-allowed' : 'pointer'}}/>
          </PlayButtonWrapper>
          {t('playGame')} {/*Play Game*/}
        </StyledButton>
    </StyledWrapper>
  );
};

export default MorphSlotMachine;