import React, { useState, useRef } from 'react';
import { TextField } from '@mui/material';
import { useRootContext } from '@contexts/RootContext';
import { useErrandContext } from '@contexts/ErrandContext';
import { useTranslation } from 'react-i18next';
import Styles from '@styles/MorphErrandNew.module.css';
import { Ballerina6 } from '@assets/Icons';
import { AddIcon, WhiteSendArrow, Close, ArrowLeft } from '@assets/Icons';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import axiosCall from '@services/axios';
import { MorphType } from '@common/MorphType';
import { ChatType } from '@common/ChatType';
import { IErrand } from '@interfaces/Conversation';
import useInitialMount from '@common/hooks/useInitialMount';
import EmojiSelector from './EmojiSelector';
import { useUserContext } from '@contexts/user';
import { useMorphContext } from '@contexts/MorphContext';
import { getNewChatPosition } from '@common/errandUtils';

const MorphErrandNewTopBox = () => {
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const morphContext = useMorphContext();
  const { t } = useTranslation();
  const { _id, activeErrandCount } = useUserContext();
  const [colorIsOpen, setColorIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(rootContext.errandColorRef.current ?? 'dimgray');
  const [isExpandedColor, setIsExpandedColor] = useState(false);
  const [isCreatingErrand, setIsCreatingErrand] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [placeholder, setPlaceholder] = useState('');
  const createErrandInputRef = useRef<HTMLInputElement>(null);
  const colorArray = ['red', 'gold', 'green', 'blue', 'dimgray'];
  const expandedColorArray = ['red', 'orange', 'gold', 'green', 'blue', 'purple', 'pink', 'dimgray', 'black'];
  const mobileArray = ['orange', 'purple', 'pink', 'black'];
  const [isLoanConditions, setIsLoanConditions] = useState(false);
  
  const initialFunctions = () => {
    getPlaceholder();
    checkIsLoanConditions();
  }

  const getPlaceholder = () => {
    if (rootContext.rootMorphType === MorphType.ErrandEdit){
      rootContext.setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        let index = prev.findIndex((e) => e._id === rootContext.editErrandId.current);
        if (index === -1) return prev;
        setPlaceholder(prev[index]?.displayName);
        return prev;
      });
    }
  }

  const checkIsLoanConditions = () => {
    if (morphContext.errandType === 'conditions'){
      setIsLoanConditions(true);
    }else{
      setIsLoanConditions(false);
    }
  }

useInitialMount(() => initialFunctions());

  const toggleColorPicker = () => {
    setColorIsOpen((prev) => !prev);
    if (isExpandedColor) {
      setIsExpandedColor(false);
    }
  };

  const handleColorSelection = (color) => {
    setSelectedColor(color);
    rootContext.errandColorRef.current = color;
    toggleColorPicker();
  };

  const colorPicker = () => {
    return (isExpandedColor ? (isMobileOrTablet() ? mobileArray : expandedColorArray) : colorArray).map(
      (color, index) => {
        return (
          <div
            className={Styles.colorCircle}
            onClick={() => handleColorSelection(color)}
            style={{ backgroundColor: `${color}` }}
            key={index}
          ></div>
        );
      }
    );
  };

  const createErrand = async (parentId) => {
    const payload = {
      url: 'chat',
      method: 'post',
      data: {
        parentId,
        ...(createErrandInputRef.current ? { displayName: createErrandInputRef.current.value } : {}),
        color: rootContext.errandColorRef.current,
        type: ChatType.Errand,
        position: getNewChatPosition(rootContext.errands),
        participants: [
          {
            userId: _id,
            userType: 'User',
            primary: true,
            messageHistoryAllowed: true,
          },
        ],
      },
    };

    if (!isCreatingErrand) {
      setIsCreatingErrand(true);
      try {
        const response: IErrand = await axiosCall(payload);

        if (response !== undefined && response !== null) {
          await axiosCall({
            url: `chat/${response._id}/queue`,
            method: 'post',
            data: {
              workflows: ['Show Disclaimer', 'Welcome User'],
            },
          });
        }

        // hide the errands drawer on mobile devices. On desktop it is always visible
        rootContext.drawerRef.current?.click();
      } catch (err) {
        console.error(err);
      } finally {
        handleCloseErrandNew();
        setIsCreatingErrand(false);
      }
    } else {
      console.log('Can only create one errand at a time.');
    }
  };

  const handleCreateErrand = async (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();

      const parentId = rootContext.errands.find((p) => p.isDefault);

      // assist position below conversations and above errands
      createErrand(parentId);
    } catch (err) {
      console.error(`Error handleCreateErrand`, err);
    }
  };

  const handleEditErrandName = async (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!rootContext.editErrandId.current) {
      console.error('Cannot edit errand, invalid chat id');
      return;
    }

    const payload = {
      url: `chat/${rootContext.editErrandId.current}`,
      method: 'put',
      data: {
        ...(createErrandInputRef.current ? {displayName: createErrandInputRef.current.value} : {}),
        color: rootContext.errandColorRef.current
      },
    };

    try {
      const response = await axiosCall(payload);

      rootContext.setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        let index = prev.findIndex((e) => e._id === response._id);
        prev[index] = {
          ...prev[index],
          ...(createErrandInputRef.current ? {displayName: createErrandInputRef.current.value} : {}),
        };
        return [...prev];
      });
    } catch (err) {
      console.error(err);
    } finally {
      handleCloseErrandNewWithDelay();    }
  };

  const handleCreateOrEditErrand = (e) => {
    if (rootContext.rootMorphType === MorphType.ErrandEdit) {
      handleEditErrandName(e);
    } else {
      handleCreateErrand(e);
    }
  };

  const handleCloseErrandNew = () => {
    rootContext.setRootMorphType(MorphType.None);
    errandContext.setMorphType(MorphType.None);
  };

  const handleCloseErrandNewWithDelay = () => {
    setIsClosing(true)
    // use setTimeout to give time for animation to run before closing the edit errand footer
    setTimeout(() => {
      rootContext.setRootMorphType(MorphType.None);
      errandContext.setMorphType(MorphType.None);
    }, 750)
  };

  const handleEmoji = (event, emojiObject) => {
    const currentInput = createErrandInputRef.current.value
    createErrandInputRef.current.value = currentInput + emojiObject.emoji
  };

  const ballerinaIcon = () => {
    return (
      <button
          className={`${Styles.editBallerinaIcon} ${colorIsOpen && Styles.shift}`}
          onClick={toggleColorPicker}
          style={{
            backgroundColor: selectedColor,
          }}
        >
          <Ballerina6 height={50} width={50} color={'var(--gray000)'} />
        </button>
    )
  }

  const returnIcon = () => {
    return (
      <button
        className={`${Styles.returnIcon}`}
        onClick={toggleColorPicker}
        style={{backgroundColor: selectedColor}}
      >
        <ArrowLeft height={30} width={30} />
      </button>
    )
  }


  return (
    <div className={[
      Styles.morphErrandNewContainer,
      ...(isClosing ? [Styles.morphErrandNewContainerClosing] : []),
      ...(isLoanConditions ? [Styles.isLoanConditions] : [])
    ].join(' ')} 
    >
      <div className={Styles.tabWrapper}>
        <div className={Styles.tab}>
          {rootContext.rootMorphType === MorphType.ErrandEdit || errandContext.morphType === MorphType.ErrandEdit
            ? colorIsOpen
              ? t('conversationErrandsFooterColor')
              : t('conversationErrandsFooterEdit')
            : t('newErrandButton')}
        </div>
        <div className={Styles.tabAfter}/>
      </div>
      <div className={Styles.wrapper}>
        {colorIsOpen ? returnIcon() : ballerinaIcon()}
        {colorIsOpen && (
          <div
            className={`${Styles.colorMenu} ${
              isExpandedColor
                ? isMobileOrTablet()
                  ? Styles.colorMenuMobile
                  : Styles.colorMenuExpanded
                : Styles.colorMenuSmall
            }`}
          >
            {colorPicker()}
            {isMobileOrTablet() ? (
              <AddIcon className={Styles.addIcon} onClick={() => setIsExpandedColor((prev) => !prev)} />
            ) : (
              !isExpandedColor && (
                <AddIcon className={Styles.addIcon} onClick={() => setIsExpandedColor((prev) => !prev)} />
              )
            )}
          </div>
        )}
        <form className={`${Styles.form} ${colorIsOpen && Styles.hide}`}>
          <TextField
            className={Styles.input}
            autoFocus={!isMobileOrTablet()}
            autoComplete="off"
            aria-autocomplete="none"
            inputRef={createErrandInputRef}
            onKeyDown={(e) => e.key === 'Enter' && handleCreateOrEditErrand(e)}
            id="outlined-input"
            placeholder={t('enterTitleHere')}
            type="input"
            defaultValue={rootContext.rootMorphType === MorphType.ErrandEdit ? placeholder : ''}
          />
        </form>
        <div className={`${Styles.emoji} ${colorIsOpen && Styles.hideEmoji}`}>
          <EmojiSelector handleEmoji={handleEmoji}/>
        </div>
        <button onClick={(e) => handleCreateOrEditErrand(e)} className={Styles.sendButton}>
          <WhiteSendArrow  />
        </button>
        <button className={Styles.exitButton} onClick={handleCloseErrandNew}>
          <Close />
        </button>
      </div>
    </div>
  );
};

export default MorphErrandNewTopBox;
