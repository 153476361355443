import React from 'react';
import { TDimensions } from "./ContentContainer";
import HintOption from "./HintOption";
import { useRootContext } from '@contexts/RootContext';

type THintOptionListProps = {
    options: {
        html: string;
    }[];
    renderFinal: boolean;
    currActiveIdx: number;
    isCurrMsgLastInChat: boolean;
    dimensions: TDimensions;
    numOfShownElements: number;
    suggestionTools: {
        open: () => void;
        close: () => void;
    };
    handleWorkflowOrMorphType: (hrefOrValue: any) => Promise<void>;
}

const HintOptionList = ({
    options,
    renderFinal,
    currActiveIdx,
    isCurrMsgLastInChat,
    dimensions,
    numOfShownElements,
    suggestionTools,
    handleWorkflowOrMorphType
}: THintOptionListProps) => {
    const rootContext = useRootContext();
    const renderList = () => (
        options.map(({ html }, idx) => {
            let measuredHeight = null;
            let isCurrActive = false;
            let animated = false;
            let className = '';

            // if all mounted (static view)
            if (renderFinal) {
                className = `allMountedHintContainer ${!rootContext.consentChecked && 'disabled'}`;
                if (currActiveIdx === idx && isCurrMsgLastInChat) {
                    isCurrActive = true;
                }
            }
            // if animated (animated view)
            else {
                className = 'hintOptionContainer';
                animated = true;
                measuredHeight = dimensions[idx].height;
            }
            // class for curr option item (when it goes one by one from top to bottom, typing each in the footer area.)
            const currClassName = `${className} ${isCurrActive ? 'hovered' : ''}`;

            // If animated AND curr idx is > then currently shownElements
            if (!renderFinal && idx > numOfShownElements) {
                return null; // do not render
            }

            return (
                <HintOption
                    html={html}
                    index={idx + 1}
                    animated={animated} // dynamic animated/final part
                    key={`${html}_${idx}`}
                    className={currClassName}
                    measuredHeight={measuredHeight}
                    suggestionTools={suggestionTools}
                    postClickHandler={handleWorkflowOrMorphType}
                />
            );
        })
    )

    return <>{renderList()}</>
}

export { HintOptionList };