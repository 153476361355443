/**
 * This file serves the common functions used in retrieving the errands
 * and setting up all of the pertinent data for them before rendering.
 * All functions are exported with no default export due to the utility class
 * this file serves as.
 * @author Jaden
 */
import axiosCall from '@services/axios';
import { IContext, IErrand, INotification, IParticipant, IUserChatAction, IUserData } from '@interfaces/Conversation';
import { t } from 'i18next';
import { OperatorErrand } from '@mTypes/Conversation';
import { ChatType } from '@common/ChatType';
import { getUserPlayedToday } from '@storage/userStorage';
import type { IMessage } from '@interfaces/Conversation';
import { AccessType } from './AccessType';
import { userActiveChatStatus, userActiveChatType, ResetFooterUserAction } from '@common/common';
import { ValidatorFunctions } from './Validators';

/**
 * This function queries morgan-core for matching conversations and errands for the user
 * that was supplied. It calls the getErrandsFromConversations function to prepare all of
 * the data in the errand before returning.
 * @param {string} userId The user's ID to get matching errands/conversations for
 * @param {AbortController} config
 * @param {boolean} fetchActiveChats fetch user's active chats
 * @param {number} offs
 * @returns {IErrand[]} an array of IErrand objects | null if the chatId supplied is undefined
 */
export const prepareErrands = async (userId: string, config, fetchActiveChats: boolean, offset: number, limit: number) => {
  try {
    if (ValidatorFunctions.isUndefinedOrNull(userId)) {
      throw new Error(`Undefined userId: |${userId}|`)
    }

    let url;
    if (fetchActiveChats) {
      url = `user/${userId}/getConsolidatedChats?type=${userActiveChatType.join(',')}&status=${userActiveChatStatus.join(',')}&offset=${offset}&limit=${limit}`;
    } else {
      url = `user/${userId}/getConsolidatedChats?type=conversation,errand,form,conditions&status=closed`;
    }

    let conversations: IErrand[] = await axiosCall({
      url: url,
      method: 'get',
    }, config);
    
    if (conversations) {
      conversations = getAdditionalData(conversations);
      // Make this request get the sessionToken for the primary user of each conversation, not the passed in userId
      for (const convo of conversations) {
        convo.ssAccessTokenValid = convo.ssAccessToken !== null;
        convo.previewAllowed = convo.participants.find((p) => p.userData?._id === userId)?.primary;
        ResetFooterUserAction(convo);
      }
    }

    return conversations;
  } catch (err) {
    if (err?.message !== 'CanceledError: canceled') {
      console.error(`ErrandUtils: Error getting Morgan errands: |${err?.message}|`);
    }

    return [];
  }
}

/**
 * This function queries morgan-core for the additional setup information for the Errand object.
 * It calls the getAdditionalData, getParticipantData, and getContextData functions to 
 * prepare the errand with all necessary data before being returned.
 * @param {OperatorErrand[]} errands The morgan-core queried conversations for the tab: mine/live/history/team/group
 * @returns {IErrand} The IErrand object of the returned errand with context, participant data, and previews all set
 */
export const prepareOperatorErrands = async(conversations: OperatorErrand[], operatorData: IUserData | undefined, abortController: AbortController = null, onlineUsers = [], showPreviews = false) => {
    if (!Array.isArray(conversations) || conversations.length === 0) return [];
    if (!operatorData) return [];
    if(!abortController?.signal?.aborted){
      conversations = getAdditionalData(conversations) as OperatorErrand[];

      for await(let convo of conversations) {
        convo.onlineStatus = getOnlineStatus(convo, onlineUsers);
        convo.displayString = getOperatorDisplayString(convo, operatorData);
        convo.showInactive = shouldShowInactive(convo, operatorData);
        let operatorArr = getOperatorParticipants(convo, operatorData, true);
        let assistedBy;
        if (Array.isArray(operatorArr) && operatorArr?.length > 0) {
          assistedBy = t('conversationListAssistedBy');
          operatorArr.forEach(
            (op) => (assistedBy += `${op?.userData?.firstname || ''} ${op?.userData?.lastname || ''}, `)
          );
          assistedBy = assistedBy?.slice(0, -2)?.trim();
        }
        convo.assistedBy = assistedBy || t('conversationListAssistedBy') + ' ' + t('conversationListNone');
        //const notification = getLastNotificationForChat(notifications, convo);
      }
    }
    return conversations;
}

// const getLastNotificationForChat = (notifications: INotification[], convo: OperatorErrand) => {
//   notifications.reverse();
//   return notifications.find((n) => n.messageId && n.chat._id === convo._id)
// }

export const patchNullMessagesOf = (preparedArr, withMessages) => {
  // Create a map to store objects based on their _id for faster lookup
  const mapById = new Map();

  // Populate the map with objects from preparedArr
  preparedArr.forEach(obj => mapById.set(obj._id, { ...obj }));

  // Merge objects from withMessages into the map
  withMessages.forEach(obj => {
      const existingObj = mapById.get(obj._id);

      if (existingObj) {
          // Compare objects and create a new object with merged data
          const newObj = existingObj;

          // Add any missing keys from obj to newObj
          Object.keys(obj).forEach(key => {
              if (!existingObj[key]) {
                  newObj[key] = obj[key];
              }
          });

          // update existing object in the map
          mapById.set(obj._id, { ...newObj });
      }
  });

  // Convert the map values back to an array
  const mergedArray = Array.from(mapById.values());

  return mergedArray;
}

// get the name of a chat to be displayed in the ConversationsList
export const getOperatorDisplayString = (conversation: IErrand, operatorData: IUserData | undefined): string => {
  if (conversation.type === ChatType.Team) {
    const ops = getOperatorParticipants(conversation, operatorData);
    if (ops.length > 0) {
      let dispString = ops?.filter((u) => u.userData?.firstname)?.map((u) => u.userData?.firstname).join(',');
      if (dispString) {
        return dispString;
      }
    }
    return t('deletedUser');
  } else if (conversation.type === ChatType.Group) {
    return conversation.displayName;
  } else {
    const primaryUser = getPrimaryParticipant(conversation);
    let string = '';
    // If no users in the conversation, show placeholder
    if (!primaryUser || !primaryUser._id) {
      string = t('conversationListPlaceholderName');
    } else if (!primaryUser.userData || !primaryUser.userData?.firstname || !primaryUser.userData?._id) {
      string = t('deletedUser');
    } else {
      string = `${primaryUser.userData.firstname || ''} ${primaryUser.userData.lastname || ''}`;
    }

    // one last step
    if (conversation.type === ChatType.Errand) {
      if (conversation.displayName) string += ` (${conversation.displayName})`;
      else string += t('conversationListErrand');
    }

    return string;
  }
};

// Based on the name of a chat to be displayed in the ConversationsList, determine whether to show the inactive flag
export const shouldShowInactive = (conversation: IErrand, operatorData: IUserData | undefined): boolean => {
  if (conversation.type === ChatType.Team || conversation.type === ChatType.Group) {
    const ops = getOperatorParticipants(conversation, operatorData);
    return ops.length > 0 ? !Boolean(ops.find((p) => p?._id && p?.active && p?.userData)?.active) : true;
  }
  const primaryUser = getPrimaryParticipant(conversation);
  if (!primaryUser || !primaryUser._id || !primaryUser?.userData || !primaryUser?.userData?.firstname || !primaryUser?.userData?._id) {
    return false;
  } else {
    return !Boolean(primaryUser?.active);
  }
};

/**
 * This function returns the errand array with previews, actions, placeholders, and recipients set
 * @param {IErrand[]} conversations 
 * @returns IErrand[] the array of Errand objects after data finalization
 */
export const getAdditionalData = (conversations: IErrand[]) => {
    let primaryIndex: number = -1; //primary conversation index
    let errands: IErrand[] = [];

    for (const [index, errand] of conversations.entries()) {
      const {
        _id,
        displayName,
        type,
        status,
        isFlagged,
        createdAt,
        activeContext,
        context,
        position,
        color,
        parentId,
        participants,
        lastMessageData,
        ssAccessToken,
      } = errand;

      let { preview } = errand;

      if (type === ChatType.Conversation && primaryIndex === -1) {
        primaryIndex = index;
      }

      let name = displayName || 'AngelAi';
      //for property listings, the name is [name to display]<>[link]
      if (type === ChatType.Page || type === ChatType.Conditions) {
        if (name.includes('<>')) {
          name = name.split('<>')[0];
          preview = displayName.split('<>')[1];
        }
      }

      errands.push({
        _id: _id,
        action: undefined,
        isDefault: primaryIndex === index,
        //TODO: deprecate name
        name: name,
        displayName: displayName,
        status: status,
        activeContext: activeContext,
        type: type,
        participants: participants,
        lastMessageData: lastMessageData,
        lastUpdated: new Date(),
        placeholder: 'Type here...',
        isFlagged: isFlagged,
        icon: null,
        messages: null,
        preview: preview,
        previewAllowed: true,
        position: position,
        color: color,
        parentId: parentId,
        context: context,
        ssAccessToken: ssAccessToken,
        createdAt,
      });
    }
    return errands;
}

/**
 * This function queries morgan-core for the IParticipant array to be placed into the errand
 * @param {string} chatId The conversation/errand id to retrieve participants for
 * @returns {IParticipant[]} an array of IParticipant objects associated with the errand/chat
 */
export const getParticipantData = async (chatId: string): Promise<IParticipant[]> => {
  if(!chatId) return null;
  // get all participants
  const participants: IParticipant[] = await axiosCall({
    url: `chat/${chatId}/participant`,
  }).catch((error) => {
    console.error(`ErrandUtils: Unable to load chat participants ${error.message}`);
    return;
  });
  return participants;
}

/**
 * This function queries morgan-core for the contexts associated with the supplied chat.
 * @param {string} chatId The chat ID to get contexts for
 * @returns {IContext[]} The array of IContext objects returned
 */
export const getContextData = async (chatId: string): Promise<IContext[]> => {
  if(!chatId) return null;
  // get chat context (e.g. loan number, borrower, asset, liability, etc)
  const context: IContext[] = await axiosCall({
    url: `context?chat=${chatId}`,
  }).catch((error) => {
    console.error(`ErrandUtils: Unable to load chat context ${error.message}`);
    return;
  });
  if (Array.isArray(context) && context.length > 0) {
    return context
  }
}

/**
 * This function queries morgan-core for the authentication details for the user supplied
 * @param {string} userId The user to get authentication information for
 */
export const getAuthenticatedChatData = async (userId: string) => {
  if(!userId) return null;
  // Get whether or not this user has authenticated recently
  const ssAccess = await axiosCall({
    url: `user/${userId}/access/authentication`,
  }).catch((error) => {
    console.error(`ErrandUtils: Unable to load user access details ${error.message}`);
    return;
  });

  // If their access was valid we need to update stuff!
  if (ssAccess?.valid === true) {
    // Update chat and errand data
    return ssAccess.ssAccessToken;
  }
  return null;
}


export const getActionFromId = async (actionId: string) => {
  if(!actionId) return null;
  const action: IUserChatAction = await axiosCall({
    url: `useraction/${actionId}?fields=status,intendedAudience`,
  }).catch(e => {
    return console.error("Error occurred retrieving userAction", e);
  });

  return action;
};

export const getMessageUserAction = async (message: IMessage, isPrivate = false) => {
  try {
    if (!message || message.messageType !== 'Action' || !message.userAction) return null;
    if ([ChatType.Activity, ChatType.Form, ChatType.Page].indexOf(message.action?.chatTypeInitiator) !== -1) return null;
    const isCorrectChat = isPrivate ? message.accessType === AccessType.private : message.accessType !== AccessType.private;
    if (!isCorrectChat) return null;

    // 04/17/2024 We already have message.userAction expanded so use its _id.
    const userAction: IUserChatAction = await axiosCall({ 
      url: `useraction/${message.userAction._id}?fields=status,intendedAudience`
    });

    if (!userAction || !userAction?.action?.description || userAction?.status !== 'in-progress') return null;
    if (userAction?.action?.description === 'TCPA') return null;
    if (userAction?.action?.description === 'Slot Machine' && getUserPlayedToday()) return null;

    return userAction;
  } catch (err) {
    return null;
  }
}

/**
 * This function returns the current participant in an errand.
 * @param errand 
 * @returns 
 */
export const getCurrentParticipant = (errand: IErrand | OperatorErrand, _id: string): IParticipant => {
  if (!errand.participants || !Array.isArray(errand.participants)) return undefined;
  return errand.participants.find((p) => p?.active && p?.userData?._id === _id);
};

/**
 * This function returns the primary participant in an errand.
 * @param errand 
 * @returns 
 */
export const getPrimaryParticipant = (errand: IErrand): IParticipant => {
  if (!errand.participants || !Array.isArray(errand.participants)) return undefined;
  return errand.participants.find((p) => p.active && p.primary && p.userType === "User");
}

/**
 * This function returns all human operator participants in an errand.
 * @param errand 
 * @returns 
 */
export const getOperatorParticipants = (errand: IErrand, operatorData: IUserData | undefined, allowSelf: boolean = false): IParticipant[] => {
  if (!errand.participants || !Array.isArray(errand.participants)) return undefined;
  if (errand.type === 'team') {
    return errand.participants.filter((p) => p.active && p.userType === 'Operator' && (allowSelf || !operatorData ? true : p.userData._id !== operatorData._id) );
  }
  return errand.participants.filter((p) => p.active && p.userType === 'Operator' && !p.ai && (allowSelf || !operatorData ? true : p.userData._id !== operatorData._id) );
}

// get the status of a chat based on the primary user's status
export const getOnlineStatus = (conversation, onlineUsers) => {
  if (conversation.type === ChatType.Team) {
    return getOperatorParticipants(conversation, {_id:localStorage.getItem('operatorId')} as IUserData)?.find((u) => u?.userData?.firstname)?.userData?.status;
  } else if (conversation.type === ChatType.Group) {
    return null; //Hide online indicator when group chat
  } else {
    const primaryUser = getPrimaryParticipant(conversation);
    if (primaryUser && primaryUser?.userData && primaryUser?.userData?._id && primaryUser?.userData?.firstname) {
      // TODO include a more efficient search instead of the linear filter since it checks the entire list of onlineUsers this will get
      // super slow and buggy as the number of users increases.
      let s = onlineUsers?.find((ou) => ou._id === primaryUser?.userData?._id);
      return s?.status || 'offline';
    } else {
      // If no user, get first operator in op list
      const primaryOperator = getOperatorParticipants(conversation, {_id:localStorage.getItem('operatorId')} as IUserData);
      if (Array.isArray(primaryOperator) && primaryOperator.length > 0) {
        return primaryOperator[0]?.userData?.status || 'offline';
      }
    }
  }
  // Default
  return 'offline';
};

/**
 * Determines the new chat insertion position in the conversation list.
 * 
 * Finds the first errand (non-conversation type) in the provided array.
 * Returns the position just before that errand, or 0 if none is found.
 *
 * @param conversations - Array of errands (including chats or conversations).
 * @returns Position to insert a new chat, just before the first errand, or -1 if none found.
 */
export const getNewChatPosition = (conversations: Array<IErrand>): number => {
  const firstErrand = conversations.find((x) => x.type !== ChatType.Conversation);

  const position = firstErrand?.position || 0;

  return position - 1;
}


/**
 * This function returns whether the tpConsent was given
 * @param participant 
 * @returns 
 */
export const getTpConsentGiven = (errand) => {
  const primaryUser = getPrimaryParticipant(errand);
  // if primaryUser undefined OR its userData
  if(ValidatorFunctions.isUndefinedOrNull(primaryUser) || ValidatorFunctions.isUndefinedOrNull(primaryUser?.userData)) {
    return false;
  }
  else {
    return primaryUser.userData.tpConsentGiven;
  }
}