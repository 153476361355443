/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 * 
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 * 
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React, { useMemo, lazy, Suspense } from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphReplySkeleton.module.css';
import { IErrand } from '@interfaces/Conversation';
import ErrorBoundary from "@components/ErrorBoundary";

const LazyMorphReply = lazy(() => import('@components/MorphReply'));

const MorphReplyFallback = () => {
  return (
    <div className={Skeleton.loader + ' ' + Styles.style} />
  );
};
// The render component below lazy loads the corresponding component using the skeleton above
type TRenderMorphReplyProps = {
  errand: IErrand;
};
const MorphReplySkeleton = ({
  errand
}: TRenderMorphReplyProps) => {
  const renderMorphReply = useMemo(() => {
    // guard statements required for handleClickSentiment to function properly
    if (!errand || !errand._id) return false;
    return true;
  }, [errand?._id]);

  const MorphReplyProps = {
    errand,
  };

  return renderMorphReply ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphReplySkeleton.tsx`}>
      <Suspense fallback={<MorphReplyFallback />}>
        <LazyMorphReply {...MorphReplyProps} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <></>
  );
};
export default MorphReplySkeleton;
