import React, { useCallback, useMemo } from 'react';

import ConversationBody from '@components/ConversationBody';
import { useErrandContext } from '@contexts/ErrandContext';
import Styles from '@styles/MorphPrivateChat.module.css';
import type { TConversationBodyProps } from '@components/ConversationBody';
import { useRootContext } from '@contexts/RootContext';
import { MorphType } from '@common/MorphType';
import TypingIndicator from '@components/TypingIndicator';
import { useUserContext } from '@contexts/user';
import { ResetFooterUserAction } from '@common/common';

const Shush = process.env.REACT_APP_MORGAN_CDN + '/Images/Shush.png';
const Bell = process.env.REACT_APP_MORGAN_CDN + '/Images/Bell.png';

const MorphPrivateChat = ({
  dispFilterMode,
  editMessageId,
  errand,
  isTyping,
  operatorData,
  setEditMessageId,
  setIsTyping,
  setPreviewUrl,
  setValue,
  showSentiment,
}: TConversationBodyProps) => {
  const { setErrands } = useRootContext();
  const { setIsMorphedFooterCloseButtonOnLeft, setMorphType, errandId } = useErrandContext();
  const { isOperator } = useUserContext();

  const errandRecipients = useMemo(() => errand?.recipients || [], [errand?.recipients]);
  const errandParticipants = useMemo(() => errand?.participants || [], [errand?.participants]);

  const title = useMemo(() => {
    const participants = errandParticipants.filter(
      (p) => p?.active && errandRecipients.indexOf(p?.userData?._id) !== -1
    );

    const names = participants
      .map((p) => {
        if (!p || !p.userData || !p.userData.firstname) return '';
        if (isOperator) {
          return `${p.userData.firstname} ${p.userData.lastname || ''}`.trim();
        }
        if (p.userType === 'Operator') return '';
        return `${p.userData.firstname} ${p.userData.lastname || ''}`.trim();
      })
      .filter((v, i, a) => v && a.indexOf(v) === i);
    console.error({ names, participants });
    if (names.length === 1) {
      names.unshift('AngelAi');
    }

    return names.join(' / ');
  }, [errandRecipients, errandParticipants, isOperator]);

  const handleClose = useCallback(() => {
    setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errandId);

      if (chatObj) {
        ResetFooterUserAction(chatObj);

        chatObj.recipients = [];
      }

      // combine all re-render triggers
      setIsMorphedFooterCloseButtonOnLeft(false);
      setMorphType(MorphType.None);
      return [...prev];
    });
  }, [setErrands, setIsMorphedFooterCloseButtonOnLeft, setMorphType, errandId]);

  return (
    <>
      <section className={Styles.wrapper}>
        <button className={Styles.button} onClick={handleClose}></button>
        <div aria-hidden="true" className={Styles.header}>
          <img src={Shush} alt="Angel with index finger on lips asking you to keep her secrets" />
          <div>
            <img src={Bell} alt="notification bell ringing" />
          </div>
        </div>
        <p className={Styles.title}>{title}</p>
        <div className={Styles.content}>
          <ConversationBody
            dispFilterMode={dispFilterMode}
            editMessageId={editMessageId}
            errand={errand}
            isPrivate={true}
            operatorData={operatorData}
            setEditMessageId={setEditMessageId}
            setPreviewUrl={setPreviewUrl}
            setValue={setValue}
            showBouncyRope={false}
            showSentiment={showSentiment}
          />
        </div>
        <TypingIndicator
          errand={errand}
          isTyping={isTyping}
          setIsTyping={setIsTyping}
          operatorData={operatorData}
          isPrivate={true}
        />
      </section>
    </>
  );
};

export default MorphPrivateChat;
