import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';
import Styles from '../Styles/MorphPhotoMenu.module.css';
import Snackbar from '@mui/material/Snackbar';
import handleFile from '@common/handleFile';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import HomeIcon from '@mui/icons-material/Home';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { MorphType } from '@common/MorphType';
import { IErrand } from '@interfaces/Conversation';
const DocumentPreview = process.env.REACT_APP_MORGAN_CDN + '/Images/DOC.png';

type MorphPhotoMenuProps = {
  setText: (text: string) => void;
  setShowPermissionReminder: (value: boolean) => void;
  setIconToShow: (value: string) => void;
  setSelectedFiles: (value: any) => void;
  selectedFiles: File[];
  errand: IErrand;
};

const MorphPhotoMenu: React.FC<React.PropsWithChildren<MorphPhotoMenuProps>> = (props) => {
  const { setText, selectedFiles, setSelectedFiles } = props;

  const {
    errand,
    boundaryRef,
    photoLimit,
    mainPhoto,
    morphType,
    photoSelectorIndex,
    setPhotoSelectorIndex,
    setMainPhoto,
  } = useContext<IErrandContext>(ErrandContext);

  const { t } = useTranslation();
  const [isWarningActive, setIsWarningActive] = useState(false);
  const swiperRef = useRef(null);
  const middleSlideIndex = Math.floor((photoLimit || 5) / 2);

  const setMorphText = useCallback(() => {
    if (morphType === MorphType.PhotoMain) {
      if (mainPhoto !== null && mainPhoto === photoSelectorIndex) {
        setText(t('mainPhoto'));
      } else {
        setText(t('propertyPictures'));
      }
    } else {
      setText(t('tPictures'));
    }
  }, [mainPhoto, morphType, photoSelectorIndex, setText, t]);

  const handleFiles = useCallback(
    (e, index: number) => {
      let files = handleFile(e, errand?.action?.action?.fieldName === 'influencerPhotograph');

      if (typeof files === 'string') {
        setIsWarningActive(t(files));
        return;
      }

      // When file limit is 1, only use the first file from the selection
      if (photoLimit === 1) {
        const file = files[0];
        setSelectedFiles([file]); // Replace the existing array with a new array containing just the new file
      } else {
        let newFiles = [...selectedFiles];

        (files as File[]).forEach((file, fileIndex) => {
          const fileIndexToAdd = index === undefined ? newFiles.length : index + fileIndex;
          if (fileIndex === 0 || index === undefined) {
            newFiles[fileIndexToAdd] = file;
          } else {
            newFiles.splice(fileIndexToAdd, 0, file);
          }
        });

        setSelectedFiles(newFiles.filter(Boolean)); // Remove any null or undefined values
      }
    },
    [errand?.action?.action?.fieldName, photoLimit, selectedFiles, setSelectedFiles, t]
  );

  const handleSlideChange = useCallback(() => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;

      if (!swiperInstance) return;

      //realIndex signifies that this is a valid photo.
      if (selectedFiles[swiperInstance.realIndex]) {
        setPhotoSelectorIndex(swiperInstance.realIndex);
        if (swiperInstance.realIndex === mainPhoto) {
          setText(t('mainPhoto'));
        } else if (morphType === MorphType.PhotoPlain) {
          setText(t('tPictures'));
        } else {
          setText(t('propertyPictures'));
        }
      } else {
        setPhotoSelectorIndex(null);
        if (morphType === MorphType.PhotoPlain) {
          setText(t('tPictures'));
        } else {
          setText(t('propertyPictures'));
        }
      }

      // Reset all slides to normal scale
      swiperInstance.slides.forEach((slide) => {
        if (slide && slide.style) slide.style.transform = 'scale(1)';
      });

      // Scale the centered slide
      const centeredSlide = swiperInstance.slides[swiperInstance.realIndex];
      if (centeredSlide && centeredSlide.style) {
        centeredSlide.style.transform = 'scale(1.2)';
        centeredSlide.style.marginTop = '10px';
      }
    }
  }, [mainPhoto, morphType, selectedFiles, setPhotoSelectorIndex, setText, t]);

  const renderPreview = useCallback(
    (index: number) => {
      const file = selectedFiles[index];
      if (!file) return null;

      return (
        <button className={Styles.btn}>
          <div className={Styles.btnBorder}>
            <input
              id={`fileInput_${index}`}
              type="file"
              accept="image/*"
              multiple
              style={{ display: 'none' }}
              onChange={(e) => handleFiles(e, index)}
            />
            <img
              style={{ marginTop: '0px', maxWidth: '50px', height: '50px', borderRadius: '5px' }}
              className={Styles.imgPreview}
              src={
                typeof file.type === 'string' && file.type.split('/')[0] === 'image'
                  ? URL.createObjectURL(file)
                  : DocumentPreview
              }
              alt={file.name}
            />
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                //remove the main photo if it was the one removed
                if (mainPhoto === index) {
                  setMainPhoto(null);
                }
                setSelectedFiles((prev) => {
                  const newFiles = [...prev];
                  newFiles[index] = null;
                  return newFiles;
                });
                handleSlideChange();
                setMorphText();
              }}
              className={Styles.removeOverlay}
            >
              <p className={Styles.removeText}>{t('createGroupRemove')}</p>
            </div>
          </div>
        </button>
      );
    },
    [handleFiles, handleSlideChange, mainPhoto, selectedFiles, setMainPhoto, setMorphText, setSelectedFiles, t]
  );

  useEffect(() => {
    if (photoLimit === 1 && selectedFiles[0]) {
      // Logic to handle UI update for single file scenario
      // For example, force Swiper to update or rerender the component to reflect the new preview
      swiperRef.current?.swiper.update(); // Update Swiper
      swiperRef.current?.swiper.slideTo(0); // Ensure Swiper is focused on the first slide
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  useEffect(() => {
    setMorphText();
    setSelectedFiles([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [morphType]);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      setTimeout(() => {
        const newIndex = selectedFiles.findIndex((file) => file !== null); // Find the first non-null file index
        swiperRef.current.swiper?.slideTo(newIndex);
        handleSlideChange();
        setMorphText();
      }, 0);
    }

    if (selectedFiles?.length === 1) {
      setMainPhoto(0);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  useEffect(() => {
    handleSlideChange();
    setMorphText();
    //these handle the cases in which the main photo was removed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPhoto, selectedFiles]);

  useEffect(() => {
    // For drag-and-drop functionality
    function preventDefault(e) {
      e.preventDefault();
    }
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      boundaryRef.current?.addEventListener('drop', (e) => handleFiles(e, swiperInstance.realIndex));
    }
    window.addEventListener('drop', preventDefault);
    window.addEventListener('dragover', preventDefault);

    // For handling slide changes and setting text
    handleSlideChange();
    setMorphText();

    // Cleanup function for the event listeners
    return () => {
      window.removeEventListener('drop', preventDefault);
      window.removeEventListener('dragover', preventDefault);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Snackbar
        open={isWarningActive ? true : false}
        autoHideDuration={6000}
        onClose={() => setIsWarningActive(null)}
        message={isWarningActive}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      <div className={Styles.wrapper}>
        <Swiper
          ref={swiperRef}
          slidesPerView={3}
          centeredSlides={true}
          modules={[FreeMode, Navigation]}
          initialSlide={middleSlideIndex}
          onSlideChange={() => handleSlideChange()}
          slideToClickedSlide={true}
          freeMode={{
            enabled: true,
            sticky: true,
            momentumBounce: false,
            momentumRatio: 0.25,
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          style={{
            display: 'flex',
            minWidth: '150px',
            position: 'absolute',
            width: '100%',
            height: '100%',
            marginTop: '15px',
            marginLeft: '7px',
            zIndex: '5',

            overflowY: 'hidden',
            scrollbarWidth: 'none', //hide scrollbar for Firefox
            msOverflowStyle: 'none', // hide scrollbar for IE and Edge
          }}
        >
          {Array.from({ length: photoLimit || 5 }, (_, i) => i + 1).map((index) => (
            <SwiperSlide key={index} onClick={() => document.getElementById(`fileInput_${index}`)?.click()}>
              {renderPreview(index - 1) || (
                <div className={Styles.btn}>
                  <div className={Styles.btnBorder}>
                    {morphType === MorphType.PhotoMain ? (
                      <HomeIcon sx={{ color: 'orange' }} />
                    ) : (
                      <CameraAltIcon sx={{ cursor: 'pointer', color: 'orange' }} />
                    )}
                    <input
                      id={`fileInput_${index}`}
                      type="file"
                      accept="image/*"
                      {...(photoLimit !== 1 ? { multiple: true } : {})}
                      style={{ display: 'none' }}
                      onChange={(e) => handleFiles(e, index)}
                    />
                    <p className={Styles.label}>{photoLimit === 1 ? t('addPhoto') : t('addPhotos')}</p>
                  </div>
                </div>
              )}
            </SwiperSlide>
          ))}
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </Swiper>
      </div>

      <div className={Styles.bottomBorder}></div>
    </>
  );
};

export default MorphPhotoMenu;
