/**
 * @file This module contains the definition of the `LanguagePicker` component.
 * @author Kristoffer A. Wright <kristoffer.wright@swmc.com>
 */

import React, { PropsWithChildren, useEffect, useState, memo } from 'react';

import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { US as FlagIconUS } from 'country-flag-icons/react/3x2';
import { setPreselectedLanguage } from '@storage/userStorage';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertLink from '@mui/icons-material/InsertLink';
import { Keyboard } from '@assets/Icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { FlagStyles, LanguagePickerButton } from '@styles/LanguagePickerButtonStyles';
import { map, flagIcons } from '@common/LocationInformation';
import { useSocketContext } from '../Contexts/socket';
import { useUserContext } from '@contexts/user';

const LanguagePicker: React.FC<PropsWithChildren<any>> = memo((props) => {

  ///////////////////////////// C O N S T A N T S //////////////////////////////

  const { t, i18n } = useTranslation();
  
  const dispModes = [
    "FLAGS",
    "DOCUMENTS",
    "AUDIO",
    "ACTIONS",
    "RESPONSES",
    "NOTIFICATIONS",
    "URLS"
  ];

  ///////////////////////////////// S T A T E //////////////////////////////////

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [dispMode, setDispMode] = useState(0);
  const [isTyping, setIsTyping] = useState(false);

  const { _id } = useUserContext();
  const { messagesSocket, isMessagesConnected } = useSocketContext();

  //////////////////////// E V E N T    H A N D L E R S ////////////////////////
  /**
   * Invoked when the language picker menu is closed.
   */
  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  /**
   * Invoked when the language is changed.
   */
  const handleLangChange = (event) => {
    setMenuAnchor(null);
    i18n.changeLanguage(map[event.currentTarget.dataset.itemIndex].lang);
    setPreselectedLanguage(map[event.currentTarget.dataset.itemIndex].lang);
  };

  /**
   * Invoked when the language picker menu is opened.
   */
  const handleOpenMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  /**
   * Invoked when the up filter arrow is clicked
   */
  const handleIncDispMode = (event) => {
    if (dispMode >= (dispModes.length - 1)) {
      setDispMode(0);
    } else {
      setDispMode(dispMode + 1);
    }
  }

  /**
   * Invoked when the down filter arrow is clicked
   */
  const handleDecDispMode = (event) => {
    if (dispMode <= 0) {
      setDispMode(dispModes.length - 1);
    } else {
      setDispMode(dispMode - 1);
    }
  }

  /////////////////////////// U S E - E F F E C T S ////////////////////////////

  useEffect(() => {
    switch (dispModes[dispMode]) {
      case 'FLAGS':
        props.setDispFilterMode('None');
        return;
      case 'DOCUMENTS':
        props.setDispFilterMode('Document');
        return;
      case 'AUDIO':
        props.setDispFilterMode('Audio');
        return;
      case 'ACTIONS':
        props.setDispFilterMode('Action');
        return;
      case 'RESPONSES':
        props.setDispFilterMode('Text');
        return;
      case 'NOTIFICATIONS':
        props.setDispFilterMode('Notification');
        return;
      case 'URLS':
        props.setDispFilterMode('Url');
        return;
      default:
        props.setDispFilterMode('None');
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispMode]);

  useEffect(() => {
    if (!isMessagesConnected) return;
    
    const chatEventHandler = (payload) => {
      const data = payload?.data;
      const type = data?.type;
      const userId = data?.userId;
      const message = data?.message;
      const newTypingState = message === 'true' ? true : false;

      if (type !== 'typing' || userId !== _id) return;

      setIsTyping(newTypingState);
    };

    messagesSocket.current?.on('chat-event-emitted', chatEventHandler);
    return () => {
      messagesSocket.current?.off('chat-event-emitted', chatEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected]);

  return (
    <LanguagePickerButton>
      {/* <button type="button" className="LanguagePickerButtonArrow" onClick={handleIncDispMode}>▲</button> */}
      <div>
        <Tooltip
          title={
            dispModes[dispMode] === 'FLAGS' ? t('langPickerTooltip') : 
            dispModes[dispMode] === 'DOCUMENTS' ? t('langPickerFilterDocumentsTooltip') : 
            dispModes[dispMode] === 'AUDIO' ? t('langPickerFilterAudioTooltip') : 
            dispModes[dispMode] === 'ACTIONS' ? t('langPickerFilterAudioTooltip') : 
            dispModes[dispMode] === 'RESPONSES' ? t('langPickerFilterResponsesTooltip') : 
            dispModes[dispMode] === 'NOTIFICATIONS' ? t('langPickerFilterNotificationsTooltip') : 
            dispModes[dispMode] === 'URLS' ? t('langPickerFilterUrlsTooltip') : ''
          }
          placement='top-start'
          enterDelay={1000}
          enterNextDelay={1000}>
          {isTyping ? (
            <Keyboard />
          ) : (
            <>
              {dispModes[dispMode] === 'FLAGS' ? (
                <>
                  {flagIcons[i18n.language.split('-')[0]] ? (
                React.cloneElement(flagIcons[i18n.language.split('-')[0]], {
                  style: { ...FlagStyles },
                  onClick: handleOpenMenu,
                })
              ) : (
                <FlagIconUS // Default flag if the language is not found in the dictionary
                  style={{ ...FlagStyles }}
                  onClick={handleOpenMenu}
                />
              )}
                </>
              ) : dispModes[dispMode] === 'DOCUMENTS' ? (
                <InsertDriveFileIcon />
              ) : dispModes[dispMode] === 'AUDIO' ? (
                <SpatialAudioOffIcon />
              ) : dispModes[dispMode] === 'ACTIONS' ? (
                <AssignmentIcon />
              ) : dispModes[dispMode] === 'RESPONSES' ? (
                <AssignmentTurnedInIcon />
              ) : dispModes[dispMode] === 'NOTIFICATIONS' ? (
                <NotificationsIcon />
              ) : dispModes[dispMode] === 'URLS' ? (
                <InsertLink />
              ) : (
                <></>
              )}
            </>
          )}
        </Tooltip>
      </div>
      {/* <button type="button" className="LanguagePickerButtonArrow" onClick={handleDecDispMode}>▼</button> */}

      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          transform: 'translate(-5px, -15px);',
          zIndex: '1550',
        }}
        keepMounted>
        {
          map.map((item, i) => (
            <MenuItem 
              key={item.lang}
              value={item.lang}
              onClick={handleLangChange}
              data-item-index={i}
            >
            {item.nativeName}
          </MenuItem>
        ))}
      </Menu>
    </LanguagePickerButton>
  );
});

export default LanguagePicker;
