/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 * 
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 * 
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

 import React, { useMemo, lazy, Suspense } from 'react';
 import Skeleton from '@styles/Skeletons/Skeletons.module.css';
 // Uses MorphReply styles because it is simillar component to this one
 import Styles from '@styles/Skeletons/MorphCreditRepairAccountType.module.css';
 import { IErrand } from '@interfaces/Conversation';
 import ErrorBoundary from "@components/ErrorBoundary";

 const LazyMorphUserCreditRepairAccountType = lazy(() => import('@components/MorphCreditRepairAccountType/MorphCreditRepairAccountType'));

 const MorphUserCreditRepairAccountTypeFallback = () => {
   return (
     <div className={Skeleton.loader + ' ' + Styles.style} />
   );
 };
 
 type TRenderMorphReplyProps = {
   errand: IErrand;
   cancelAction: (key: any, clear: boolean) => void;
 };
 
 const MorphUserCreditRepairAccountType = ({
   errand, cancelAction
 }: TRenderMorphReplyProps) => {
   const renderMorphUserCreditRepairAccountType = useMemo(() => {
     if (!errand || !errand._id) return false;
     return true;
   }, [errand?._id, errand?.action]);
 
   const MorphUserCreditRepairAccountTypeProps = {
     errand,
     cancelAction
   };
 
   return renderMorphUserCreditRepairAccountType ? (
     <ErrorBoundary debug={`./src/Components/Skeletons/MorphCreditRepairAccountTypeSkeleton.tsx`}>
       <Suspense fallback={<MorphUserCreditRepairAccountTypeFallback />}>
         <LazyMorphUserCreditRepairAccountType {...MorphUserCreditRepairAccountTypeProps} />
       </Suspense>
     </ErrorBoundary>
   ) : (
     <></>
   );
 };
 
 export default MorphUserCreditRepairAccountType;