import React from 'react';
import Styles from '@styles/MessageContent/BlockchainMessageMessageContent.module.css';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import MessageTextStyle from '@styles/MessageTextStyle';
import { useUserContext } from '@contexts/user';

const BlockchainMessageMessageContent = ({ message }) => {
  const sentByUser = useUserContext()?._id === message?.sender?._id;
  const lockContainerWidth = 30;

  return (
    <ChatBubbleStyle className={Styles.chatBubble}
                     sx={{ minWidth: 1, backgroundColor: sentByUser ? '#F9CCB3' : '#01011A',
                           padding: `6px ${sentByUser ? 7 : (lockContainerWidth + 7)}px 8px ${sentByUser ? (lockContainerWidth + 9) : 9}px` }}>
      <MessageTextStyle>
        <p style={{ color: !sentByUser && 'white' }}>
          {message?.message}
        </p>
      </MessageTextStyle>
      <div className={Styles.lockContainer} style={{ width: lockContainerWidth, left: sentByUser && 0, right: !sentByUser && 0 }}>
        <img src={`${process.env.REACT_APP_MORGAN_CDN}/Images/Blockchain/WalletLockBlue.png`} className={Styles.lockIcon} alt='Lock Icon' />
      </div>
    </ChatBubbleStyle>
  );
};

export default BlockchainMessageMessageContent;
