/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 * 
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 * 
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React, { Dispatch, SetStateAction, useMemo, lazy, Suspense } from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphMessageOptionSkeleton.module.css';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { MorphType } from '@common/MorphType';
import { isNotOtpNorPassword } from '@common/StringUtils';
import ErrorBoundary from "@components/ErrorBoundary";

const LazyMorphMessageOptionConvert = lazy(() => import('@components/MorphMessageOptions/MorphMessageOptionConvert'));
const LazyMorphMessageOptionCopy = lazy(() => import('@components/MorphMessageOptions/MorphMessageOptionCopy'));
const LazyMorphMessageOptionDelete = lazy(() => import('@components/MorphMessageOptions/MorphMessageOptionDelete'));
const LazyMorphMessageOptionEdit = lazy(() => import('@components/MorphMessageOptions/MorphMessageOptionEdit'));
const LazyMorphMessageOptionJoin = lazy(() => import('@components/MorphMessageOptions/MorphMessageOptionJoin'));
const LazyMorphMessageOptionPrivateMode = lazy(() => import('@components/MorphMessageOptions/MorphMessageOptionPrivateMode'));
const LazyMorphMessageOptionRemoveUser = lazy(() => import('@components/MorphMessageOptions/MorphMessageOptionRemoveUser'));
const LazyMorphMessageOptionReply = lazy(() => import('@components/MorphMessageOptions/MorphMessageOptionReply'));
const LazyMorphMessageOptionSentiment = lazy(() => import('@components/MorphMessageOptions/MorphMessageOptionSentiment'));
const LazyMorphMessageOptionSetInternal = lazy(() => import('@components/MorphMessageOptions/MorphMessageOptionSetInternal'));

const MorphMessageOptionSkeleton = () => {
  return (
    <div className={Skeleton.loader + ' ' + Styles.style} />
  );
};
// The render component below lazy loads the corresponding component using the skeleton above
type TMorphMessageOptionSkeletonProps = {
  errand: IErrand;
  handleClose: (morphType?: MorphType) => void;
  message: IMessage;
  setEditMessageId: Dispatch<SetStateAction<string>>;
  setValue: Dispatch<SetStateAction<string>>;
};
const MorphMessageOptionConvertSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderConvert = useMemo(() => {
    try {
      // guard statements required for handleClickMenu to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business logic for allowing users or operators to Convert messages
      if (!message.operatorView) return false;
      if (message.senderType !== 'User') return false;
      if (message.messageType !== 'Text' && message.messageType !== 'Document' && message.messageType !== 'Audio') return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderCopy.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.operatorView, message?.senderType, handleClose]);
  const MorphMessageOptionConvertProps = {
    errand,
    handleClose,
    message,
  };

  return renderConvert ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionConvert`}>
      <Suspense fallback={<MorphMessageOptionSkeleton />}>
        <LazyMorphMessageOptionConvert {...MorphMessageOptionConvertProps} />
      </Suspense>
    </ErrorBoundary>
  ) : <></>
};
const MorphMessageOptionCopySkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderCopy = useMemo(() => {
    try {
      // guard statements required for handleClickCopy to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business requirements for editing messages
      if (!message.visible && !message.operatorView) return false;
      if (message.messageType === 'Field') {
        if (!isNotOtpNorPassword(message)) return false;
      } else {
        // opt in for editing
        const copyableMessageTypes = ['Action', 'Audio', 'Text', 'Field', 'helptip', 'Video', 'Url'];
        if (copyableMessageTypes.indexOf(message.messageType) === -1) return false;
      }
  
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderCopy.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.messageType, message?.visible, message?.operatorView, handleClose]);
  const MorphMessageOptionCopyProps = {
    errand,
    handleClose,
    message,
  };

  return renderCopy ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionCopy`}>
      <Suspense fallback={<MorphMessageOptionSkeleton />}>
        <LazyMorphMessageOptionCopy {...MorphMessageOptionCopyProps} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <></>
  );
};
const MorphMessageOptionDeleteSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderDelete = useMemo(() => {
    try {
      // guard statements required for handleClickDelete to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business logic for allowing users or operators to delete messages
      if (message.messageType === 'Field') return false;
      if (!message.visible && !message.operatorView) return false;
      if (!message.sentByCurrentUser) return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderDelete.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.visible, message?.operatorView, message?.alignByCurrentUser, handleClose]);
  const MorphMessageOptionDeleteProps = {
    errand,
    handleClose,
    message,
  };

  return renderDelete ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionDelete`}>
      <Suspense fallback={<MorphMessageOptionSkeleton />}>
        <LazyMorphMessageOptionDelete {...MorphMessageOptionDeleteProps} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <></>
  );
};
const MorphMessageOptionEditSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderEdit = useMemo(() => {
    try {
      // guard statements required for handleClickEdit to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message?._id) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business requirements for editing messages
      if (message.operatorView) {
        if (!message.alignByCurrentUser && message.messageType !== 'Field') return false;
        if (message.messageType === 'Field' && message.action?.fieldName?.toLowerCase()?.indexOf('password') !== -1) return false;
      } else {
        if (!message.sentByCurrentUser) return false;
      }
  
      // opt in for editing
      const editableMessageTypes = ['Text', 'Field', 'Video', 'Url'];
      if (editableMessageTypes.indexOf(message.messageType) === -1) return false;
  
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderEdit.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.messageType, message?.visible, message?.operatorView, handleClose]);
  const MorphMessageOptionEditProps = {
    errand,
    handleClose,
    message,
    setEditMessageId,
    setValue,
  };

  return renderEdit ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionEdit`}>
      <Suspense fallback={<MorphMessageOptionSkeleton />}>
        <LazyMorphMessageOptionEdit {...MorphMessageOptionEditProps} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <></>
  );
};
const MorphMessageOptionJoinSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderJoin = useMemo(() => {
    try {
      // guard statements required for handleClickMenu to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message?._id || !message?.messageType || !message?.message || !message?.userId) return false;
  
      // business logic for allowing users or operators to join chat invites
      if (message?.messageType !== 'Text') return false;
      const chatId = (message?.message?.split('data-chat-id="')?.[1] || '')?.split('"')[0];
      if (!chatId) return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderJoin.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.messageType, message?.message]);
  const MorphMessageOptionJoinProps = {
    errand,
    handleClose,
    message,
  };

  return renderJoin ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionJoin`}>
      <Suspense fallback={<MorphMessageOptionSkeleton />}>
        <LazyMorphMessageOptionJoin {...MorphMessageOptionJoinProps} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <></>
  );
};
const MorphMessageOptionPrivateModeSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {

  const errandType = useMemo(() => errand?.type, [errand?.type]);

  const messageSender = useMemo(() => message?.sender, [message?.sender]);
  const messageUserId = useMemo(() => message?.userId, [message?.userId]);

  const messageSenderId = useMemo(() => messageSender?._id, [messageSender?._id]);

  const renderPrivateMode: boolean = useMemo(() => {
    // business logic for allowing private messages
    if (errandType === 'team') return false;

    // own message should not have private message option
    if (messageSenderId === messageUserId) return false;

    return true;
  }, [errandType, messageSenderId, messageUserId]);
  const MorphMessageOptionPrivateModeProps = {
    errand,
    handleClose,
    message,
  };

  return renderPrivateMode ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionPrivateMode`}>
      <Suspense fallback={<MorphMessageOptionSkeleton />}>
        <LazyMorphMessageOptionPrivateMode {...MorphMessageOptionPrivateModeProps} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <></>
  );
};
const MorphMessageOptionRemoveUserSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderRemoveUser = useMemo(() => {
    try {
      // guard statements required for handleClickRemoveUser to function properly
      if (!errand || !errand._id || !Array.isArray(errand.participants)) return false;
      if (!message || !message._id || !message.sender) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business logic for allowing users to remove others
      if (!message.operatorView) return false;
      if (message.senderType !== 'User' && message.sender.accessLevel !== 'Level 0') return false;
      const senderIsPrimary = errand.participants.find((x) => x?.active && x.userData?._id === message?.sender?._id)?.primary;
      if (senderIsPrimary) return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderRemoveUser.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, errand?.participants, message?.sender?._id, message?.sender?.accessLevel, handleClose]);
  const MorphMessageOptionRemoveUserProps = {
    errand,
    handleClose,
    message,
  };

  return renderRemoveUser ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionRemoveUser`}>
      <Suspense fallback={<MorphMessageOptionSkeleton />}>
        <LazyMorphMessageOptionRemoveUser {...MorphMessageOptionRemoveUserProps} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <></>
  );
};
const MorphMessageOptionReplySkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderReply = useMemo(() => {
    try {
      // guard statements required for handleClickReply to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id || !message.userId) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business logic for allowing users to remove others
      const replyableMessageTypes = ['Action', 'Audio', 'Field', 'Text', 'Greeting', 'Url'];
      if (replyableMessageTypes.indexOf(message.messageType) === -1) return false;
      if (message.messageType === 'Action') {
        // opt out of replies
        const unreplyableActionDescriptions = ['TCPA', 'Slot Machine'];
        if (unreplyableActionDescriptions.indexOf(message.action?.description) !== -1) return false;
        if (message.action?.description?.toLowerCase()?.indexOf('password') !== -1) return false;
      }
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderReply.catch`, error);
      return false;
    }
  }, [errand._id, message, handleClose]);
  const MorphMessageOptionReplyProps = {
    errand,
    handleClose,
    message,
  };

  return renderReply ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionReply`}>
      <Suspense fallback={<MorphMessageOptionSkeleton />}>
        <LazyMorphMessageOptionReply {...MorphMessageOptionReplyProps} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <></>
  );
};
const MorphMessageOptionSentimentSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderSentiment = useMemo(() => {
    try {
      if (!message || !message.operatorView) return false; // on user side, don't render sentiment, or if the message renders on the left
      // guard statements required for handleClickSentiment to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id || !message.userId) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business logic for sentiment
      if (!message.operatorView && !message.alignByCurrentUser) return false;
      const messagesWithSentiment = ['Text', 'Field', 'Document', 'Audio', 'Url'];
      if (messagesWithSentiment.indexOf(message.messageType) === -1) return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderSentiment.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.messageType, message?.userId, handleClose]);
  const MorphMessageOptionSentimentProps = {
    errand,
    handleClose,
    message,
  };

  return renderSentiment ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionSentiment`}>
      <Suspense fallback={<MorphMessageOptionSkeleton />}>
        <LazyMorphMessageOptionSentiment {...MorphMessageOptionSentimentProps} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <></>
  );
};
const MorphMessageOptionSetInternalSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderSetInternal = useMemo(() => {
    try {
      if (!message.operatorView) return false; // on user side, don't render whether the msg interal
      // guard statements required for handleClickSentiment to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id) return false;
      if (typeof handleClose !== 'function') return false;
      if (!message.operatorView) return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderSetInternal.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id]);
  const MorphMessageOptionSetInternalProps = {
    errand,
    handleClose,
    message,
  };

  return renderSetInternal ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionsSetInternal`}>
      <Suspense fallback={<MorphMessageOptionSkeleton />}>
        <LazyMorphMessageOptionSetInternal {...MorphMessageOptionSetInternalProps} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <></>
  );
};
export {
  MorphMessageOptionConvertSkeleton,
  MorphMessageOptionCopySkeleton,
  MorphMessageOptionDeleteSkeleton,
  MorphMessageOptionEditSkeleton,
  MorphMessageOptionJoinSkeleton,
  MorphMessageOptionPrivateModeSkeleton,
  MorphMessageOptionRemoveUserSkeleton,
  MorphMessageOptionReplySkeleton,
  MorphMessageOptionSentimentSkeleton,
  MorphMessageOptionSetInternalSkeleton
};
