/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 * 
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 * 
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

 import React, { useMemo, lazy, Suspense } from 'react';
 import Skeleton from '@styles/Skeletons/Skeletons.module.css';
 // Uses MorphReply styles because it is simillar component to this one
 import Styles from '@styles/Skeletons/MorphUserPromptsMenuSkeleton.module.css';
 import { IErrand } from '@interfaces/Conversation';
 import { useErrandContext } from '@contexts/ErrandContext';
 import ErrorBoundary from "@components/ErrorBoundary";

const LazyMorphUserPromptsMenuSkeleton = lazy(() => import('@components/MorphUserPromptsMenu/MorphUserPromptsMenu'));

 const MorphUserPromptsMenuSkeletonFallback = () => {
   return (
     <div className={Skeleton.loader + ' ' + Styles.style} />
   );
 };
 
 type TRenderMorphReplyProps = {
   errand: IErrand;
   cancelAction: (key: any, clear: boolean) => void;
 };
 
 const MorphUserPromptsMenuSkeleton = ({
   errand, cancelAction
 }: TRenderMorphReplyProps) => {
   const renderMorphUserPromptsMenuSkeleton = useMemo(() => {
     if (!errand || !errand._id) return false;
     return true;
   }, [errand?._id]);
   const errandContext = useErrandContext();
 
   const MorphUserPromptsMenuSkeletonProps = {
     errand,
     cancelAction
   };
 
   return renderMorphUserPromptsMenuSkeleton ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphUserPromptsMenuSkeleton.tsx`}>
      <Suspense fallback={<MorphUserPromptsMenuSkeletonFallback />}>
        <LazyMorphUserPromptsMenuSkeleton
          ref={errandContext.morphUserPromptsMenuRef}
          {...MorphUserPromptsMenuSkeletonProps}
        />
      </Suspense>
     </ErrorBoundary>
   ) : (
     <></>
   );
 };
 export default MorphUserPromptsMenuSkeleton;