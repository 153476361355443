import { Box } from '@mui/system';
import { IconButton, Button } from '@mui/material';
import useDebounce from '@common/hooks/useDebounce';
import React, { useState, useContext, useEffect } from 'react';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { useTranslation } from 'react-i18next';
import Styles from '@styles/MorphFormOpen.module.css'
import { useFooterContext } from '@contexts/FooterContext';
import { getUserConsent } from '@storage/userStorage';

const MorphCreditRepairWelcome = ({handleSubmit}) => {
  const { t } = useTranslation();
  const footerContext = useFooterContext();

  useEffect(() => {
    setTimeout(() => {
      footerContext.sendButtonRef.current?.update(t('helpMeRepairMyCredit'));
    }, 100)
    footerContext.sendButtonRef.current?.update(t('helpMeRepairMyCredit'));
    footerContext.chatInputFieldRef.current?.update(t('helpMeRepairMyCredit'))
  }, [])

  const submitMessage = (e) => {
    if (getUserConsent() !== 'true') return;
    handleSubmit(e);
  }

  return (
    <Button className={[Styles.button,
    ...(getUserConsent() !== 'true' ? [Styles.disabled] : [])
    ].join(' ')} variant="contained" onClick={submitMessage}>
      {t('clickToRepairCredit')}
    </Button>
  );
};

export { MorphCreditRepairWelcome };
