/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 * 
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 * 
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

 import React, { useMemo, lazy, Suspense } from 'react';
 import Skeleton from '@styles/Skeletons/Skeletons.module.css';
 // Uses MorphReply styles because it is simillar component to this one
 import Styles from '@styles/Skeletons/MorphVideoListMenuSkeleton.module.css';
 import { IErrand } from '@interfaces/Conversation';
 import ErrorBoundary from "@components/ErrorBoundary";

 const LazyMorphVideoListMenuSkeleton = lazy(() => import('@components/MorphVideoListMenu'));

 const MorphVideoListMenuSkeletonFallback = () => {
   return (
     <div className={Skeleton.loader + ' ' + Styles.style} />
   );
 };
 type TRenderMorphReplyProps = {
   errand: IErrand;
   cancelAction: (key: any, clear: boolean) => void;
 };
 const MorphVideoListMenuSkeleton = ({
   errand, cancelAction
 }: TRenderMorphReplyProps) => {
   const renderMorphVideoListMenuSkeleton = useMemo(() => {
     if (!errand || !errand._id) return false;
     return true;
   }, [errand?._id]);

   const MorphVideoListMenuSkeletonProps = {
     errand,
     cancelAction,
   };

   return renderMorphVideoListMenuSkeleton ? (
     <ErrorBoundary debug={`./src/Components/Skeletons/MorphVideoListMenuSkeleton.tsx`}>
       <Suspense fallback={<MorphVideoListMenuSkeletonFallback />}>
         <LazyMorphVideoListMenuSkeleton {...MorphVideoListMenuSkeletonProps} />
       </Suspense>
     </ErrorBoundary>
   ) : (
     <></>
   );
 };
 export default MorphVideoListMenuSkeleton; 