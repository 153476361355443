/*
This component renders a prompt in the Conversation Footer to ask the user for consent for humans to monitor their chats.
In the future, this component will need to be generalized to match any other text that uses this format.
*/
import React, { useState, useEffect, MutableRefObject } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useWidget from '@common/hooks/useWidget';
import Styles from '@styles/ConsentBox.module.css';
import { MorganTheme, useTheme } from '@mui/material';
import axiosCall from '@services/axios';
import eventBus from '../Common/eventBus';
import { useErrandContext } from '@contexts/ErrandContext';
import { useUserContext } from '@contexts/user';
import { useRootContext } from '@contexts/RootContext';
import { useFooterContext } from '@contexts/FooterContext';
import ChatSendButton from './ChatSendButton';
import CheckIcon from '@mui/icons-material/Check';
import { IErrand } from '@interfaces/Conversation';
import { getTpConsentGiven } from '@common/errandUtils';
import { MorphType } from '@common/MorphType';
import { getUserConsent, setUserConsent } from '@storage/userStorage';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import useInitialMount from '@common/hooks/useInitialMount';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import { SendMessage } from '@assets/Icons';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

const SendMessageIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/SendMessage.png';

type TConsentContentProps = {
  handleSubmit: ((e: Event) => void);
  errand: IErrand
  isFocused: Boolean
  inputContainerRef: MutableRefObject<null | HTMLInputElement>;
  fieldAttributeDescription: string;
}

const ConsentBox = ({
  handleSubmit, errand, isFocused, inputContainerRef, fieldAttributeDescription
}: TConsentContentProps) => {
  const { t } = useTranslation();
  const theme: MorganTheme = useTheme();
  const errandContext = useErrandContext();
  const footerContext = useFooterContext();
  const rootContext = useRootContext();
  const { _id } = useUserContext();
  const { isDesktop } = useWindowDimensions();

  const [isChecked, setIsChecked] = useState(true);
  const [inputContainerHeight, setInputContainerHeight] = useState(inputContainerRef?.current?.getBoundingClientRect().height || 57);
  const [processSubmit, setProcessSubmit] = useState(false);

  // These morphTypes need additional height for the consent
  const welcomeMorphTypes = errandContext.morphType === MorphType.CreditRepairWelcome

  // Slotmachine morphtype
  const isSlotMachine = errandContext.morphType === MorphType.SlotMachine

  // Condition in which we want the height to be increased just slightly
  const actionHeight = errand?.lastMessageData?.messageType === "Action" || 
  errand?.lastMessageData?.messageType === 'BlockchainWelcome'

  const insertConsentField = async () => {

    const body = {
      chatId: errand?._id,
      userId: _id,
    }

    const payload = {
      url: `chat/insertConsentGivenField`,
      method: 'POST',
      data: body,
    }

    await axiosCall(payload);
  }

  const checkConsent = () => {
    if (getTpConsentGiven(errand)) setUserConsent('true');
  }

  useInitialMount(checkConsent)

  const checkTheBox = () => {
    setIsChecked(true);
    rootContext.setConsentChecked(true);
  }

  const uncheckTheBox = () => {
    setIsChecked(false)
    rootContext.setConsentChecked(false);
  }

  const handleSendButton = (e) => {
    if (isChecked){
      insertConsentField();
      setTimeout(() => {
        setProcessSubmit(true);
      }, 0)
      rootContext.setTpConsentGiven(true);
      setUserConsent('true');
      eventBus.dispatch('consentGiven');
    }
  }

  // Users must click on the send button to accept consent
  // const handleKeyDown = (event: KeyboardEvent) => {
  //   if (!isChecked) return;
  //   if (event.key === 'Enter' && footerContext.chatInputFieldRef.current.unformattedValue !== '') {
  //     handleSendButton(event);
  //   }
  // };

  // Using a useEffect here to handleSubmit so that we use the
  // latest version of handleSubmit for messageInView
  useEffect(() => {
    if (processSubmit === true){
      handleSubmit(null);
    }
  }, [processSubmit])

  useEffect(() => {
    if (getTpConsentGiven(errand) || getUserConsent() === 'true'){
      rootContext.setTpConsentGiven(true);
    }else{
      rootContext.setTpConsentGiven(false);
    }
  }, [errand]);

  // Users must click on the send button to accept consent
  // useEffect(() => {
  //   if (!rootContext.tpConsentGiven){
  //     document.addEventListener('keydown', handleKeyDown);
  //   }else{
  //     document.removeEventListener('keydown', handleKeyDown)
  //   }
  //   return () => {document.removeEventListener('keydown', handleKeyDown)};
  // }, [isChecked, rootContext.tpConsentGiven]);

  useEffect(() => {
    const updateHeight = () => {
        if (inputContainerRef.current) {
            setInputContainerHeight(inputContainerRef.current.getBoundingClientRect().height);
        }
    };

    const observer = new ResizeObserver(updateHeight);

    if (inputContainerRef.current) {
        observer.observe(inputContainerRef.current);
    }

    if (rootContext.tpConsentGiven){
      observer.disconnect();
    }
    
    return () => {
        observer.disconnect();
    };
  }, [rootContext.tpConsentGiven]);

  if (rootContext.tpConsentGiven) return null;

  return (
    <section className={[Styles.wrapper,
    ...(rootContext.shakingConsent ? [Styles.shaking] : [])
    ].join(' ')}>
      <div className={[Styles.content,
      ... (welcomeMorphTypes ? [Styles.welcomeContent] : []),
      ... (isSlotMachine ? [Styles.slotMachineContent] : []),
      ].join(' ')}>
        {isChecked ? 
          <span onClick={uncheckTheBox}>
            <CheckIcon className={Styles.checkContainer}/>
          </span>
          :
          <div className={Styles.uncheck} onClick={checkTheBox}/>
        }
        {isMobileOrTablet() ? t('byTapping') : t('byClicking')}
        {dynamicConsentButton(fieldAttributeDescription)}
        <Trans i18nKey="termsFooterSunWest">
          terms
          <a target="_blank" href={theme.termsAndConditions.termsConditionsURL} rel="noreferrer" tabIndex={-1}>
            Terms and Conditions
          </a>
          <a target="_blank" href={theme.termsAndConditions.termsPrivacyPolicyURL} rel="noreferrer" tabIndex={-1}>
            Privacy Policy
          </a>
        </Trans>
      </div>
        <div className={[Styles.connector,
        ...(welcomeMorphTypes ? [Styles.welcomeConnector] : []),
        ...(isSlotMachine ? [Styles.slotMachineConnector] : []),
        ...(fieldAttributeDescription === 'BOOLEAN' ? [Styles.booleanConnector] : [])
        ].join(' ')} />
      {/* <div 
      style={{
        height: `${
          isDesktop
            ? welcomeMorphTypes
              ? inputContainerHeight // Desktop, welcomeMorphTypes is true
              : isSlotMachine
              ? inputContainerHeight + 15 // Desktop, welcomeMorphTypes is false, and isSlotMachine is true
              : inputContainerHeight // Desktop, welcomeMorphTypes is false, and isSlotMachine is false
            : welcomeMorphTypes
            ? inputContainerHeight + 3 // Mobile, welcomeMorphTypes is true 
            : isSlotMachine
            ? inputContainerHeight + 20 // Mobile, welcomeMorphTypes is false, and isSlotMachine is true 
            : inputContainerHeight + 3 // Mobile, welcomeMorphTypes is false, and isSlotMachine is false 
        }px`,
      }}
      
      
    className={[
        Styles.sendButtonContainer,
        ...(actionHeight ? [Styles.actionContainer] : []),
        ...(welcomeMorphTypes ? [Styles.welcomeContainer] : []),
        ...(isSlotMachine ? [Styles.slotMachineContainer] : []),
        ...(fieldAttributeDescription === 'BOOLEAN' ? [Styles.booleanContainer] : [])
        ].join(' ')} onClick={handleSendButton}>
        <div style={{height: `${
          isDesktop ? inputContainerHeight-46 : inputContainerHeight-45
        }px`}} className={[
          Styles.connector,
          ...(welcomeMorphTypes ? [Styles.welcomeConnector] : []),
          ...(isSlotMachine ? [Styles.slotMachineConnector] : []),
          ...(fieldAttributeDescription === 'DROPDOWN' ? [Styles.dropdown] : []),
          ...(fieldAttributeDescription === 'BOOLEAN' ? [Styles.booleanConnector] : [])
          ].join(' ')}/>
        {fieldAttributeDescription !== 'DROPDOWN' && <div style={{
          height: `${
            isDesktop
              ? welcomeMorphTypes
                ? inputContainerHeight - 1 // Desktop, welcomeMorphTypes is true 
                : isSlotMachine
                ? inputContainerHeight + 15 // Desktop, welcomeMorphTypes is false, and isSlotMachine is true 
                : inputContainerHeight // Desktop, welcomeMorphTypes is false, and isSlotMachine is false 
              : welcomeMorphTypes
              ? inputContainerHeight - 1 // Mobile, welcomeMorphTypes is true 
              : isSlotMachine
              ? inputContainerHeight + 15 // Mobile, welcomeMorphTypes is false, and isSlotMachine is true 
              : inputContainerHeight // Mobile, welcomeMorphTypes is false, and isSlotMachine is false 
          }px`,
        }} 
        className={[
          Styles.halfBox,
          ...(isFocused || errandContext.morphType === MorphType.DownloadAppBanner || isSlotMachine ? [Styles.focusedBox] : []),
          ...(actionHeight? [Styles.actionHalf] : []),
          ...(welcomeMorphTypes ? [Styles.welcomeHalf] : [])
        ].join(' ')} />}
        <div className={Styles.sendButton}>
          {fieldAttributeDescription === 'BOOLEAN' ?
            <></>
          :
      
          isChecked ? 
            <ChatSendButton/>
            :
            <img
              className={Styles.disabledSendButton}
              src={SendMessageIcon}
              alt="Send Button"
            />
          
        }
        </div>
      </div> */}
    </section>
  );
  
}

export default ConsentBox;

const dynamicConsentButton = (fieldAttributeDescription) => {
  if (fieldAttributeDescription === 'BOOLEAN'){
    return (
      <div className={Styles.booleanIcon}>
        <DoneIcon className={Styles.yesButton}/>
        <HorizontalRuleIcon className={Styles.slash} />
        <CloseIcon className={Styles.noButton} />
      </div>
    )
  }
  

  else{
    return(
      <>
        <SendMessage height={'10px'} width={'10px'} />
      </>
      ) 
  }
}