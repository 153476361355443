import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
  useTheme,
} from '@mui/material';

import { InputAdornment, TextField } from '@mui/material';
import React, { useRef, useCallback, useDeferredValue, useState} from 'react';

import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import useDebounce from '../Common/hooks/useDebounce';

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditUserDialog from './EditUserDialog';
import { IMask } from 'react-imask';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import StandardTablePagination from './StandardTablePagination';
import dayjs from 'dayjs';
import downloadUserReport from './DownloadUserReport';
import { getStatusColors } from '../Common/StatusColors';
import axiosCall from '../Services/axios';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useControllerCallback from '@common/hooks/useControllerCallback'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

const StatusBox = styled(Box)(({ enabled }) => ({
  backgroundColor: enabled ? 'var(--green910)' : 'var(--gray040)',
  borderRadius: '16px',
  color: enabled ? 'var(--gray000)' : 'black',
  height: '24px',
  width: '53px',
  textTransform: 'none',
  textAlign: 'center',
  paddingTop: '2px',
}));

const StatusButton = styled(Box)(({ statuscolor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: statuscolor.main,
  borderRadius: '16px',
  color: statuscolor.text,
  height: '24px',
  fontWeight: '500',
}));

const UserHeaderBar = (props) => {
  const { t } = useTranslation();
  const [localSearchValue, setLocalSearchValue] = useState(props.searchValue);
  useDebounce(() => props.setSearch(searchRef.current.value), props.debounceMS ? props.debounceMS : 350, [localSearchValue]);
  const searchRef = useRef(/** @type {HTMLInputElement} */ (null));
  const shouldHaveSearch = props.shouldHaveSearch !== undefined ? props.shouldHaveSearch : true;
  const shouldHaveActive = props.shouldHaveActive !== undefined ? props.shouldHaveActive : true;
  const shouldHaveRefresh = props.shouldHaveRefresh !== undefined ? props.shouldHaveRefresh : true;

  return (
    <Stack marginBottom={1} direction="row" spacing={2}>
      <Box width="100%" display="flex" justifyContent="flex-start" alignItems="center">
        {props.onAdd && (
          <Button
            startIcon={props.uploadIcon ? <DriveFolderUploadIcon /> : <AddIcon />}
            sx={{ marginRight: '15px', whiteSpace: 'nowrap', width: '260px' }}
            variant="contained"
            color="info"
            onClick={() => {
              if (props.onAdd) {
                props.onAdd();
              }
            }}
          >
            {props.addLabel}
          </Button>
        )}
        {shouldHaveSearch ? (
          <TextField
            sx={{ width: '240px' }}
            inputRef={searchRef}
            focused
            defaultValue={localSearchValue}
            onChange={(e) => {
              setLocalSearchValue(e.target.value);
            }}
            size="small"
            variant="outlined"
            color="info"
            label={t('searchLabel')}
            InputProps={{
              endAdornment: (
                <>
                  {localSearchValue !== '' && (
                    <InputAdornment
                      position="end"
                      onClick={() => {
                        if (searchRef.current) {
                          searchRef.current.value = '';
                        }
                        props.setSearch('');
                        setLocalSearchValue('');
                      }}
                    >
                      <CloseIcon fontSize="small" sx={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        ) : (
          <div></div>
        )}
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          sx={{ marginRight: '15px' }}
          variant="outlined"
          onClick={() => {
            props.setShowBannedUsers(!props.showBannedUsers)
          }}
        >
          {!props.showBannedUsers ? t('showBannedUsers') : t('showOnlyBannedUsers')}
        </Button>
        {shouldHaveActive && props.showInactiveClick && (
          <Button
            sx={{ marginRight: '15px' }}
            variant="outlined"
            onClick={() => {
              if (props.showInactiveClick) {
                props.showInactiveClick();
              }
            }}
          >
            {props.isActive ? t('showInactive') : t('hideInactive')}
          </Button>
          
        )}        
        {shouldHaveRefresh && (
          <Tooltip title={t('refreshButtonTooltip')} placement="bottom">
            <CachedIcon
              sx={{ marginRight: '15px', cursor: 'pointer' }}
              onClick={() => {
                props.onRefresh();
              }}
            />
          </Tooltip>
        )}
        {/* <Button
          sx={{ height: '40px' }}
          variant="console"
          onClick={() => {
            onExport();
          }}>
          {t('downloadReportButton')}
        </Button> */}
      </Box>
    </Stack>
  );
};

const Users = (props) => {
  const [editUserState, setEditUserState] = useState({ open: false, data: null });
  const { t } = useTranslation();
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getActive, setGetActive] = useState(true);
  const [showBannedUsers, setShowBannedUsers] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const deferredSearch = useDeferredValue(search);

  const masked = IMask.createMask({
    mask: '(000) 000-0000',
  });

  const loadData  = useCallback(async (controller) => {
    setLoading(true);
    // Load one more row than required to test if more data exists
    const offset = page * rowsPerPage;
    const limit = rowsPerPage > 0 ? `&limit=${rowsPerPage + 1}` : '';
    const active = getActive ? '&active=1' : '';
    let data;
    if (deferredSearch.length > 0) {
      data = { search: deferredSearch };
    } else {
      data = '';
    }

    let request = {
      url: `user/participants/access/?${showBannedUsers ? 'banned=true&' : ''}order=asc&orderBy=lastname&offset=${offset}${limit}${active}`,
      method: 'POST',
      data: data,
    };
    let config = controller
      ? {
          signal: controller.signal,
        }
      : {};

    try {
      const result = await axiosCall(request, config);
      if (result?.length > 0) {
        // See if we were able to fetch the one additional row, therefore indicating more data is available
        setIsMoreDataAvailable(rowsPerPage > 0 ? result?.length > rowsPerPage : false);
        setDataToDisplay(rowsPerPage > 0 ? result?.slice(0, rowsPerPage) : result);
        setLoading(false);
        try {
          const a = active ? "active=true" : ""
          const result = await axiosCall({ url: `user/count?${a}` ,method: 'GET',});
          if (result) {
            setUserCount(result)
          }
        } catch (error) {
          console.log(error);
        }
        setNoResults(false);
      } else {
        setNoResults(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [getActive, page, rowsPerPage, showBannedUsers, deferredSearch]);

  const formatDateTime = (value) => {
    if (Date.parse(value)) {
      return dayjs(value).format('MM/DD/YY hh:mm:ss A');
    }
    return '';
  };

  const handleDownloadUserReport = async () => {
    try {
      const result = await axiosCall({
        url: `user/participants/access?order=asc&orderBy=lastname`,
      });
      if (result) {
        downloadUserReport(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*************************************
   * Edit User Dialog Functions
   *************************************/
  const handleEditUserOpen = (user) => {
    setEditUserState({ open: true, data: user });
  };

  const handleEditUserClose = () => {
    setEditUserState({ open: false, data: null });
    setErrorMessage('');
  };

  const handleEditUserSubmit = async (data) => {
    if (await editUser(data)) {
      setEditUserState({ open: false, data: null });
      setErrorMessage('');
    }
  };

  const handleShowInactive = () => {
    //Invert this value
    setGetActive(!getActive);
    setPage(0);
  };

  const handleUserReactivate = async (id) => {
    try {
      let result = await axiosCall({
        url: `user/${id}`,
        method: 'PUT',
        data: {
          active: true,
        },
      });

      if (result) {
        await loadData();
      }
    } catch (error) {}
  };

  const handleUserDeactivate = async (id) => {
    try {
      const result = await axiosCall({
        url: `user/${id}`,
        method: 'DELETE',
      });
      if (result) {
        await loadData();
        setErrorMessage('');
      }
    } catch (error) {
      return false;
    }
  };

  const editUser = async (data) => {
    const { id, ...sendData } = data;
    try {
      const result = await axiosCall({
        url: `user/${id}`,
        method: 'PUT',
        data: sendData,
      });
      if (result) {
        await loadData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error');
      return false;
    }
  };

  /*************************************
   * Pagination Functions
   *************************************/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (newSearchValue) => {
    // We want to reset to the first page when searching so we don't miss any documents 
    // (for example, we don't want to begin our search on the second page 
    // as we would miss those on the first)
    setPage(0);
    // Now we can update the search value
    setSearch(newSearchValue);
  };

   // load initial data on mount
   // reload data when getActive, page or rowsPerPage changes
 
   // search 500ms after user gets done typing
   useControllerCallback(loadData);

  return (
    <Box display="flex" flexDirection="column" height="calc(100vh - 175px)" padding={1}>
      <UserHeaderBar
        showBannedUsers={showBannedUsers}
        setShowBannedUsers={setShowBannedUsers}
        setSearch={handleSearchChange}
        onRefresh={loadData}
        onExport={handleDownloadUserReport}
        showInactiveClick={handleShowInactive}
        isActive={getActive}
        searchValue={search}
      />
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <>
            {noResults ? (
              <Stack alignItems="center" justifyContent="center" height="300px">
                <Paper sx={{ p: 1.5 }}>
                  <Typography> {t('noData')} </Typography>
                </Paper>
              </Stack>
            ) : (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t('nameCol')}</StyledTableCell>
                    <StyledTableCell>{t('emailCol')}</StyledTableCell>
                    <StyledTableCell>{'User ID'}</StyledTableCell>
                    <StyledTableCell>{'Web User ID'}</StyledTableCell>
                    <StyledTableCell>{t('phoneCol')}</StyledTableCell>
                    <StyledTableCell>{t('tcpaCol')}</StyledTableCell>
                    <StyledTableCell>{t('optInCol')}</StyledTableCell>
                    <StyledTableCell>{t('unsubscribedSmsCol')}</StyledTableCell>
                    <StyledTableCell>{t('unsubscribedEmailCol')}</StyledTableCell>
                    <StyledTableCell>{t('bannedLabel')}</StyledTableCell>
                    <StyledTableCell>{t('statusCol')}</StyledTableCell>
                    <StyledTableCell>{t('lastAccessCol')}</StyledTableCell>
                    <StyledTableCell>{t('actionCol')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataToDisplay.map((user) => {
                    const active = user.active ? 1 : 0;
                    return (
                      <TableRow
                        sx={{ backgroundColor: active ? 'var(--gray000)' : 'var(--peach030)' }}
                        data-id={user?._id}
                        hover
                        key={user?._id}>
                        <StyledTableCell>
                          {user.firstname} {user.lastname}
                        </StyledTableCell>
                        <StyledTableCell>{user.email}</StyledTableCell>
                        <StyledTableCell>{user.userId}</StyledTableCell>
                        <StyledTableCell>{user.webUserId}</StyledTableCell>
                        <StyledTableCell>
                          {user?.phone ? masked.resolve(user.phone) : ''}
                        </StyledTableCell>
                        <StyledTableCell>
                          <StatusBox enabled={user.tcpaConsentGiven ? 1 : 0}>
                            {user.tcpaConsentGiven ? t('yes') : t('no')}
                          </StatusBox>
                        </StyledTableCell>
                        <StyledTableCell>
                          <StatusBox enabled={user.optInConsentGiven ? 1 : 0}>
                            {user.optInConsentGiven ? t('yes') : t('no')}
                          </StatusBox>
                        </StyledTableCell>
                        <StyledTableCell>
                          <StatusBox enabled={!user.smsNotifications ? 1 : 0}>
                            {!user.smsNotifications ? t('yes') : t('no')}
                          </StatusBox>
                        </StyledTableCell>
                        <StyledTableCell>
                          <StatusBox enabled={!user.emailNotifications ? 1 : 0}>
                            {!user.emailNotifications ? t('yes') : t('no')}
                          </StatusBox>
                        </StyledTableCell>
                        <StyledTableCell>
                          <StatusBox enabled={user.banned ? 1 : 0}>
                            {user.banned ? t('yes') : t('no')}
                          </StatusBox>
                        </StyledTableCell>
                        <StyledTableCell>
                          <StatusButton statuscolor={getStatusColors(user.status, theme)}>
                            {user.status ? t(`${user.status}Status`) : t('offlineStatus')}
                          </StatusButton>
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatDateTime(user.lastAccess?.createdAt)}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Tooltip
                            title={t('editUserTooltip')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <BorderColorOutlinedIcon
                              onClick={() => handleEditUserOpen(user)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '15px', color: 'var(--blue210)' }}
                            />
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <StandardTablePagination
                      dataSize={userCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      isMore={isMoreDataAvailable}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </>
        )}
      </TableContainer>
      {editUserState.open && (
        <EditUserDialog
          open={editUserState.open}
          user={editUserState.data}
          error={errorMessage}
          onClose={handleEditUserClose}
          onSubmit={handleEditUserSubmit}
        />
      )}
    </Box>
  );
};

export default Users;
