import { MutableRefObject, useRef, useCallback, useEffect } from 'react';

// Define a type for AbortController mapping
type AbortControllerType = Record<string, AbortController>;

/**
 * Manages the AbortController instance for a specific key.
 * Aborts any existing controller for the key before creating a new one.
 * Returns the AbortSignal of the new AbortController.
 *
 * @param abortControllerRef - MutableRefObject holding the AbortController map.
 * @param key - Key identifying the AbortController instance.
 * @returns The new AbortSignal associated with the new AbortController.
 */
const AbortControllerInstance = (
  abortControllerRef: MutableRefObject<AbortControllerType>,
  key: string
): { signal: AbortSignal } => {
  const data = abortControllerRef.current;

  AbortControllerClear(abortControllerRef, key);

  // Create and assign a new AbortController
  data[key] = new AbortController();

  const output = {
    signal: data[key].signal,
  };
  // Return the signal of the new AbortController
  return output;
};

/**
 * Aborts and removes the AbortController associated with a specific key.
 *
 * @param abortControllerRef - MutableRefObject holding the AbortController map.
 * @param key - Key identifying the AbortController instance to clear.
 */
const AbortControllerClear = (abortControllerRef: MutableRefObject<AbortControllerType>, key: string): void => {
  const data = abortControllerRef.current;

  if (data[key]) {
    try {
      data[key].abort();
    } catch (err) {
      console.error(`Error aborting controller for key: ${key}`, err.message);
    } finally {
      delete data[key]; // Remove the controller from the map
    }
  }
};

/**
 * Aborts and clears all AbortController instances in the map.
 * Useful for cleanup to cancel all ongoing tasks.
 *
 * @param abortControllerRef - MutableRefObject holding the AbortController map.
 */
const AbortControllerDestructor = (abortControllerRef: MutableRefObject<AbortControllerType>) => {
  const data = abortControllerRef.current;

  // Abort and clean up all AbortController instances in the map
  for (const key of Object.keys(data)) {
    AbortControllerClear(abortControllerRef, key);
  }
};

/**
 * Custom hook to manage AbortControllers for different keys.
 * Provides an `getAbortSignal` function to obtain a new AbortSignal per key,
 * and a `manualAbort` function to cancel a specific controller by key.
 */
const useAbortControllerV2 = () => {
  const abortControllerRef = useRef<AbortControllerType>({});

  /**
   * Retrieves or creates a new AbortSignal for the specified key.
   * If an AbortController already exists for the key, it aborts the previous one
   * before creating and returning a new signal.
   *
   * @param key - Unique identifier for the AbortController instance.
   * @returns An object containing the AbortSignal of the newly created AbortController.
   */
  const getAbortSignal = useCallback((key: string): { signal: AbortSignal } => {
    return AbortControllerInstance(abortControllerRef, key);
  }, []);

  /**
   * Manually aborts the AbortController associated with the specified key.
   * Typically, this is only needed in specific scenarios; automatic aborting
   * is handled by the `getAbortSignal` function.
   *
   * @param key - Unique identifier for the AbortController to be aborted.
   */
  const manualAbort = useCallback((key: string): void => {
    return AbortControllerClear(abortControllerRef, key);
  }, []);

  /**
   * Cleans up all AbortControllers when the component using this hook unmounts.
   * This ensures that any pending requests are canceled, avoiding potential memory leaks.
   */
  useEffect(() => {
    return () => {
      AbortControllerDestructor(abortControllerRef);
    };
  }, []);

  return { getAbortSignal, manualAbort };
};

export default useAbortControllerV2;
