import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Stack, Fade } from '@mui/material';
import MessageTextStyle from '@styles/MessageTextStyle';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useTranslation } from 'react-i18next';
import MessageTime from '@components/MessageTime';
import Sanitized from '@components/Sanitized';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';

import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import { ValidatorFunctions } from '@common/Validators';
import { shouldCurrUserReplyTo } from '@common/userMessagesUtils';
import { getLangName, uiTranslationController } from '@common/common';
import OriginalLangStyle from '@styles/OriginalLangStyle';
import TranslationInfoStyle from '@styles/TranslationInfoStyle';
import TranslationTabLeft from '@styles/TranslationTabLeft';
import TranslationTabRight from '@styles/TranslationTabRight';
import { getUserConsent, getUserConsentIntro } from '@storage/userStorage';
import { useUserContext } from '@contexts/user';

type TUserPromptsMenuMessageContentProps = {
  message: IMessage;
  errand: IErrand;
};

const UserPromptsMenuMessageContent = (props: TUserPromptsMenuMessageContentProps) => {
  const errandContext = useErrandContext();
  const { dispMessage, isTranslated } = uiTranslationController(props.message);
  const { _id, isUser } = useUserContext();
  const [revealEnabled, setRevealEnabled] = useState(false);
  const [isFading, setIsFading] = useState(true);
  const messageRef = useRef(null);

  /**
   * This function is a handler callback for the Original Language component's
   * onClick event. It is responsible for temporarily revealing the origianl
   * message.
   */
  const handleClickOriginalLang = useCallback(
    async (e) => {
      e?.stopPropagation();
      if (!props.message.visible) return;
      if (revealEnabled) {
        return;
      }
      const reset = messageRef.current.innerHTML;

      // Start the inital fadeout:
      setRevealEnabled(true);
      setIsFading(false);

      // Wait until the fadeout is done to fade in the original message:
      setTimeout(
        (reset) => {
          let editedMessage = props.message.message;

          let [firstSection, secondSection] = props.message.message
            .split(`<i class="messageArrow"/>`)
            .map((x) => x.replace(/(<([^>]+)>)/gi, ''));

          if (secondSection) {
            editedMessage = `<s>${firstSection}</s>&nbsp;<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1r4vxry-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowCircleRightRoundedIcon"><path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10zm-10 2.79V13H9c-.55 0-1-.45-1-1s.45-1 1-1h3V9.21c0-.45.54-.67.85-.35l2.79 2.79c.2.2.2.51 0 .71l-2.79 2.79c-.31.31-.85.09-.85-.36z"></path></svg> <i>${secondSection}</i>`;
          }

          // Style the original message:
          editedMessage = `<div style="font-size: 0.8em; font-family: Poppins; font-weight: 400;">${editedMessage}</div>`;

          messageRef.current.innerHTML = editedMessage;
          setIsFading(true);

          // Allow the revealed message to display for some time, then begin fading
          // it out:
          setTimeout(
            (reset) => {
              setIsFading(false);

              // Finally, wait until the fadeout is done, then fade in the translated
              // message:
              setTimeout(
                (reset) => {
                  messageRef.current.innerHTML = reset;
                  setIsFading(true);
                  setRevealEnabled(false);
                },
                500,
                reset
              );
            },
            3000,
            reset
          );
        },
        500,
        reset
      );
    },
    [revealEnabled, props.message, messageRef]
  );

  const openUserPromptsMenu = () => {
    if (errandContext.morphType !== MorphType.UserPromptsMenu) errandContext.setMorphType(MorphType.UserPromptsMenu);
  };

  useEffect(() => {
    // open only if
    // curr message is last AND no curr action AND is not operator view
    const lastMsgInCurrErrand = props.errand.messages[props.errand.messages.length - 1]

    if (
      ValidatorFunctions.isUndefinedOrNull(errandContext.errand?.action) === true &&
      lastMsgInCurrErrand._id === props.message._id &&
      isUser &&
      shouldCurrUserReplyTo(props.message, _id) === true &&
      errandContext.isSearchOpen === false
    ) {
      openUserPromptsMenu();
    }
  }, [_id]);

  return (
    <Stack
      display="flex"
      flexDirection="column"
      alignItems={
        // On the user side, Team, and Group chats: only sender is on the right side
        // On the operator side, only sender (operator) and Morgan is on the right side
        props.message?.alignByCurrentUser ? 'flex-end' : 'flex-start'
      }
      width="fit-content"
      maxWidth={window.innerWidth > 900 ? 'min(60%, calc(100vw - 50px))' : 'min(90%, calc(100vw - 50px))'}
      minWidth={'85px'}
    >
      <Stack display="flex" flexDirection="column">
        <ChatBubbleStyle
          sx={{
            minWidth: '85px',
            borderColor: 'var(--orange700)',
            background: props.message.sentByCurrentUser ? 'var(--gray000)' : 'var(--peach600)',
            pointerEvents: 'all',
          }}
        >
          <Stack flexDirection="column" width="100%">
            <Stack flexDirection="row">
              <Stack
                alignItems="center"
                sx={{
                  d: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  p: '6px 7px 8px 9px',
                  maxWidth: '100%',
                  position: 'relative',
                  width: '100%',
                }}
              >
                <Fade in={isFading} appear={false}>
                  <Stack direction="row" justifyContent="center" alignItems="center" ref={messageRef}>
                    <MessageTextStyle>
                      <Sanitized html={dispMessage} highlight={props.message?.searchWords} />
                    </MessageTextStyle>
                  </Stack>
                </Fade>
                <Stack
                  sx={{
                    marginRight: '10px',
                    marginLeft: 'auto',
                    marginTop: '0px',
                    marginBottom: '0px',
                    flexWrap: 'nowrap',
                    backgroundColor: 'transparent',
                  }}
                >
                  <MessageTime message={props.message} />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </ChatBubbleStyle>
      </Stack>
      {isTranslated &&
        (props.message.alignByCurrentUser ? (
          <TranslationTabRight>
            <OriginalLangStyle onClick={handleClickOriginalLang}>
              <u>{getLangName(props.message.detectedLanguage)}</u>
            </OriginalLangStyle>
            <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {props.message.localizedLanguage}</TranslationInfoStyle>
          </TranslationTabRight>
        ) : (
          <TranslationTabLeft>
            <OriginalLangStyle onClick={handleClickOriginalLang}>
              <u>{getLangName(props.message.detectedLanguage)}</u>
            </OriginalLangStyle>
            <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {props.message.localizedLanguage}</TranslationInfoStyle>
          </TranslationTabLeft>
        ))}
    </Stack>
  );
};

export default UserPromptsMenuMessageContent;
