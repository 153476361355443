/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 * 
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 * 
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React, { Dispatch, SetStateAction, useMemo, lazy, Suspense } from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphMessageOptionsSkeleton.module.css';
import { IErrand } from '@interfaces/Conversation';
import ErrorBoundary from "@components/ErrorBoundary";

const LazyMorphMessageOptions = lazy(() => import('@components/MorphMessageOptions/MorphMessageOptions'));

const MorphMessageOptionsFallback = () => {
  return (
    <div className={Skeleton.loader + ' ' + Styles.style} />
  );
};
// The render component below lazy loads the corresponding component using the skeleton above
type TRenderMorphMessageOptionsProps = {
  errand: IErrand;
  setEditMessageId: Dispatch<SetStateAction<string>>;
  setValue: Dispatch<SetStateAction<string>>;
};
const MorphMessageOptionsSkeleton = ({
  errand, setEditMessageId, setValue,
}: TRenderMorphMessageOptionsProps) => {
  const renderOptions = useMemo(() => {
    // guard statements required for handleClickSentiment to function properly
    if (!errand || !errand._id) return false;
    if (typeof setEditMessageId !== 'function') return false;
    if (typeof setValue !== 'function') return false;
    return true;
  }, [errand?._id, setEditMessageId, setValue]);
  const MorphMessageOptionsProps = {
    errand, setEditMessageId, setValue,
  };

  return renderOptions ? (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphMessageOptionsSkeleton.tsx`}>
      <Suspense fallback={<MorphMessageOptionsFallback />}>
        <LazyMorphMessageOptions {...MorphMessageOptionsProps} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <></>
  );
};
export default MorphMessageOptionsSkeleton;
