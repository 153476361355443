import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import React, { useContext, useCallback, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';

const MainPhotoSelector = React.memo(() => {
  const { t } = useTranslation();
  const { mainPhoto, setMainPhoto, photoSelectorIndex } = useContext<IErrandContext>(ErrandContext);
  const [isWarningActive, setIsWarningActive] = useState<String | null>(null);

  const handleSelectMainPhoto = useCallback(() => {
    if (photoSelectorIndex !== undefined) {
      setMainPhoto(photoSelectorIndex);
    } else {
      setIsWarningActive(t('pleaseSelectValidMainPhoto'));
    }
  }, [photoSelectorIndex, setMainPhoto, t]);

  const handleDeselectMainPhoto = useCallback(() => {
    setMainPhoto(null);
  }, [setMainPhoto]);

  return (
    <>
      <Snackbar
        open={isWarningActive ? true : false}
        onClose={() => setIsWarningActive(null)}
        message={isWarningActive}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
      {mainPhoto === photoSelectorIndex && photoSelectorIndex !== null ? (
        <div style={{ textAlign: 'center' }} onClick={handleDeselectMainPhoto}>
          <StarIcon sx={{ color: 'orange' }} />
          <p style={{ fontFamily: 'Poppins', fontSize: '9px', color: 'grey', lineHeight: '100%', marginTop: '-5px' }}>
            {t('removeMainPhoto')}
          </p>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }} onClick={handleSelectMainPhoto}>
          <StarBorderIcon sx={{ color: 'orange' }} />
          <p style={{ fontFamily: 'Poppins', fontSize: '9px', color: 'grey', lineHeight: '100%', marginTop: '-5px' }}>
            {t('setMainPhoto')}
          </p>
        </div>
      )}
    </>
  );
});

export default MainPhotoSelector;
