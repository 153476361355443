/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 *
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 *
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React, { Dispatch, SetStateAction, lazy, Suspense } from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphChooseLoanProductsSkeleton.module.css';
import ErrorBoundary from "@components/ErrorBoundary";

const LazyMorphChooseLoanProducts = lazy(() => import('@components/ChooseLoanProduct/MorphChooseLoanProducts'));

const MorphChooseLoanProductsFallback = () => {
  return <div className={Skeleton.loader + ' ' + Styles.style} />;
};
// The render component below lazy loads the corresponding component using the skeleton above
type TMorphChooseLoanProductsSkeletonProps = {
  setText: Dispatch<SetStateAction<string>>;
};
const MorphChooseLoanProductsSkeleton = ({ setText }: TMorphChooseLoanProductsSkeletonProps) => {
  const MorphChooseLoanProductsSkeletonProps = {
    setText,
  };

  return (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphChooseLoanProductsSkeleton.tsx`}>
      <Suspense fallback={<MorphChooseLoanProductsFallback />}>
        <LazyMorphChooseLoanProducts {...MorphChooseLoanProductsSkeletonProps} />
      </Suspense>
    </ErrorBoundary>
  );
};
export default MorphChooseLoanProductsSkeleton;
