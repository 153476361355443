import React, { useMemo, Suspense } from 'react';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { useMessageContext } from '@contexts/MessageContext';
import { AccessType } from '@common/AccessType';
import { DeletedMessageContentFallback, PrivateMessageContentFallback } from './Fallbacks';
import { MessageFallbackSelector } from './Fallbacks/MessageFallbackSelector';
import { MessageTypeSelector } from '@components/MessageTypeSelector';
import DeletedMessageContent from '@components/MessageContent/DeletedMessageContent';
import PrivateMessageContent from '@components/MessageContent/PrivateMessageContent';
import ErrorBoundary from '@components/ErrorBoundary';

type MessageContentSkeletonProps = {
  errand: IErrand;
  index: number;
  message: IMessage;
};

const MessageContentSkeleton = React.memo(({ errand, index, message }: MessageContentSkeletonProps) => {
  const messageContext = useMessageContext();

  const isDeletedMessageContent = useMemo(() => {
    return message.messageStatus === 'deleted';
  }, [message.messageStatus]);

  const isPrivateMessageContent = useMemo(() => {
    return !messageContext.isPrivate && message.accessType === AccessType.private;
  }, [messageContext.isPrivate, message.accessType]);

  const componentTypeProps = useMemo(() => {
    // prop definitions for error boundary reports
    const ErrandProp = {
      errand,
    };

    const MessageProp = {
      message,
    };

    const ErrandMessageProps = {
      errand,
      message,
    };

    const IndexMessageProps = {
      index,
      message,
    };

    const ErrandIndexMessageProps = {
      errand,
      index,
      message,
    };

    return {
      ErrandProp,
      MessageProp,
      ErrandMessageProps,
      IndexMessageProps,
      ErrandIndexMessageProps,
    };
  }, [message, errand, index]);

  const messageComponent = useMemo(
    () =>
      isDeletedMessageContent ? (
        <DeletedMessageContent {...componentTypeProps.MessageProp} />
      ) : isPrivateMessageContent ? (
        <PrivateMessageContent {...componentTypeProps.ErrandIndexMessageProps} />
      ) : (
        // all other msg types
        <MessageTypeSelector componentTypeProps={componentTypeProps} message={message} />
      ),
    [componentTypeProps, isDeletedMessageContent, isPrivateMessageContent, message]
  );

  const fbComponent = useMemo(
    () =>
      isDeletedMessageContent ? (
        <DeletedMessageContentFallback message={message} />
      ) : isPrivateMessageContent ? (
        <PrivateMessageContentFallback message={message} />
      ) : (
        // all other message types
        <MessageFallbackSelector message={message} />
      ),
    [isDeletedMessageContent, isPrivateMessageContent, message]
  );

  if (!message) return <></>;

  return (
    <ErrorBoundary debug={'./src/Components/Skeletons/MessageContentSkeleton.tsx'}>
      <Suspense fallback={fbComponent}>{messageComponent}</Suspense>
    </ErrorBoundary>
  );
});

export default MessageContentSkeleton;
