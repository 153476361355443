/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 * 
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 * 
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React, { lazy, Suspense } from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphPrivateChatSkeleton.module.css';
import type { TConversationBodyProps } from '@components/ConversationBody';
import ErrorBoundary from "@components/ErrorBoundary";

const LazyMorphPrivateChat = lazy(() => import('@components/MorphPrivateChat'));

const MorphPrivateChatFallback = () => {
  return (
    <div className={Skeleton.loader + ' ' + Styles.style} />
  );
};
const MorphPrivateChatSkeleton = ({
  dispFilterMode, editMessageId, errand, isTyping, operatorData, setEditMessageId, setIsTyping, setPreviewUrl, setValue, showSentiment, 
}: TConversationBodyProps) => {
  const MorphPrivateChatProps = {
    dispFilterMode, editMessageId, errand, isTyping, operatorData, setEditMessageId, setIsTyping, setPreviewUrl, setValue, showSentiment, 
  };

  return (
    <ErrorBoundary debug={`./src/Components/Skeletons/MorphPrivateChatSkeleton.tsx`}>
      <Suspense fallback={<MorphPrivateChatFallback />}>
        <LazyMorphPrivateChat {...MorphPrivateChatProps} />
      </Suspense>
    </ErrorBoundary>
  );
};
export default MorphPrivateChatSkeleton;
