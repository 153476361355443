/**
 * @file This Component is used for rendering chat bubbles that display the TCPA
 * agreement, using a details html element in order to allow for collapsible and
 * expandable bubbles due to the TCPA text length.
 * 
 * @author Harrison Fales <harrison.fales@swmc.com>
 */

import React, { useCallback, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { uiTranslationController } from '@common/common';
import useAbortController from '@common/hooks/useAbortController';
import { useErrandContext } from '@contexts/ErrandContext';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { getUserConsent, getUserConsentIntro } from '@storage/userStorage';
import eventBus from '@common/eventBus';
import { MorphType } from '@common/MorphType';
import { useRootContext } from '@contexts/RootContext';
import axiosCall from '@services/axios';
import { useUserContext } from '@contexts/user';
const ArrowIcon = process.env.REACT_APP_MORGAN_CDN + '/Icons/OpenArrowIcon.svg';

const styleModule = {
  // Styling for top half of <details> element
  summary: {
    backgroundColor: 'var(--peach600)',
    borderRadius: '10px',
    padding: '6px 7px 8px 9px',
    border: '1px solid var(--orange700)',
    position: 'relative',
    cursor: 'pointer',
    listStyleType: 'none',
    '&::WebkitDetailsMarker': {
      display: 'none',
    },
  },
  // Styling for bottom half of <details> element
  p: {
    backgroundColor: 'var(--peach600)',
    borderRadius: '10px',
    padding: '6px 7px 8px 9px',
    border: '1px solid var(--orange700)',
    marginTop: '4px',
    cursor: 'pointer',
  },
  img: {
    height: '25px',
    position: 'absolute',
    top: '9px',
    left: '7px',
    zIndex: '3',
  },
};

// Styled component for generating the overall <details> element
const Details = styled('details')({
  display: 'flex',
  flexDirection: 'column',
  pointerEvents: 'all',
  position: 'relative',
  '&[open] img': {
    transform: 'scaleY(-1)',
  },
});

// Component responsible for housing the arrow icon, which sits on
// top of either halves
const ArrowButton = styled(Box)({
  borderRadius: '50%',
  border: '1px solid var(--orange700)',
  backgroundColor: 'var(--peach600)',
  position: 'absolute',
  left: '4px',
  top: '6px',
  height: '30px',
  width: '30px',
  zIndex: '2',
  animation: 'pulse 1s 5',

  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      opacity: '1'
    },
    '50%': {
      transform: 'scale(1.2)',
      opacity: '0.7'
    },
    '100%': {
      transform: 'scale(1)',
      opacity: '1'
    },
  },
});

// Half circle that only sits on the closed bubble for
// making the notch in the bubble
const ArrowButtonTop = styled(Box)({
  position: 'relative',
  borderRadius: '5rem 5rem 0 0',
  border: '1px solid var(--orange700)',
  borderBottom: 'none',
  backgroundColor: 'var(--peach000)',
  height: '20px',
  width: '40px',
  margin: '0px auto -9px auto',
});
// Half circle that only sits on the open bubble for
// making the notch in the bubble
const ArrowButtonBottom = styled(Box)({
  borderRadius: '0 0 5rem 5rem',
  border: '1px solid var(--orange700)',
  borderTop: 'none',
  backgroundColor: 'var(--peach000)',
  margin: '-7px auto 0px auto',
  height: '20px',
  width: '40px',
});

type TTcpaMessageContentProps = {
  errand: IErrand;
  message: IMessage;
}

const TcpaMessageContent = ({
  errand, message, 
}: TTcpaMessageContentProps) => {
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const abortController = useAbortController();
  const { isUser } = useUserContext();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { dispMessage } = uiTranslationController(message);

  const handleToggle = useCallback(async (e) => {
    if (e.type === 'toggle') {
      if (!detailsOpen) {
        e?.preventDefault();
        if (!message.visible) return;
        if (isUser && getUserConsentIntro() !== 'true') {
          return eventBus.dispatch('showConsentIntro');
        }
        if (isUser && getUserConsent() !== 'true') {
          return eventBus.dispatch('showConsentContent');
        }
        if ([MorphType.Attachment, MorphType.PhotoMain, MorphType.PhotoPlain].includes(errandContext.morphType)) {
          //reset the morph data on change.
          errandContext.setMainPhoto(null);
          errandContext.setPhotoLimit(null);
          errandContext.setPhotoSelectorIndex(null);
        }
        errandContext.setEditMessageId('');
        errandContext.setMessageOptionsIndex(-1);
        errandContext.setIsMorphedFooterCloseButtonOnLeft(false);

        // updates the icon, placeholder, and action on user side when the operator clicks the icon
        const payload = {
          url: `useraction/${message?.userAction?._id}`,
          method: 'put',
          data: {
            status: 'in-progress',
          },
        };
        const config = abortController.reset();
        await axiosCall(payload, config);
        rootContext.setErrands((prev) => {
          if (!Array.isArray(prev)) {
            console.warn('setErrands prev is not an array');
            prev = [];
          }
          let index = prev.findIndex((e) => e._id === errand._id);
          prev[index] = {
            ...prev[index],
            icon: message.icon,
            placeholder: message?.action?.description,
            action: {
              ...message?.userAction,
              action: message?.action,
              userActionId: message?.userAction?._id,
              active: true,
            },
            recipients: message.intendedAudience ? [message.sender._id, ...message.intendedAudience].sort() : [],
          };
          return [...prev];
        });
        console.log('User triggered action, ' + message?.action?._id);
        errandContext?.footerInputRef?.current?.focus();
      }
      setDetailsOpen((prev) => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    abortController, 
    detailsOpen, 
    isUser, 
    rootContext.setErrands, 
    message?.userAction?._id, 
    message?.action, 
    message?.icon, 
    errand._id, 
    message?.visible
  ]);

  return (
    // Set the side of the screen the bubble appears on depending on who sent it
    // (Should always be operator)
    <Stack
      display="flex"
      flexDirection="column"
      alignItems={
        message.alignByCurrentUser
          ? 'flex-end'
          : 'flex-start'
      }
      width="fit-content"
      maxWidth="min(60%, calc(100vw - 50px))"
      marginBottom='18px'
       mt='4px' 
    >
        <Details open={detailsOpen} onToggle={handleToggle}>
          <summary style={styleModule.summary}>
            <Typography sx={{ fontSize: '12px' }}>{dispMessage.split('<>')[0]}</Typography>
            <ArrowButtonTop>
              <ArrowButton />
              <img style={styleModule.img} src={ArrowIcon} alt="Arrow" />
            </ArrowButtonTop>
          </summary>
          <div style={styleModule.p}>
            <ArrowButtonBottom />
            <Typography sx={{ fontSize: '12px',}}>{dispMessage.split('<>')[1]}</Typography>
          </div>
        </Details>
    </Stack>
  );
};

export default TcpaMessageContent;
