/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 *
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 *
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React, { lazy, Suspense } from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphCreditRepairWelcomeSkeleton.module.css';
import ErrorBoundary from "@components/ErrorBoundary";

const LazyMorphRefinanceCalculatorWelcome = lazy(() =>
  import('@components/MorphRefinanceCalculatorWelcome').then((module) => ({
    default: module.MorphRefinanceCalculatorWelcome,
  }))
);

const MorphRefinanceCalculatorWelcomeFallback = () => {
  return <div className={Skeleton.loader + ' ' + Styles.style} />;
};
// The render component below lazy loads the corresponding component using the skeleton above
type TRenderMorphRefinanceCalculatorWelcomeProps = {
  handleSubmit: ((e: Event) => void);
};
const MorphRefinanceCalculatorWelcomeSkeleton = ({
    handleSubmit
  }: TRenderMorphRefinanceCalculatorWelcomeProps) => {
    const MorphRefinanceCalculatorWelcomeProps = {
      handleSubmit,
    };

    return (
      <ErrorBoundary debug={`./src/Components/Skeletons/MorphRefinanceCalculatorWelcomeSkeleton.tsx`}>
        <Suspense fallback={<MorphRefinanceCalculatorWelcomeFallback />}>
          <LazyMorphRefinanceCalculatorWelcome {...MorphRefinanceCalculatorWelcomeProps} />
        </Suspense>
      </ErrorBoundary>
    );
};
export default MorphRefinanceCalculatorWelcomeSkeleton;
