import React from 'react';
import { CloseIcon } from "@assets/Icons";
import { ChatType } from "@common/ChatType";
import { ContentCopy } from "@mui/icons-material";
import { Menu, MenuItem, ListItemIcon, Typography } from "@mui/material";
import AssignmentIcon from '@mui/icons-material/Assignment';
import FeedbackIcon from '@mui/icons-material/Feedback';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import axiosCall from "@services/axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ValidatorFunctions } from '@common/Validators';
import { IErrand, IUserData } from '@interfaces/Conversation';

// Constants
const zIndexSxStyle = { zIndex: '1050' };
const menuItemFontSize = 12;

type Props = {
    handleClose: () => void,
    setAddOperatorDialog: React.Dispatch<React.SetStateAction<boolean>>,
    setAnchorEl: React.Dispatch<React.SetStateAction<EventTarget & Element>>,
    handleToggleFlag: () => void,
    handleContentCopy: () => Promise<void>,
    setConsoleDialog: React.Dispatch<React.SetStateAction<boolean>>,
    setErrands: React.Dispatch<React.SetStateAction<IErrand[]>>,
    anchorEl: (EventTarget & Element),
    errand: IErrand,
    operatorData: IUserData,
}

const ConversationTitleMenu = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        anchorEl,
        handleClose,
        setAddOperatorDialog,
        setAnchorEl,
        handleToggleFlag,
        handleContentCopy,
        setConsoleDialog
    } = props;

    /**
     * ================== Local Components ==================
     */
    const renderAddOperatorButton = () => {
        // Main Logic
        const addOperatorHandler = async () => {
            // Add operator
            setAddOperatorDialog(true);
            setAnchorEl(null);
        }

        return (
            <MenuItem
                onClick={addOperatorHandler}>
                <ListItemIcon>
                    <GroupAddIcon fontSize="small" />
                </ListItemIcon>
                <Typography fontSize={menuItemFontSize}>
                    {t('consoleConversationsMenuAddOp')}
                </Typography>
            </MenuItem>
        )
    }

    const renderLeaveConvoButton = () => {
        // Main Logic
        const leaveConvoHandler = async () => {
            const operatorParticipantId = props.errand?.participants?.filter(p => p?.active && p?.userData?._id === props.operatorData._id)?.[0]?._id;
            if (!operatorParticipantId) {
                return console.error("Cannot remove participant that is not in chat participant list");
            }
            // Leave conversation
            await axiosCall({
                url: `chat/${props.errand?._id}/participant/${operatorParticipantId}`,
                method: 'delete',
            });
            setAnchorEl(null);
            let tab = 'mine';
            if (props.errand?.type === ChatType.Team || props.errand?.type === ChatType.Group) {
                tab = props.errand?.type;
            }

            // 9/3/24 - Icy: Need to set chat to something other than null/undefined because otherwise, the same
            // chat is returned as prevChat, causing the useEffect in ConversationsList to not work, 
            // so operator is unable to attend this chat immediately after leaving it. Setting to -1 fixes that.
            navigate('/console', {
                state: {
                    module: 'conversations',
                    tab: tab,
                    chat: -1,
                },
            });
        }

        return (
            <MenuItem
                onClick={leaveConvoHandler}>
                <ListItemIcon>
                    <GroupRemoveIcon fontSize="small" />
                </ListItemIcon>
                <Typography fontSize={menuItemFontSize}>
                    {t('consoleConversationsMenuLeave')}
                </Typography>
            </MenuItem>
        );
    }

    const renderSuspiciousButton = () => {
        // Business Logic
        // If Group or Team errand, do not show the button
        if (props.errand.type === ChatType.Group || props.errand.type === ChatType.Team)
            return null;

        const suspiciousButtonClickHandler = () => {
            handleToggleFlag()
            handleClose();
        }

        return (
            <MenuItem
                key="suspiciousBtn"
                onClick={suspiciousButtonClickHandler}>
                <ListItemIcon>
                    <FeedbackIcon fontSize="small" />
                </ListItemIcon>
                <Typography fontSize={menuItemFontSize}>
                    {props.errand.isFlagged
                        ? t('consoleConversationsMenuUnmarkSus')
                        : t('consoleConversationsMenuMarkSus')}
                </Typography>
            </MenuItem>
        );
    }

    const renderCloseChatButton = () => {
        // Business Logic
        // If Conversation Or Group errand, do not show the button
        if (props.errand.type === ChatType.Conversation
            || props.errand.type === ChatType.Group
            || props.errand.type === ChatType.Team)
            return null;

        // Main Logic
        const closeChatButtonHandler = async () => {
            // Set status to waiting
            await axiosCall({
                url: `chat/${props.errand?._id}`,
                method: 'PUT',
                data: { status: 'closed' },
            });

            // Update the errand status so that the conversation component
            // can handle the errands appropriately
            props.setErrands((prev) => {
                if (!Array.isArray(prev)) {
                    console.warn('setErrands prev is not an array');
                    prev = [];
                }
                let index = prev.findIndex((e) => e._id === props.errand._id);
                if (index === -1) return prev;
                prev[index].status = 'closed';
                return [...prev];
            });

            handleClose();
        }

        return (
            <MenuItem
                key="closeChatBtn"
                onClick={closeChatButtonHandler}>
                <ListItemIcon>
                    <CloseIcon fontSize="small" />
                </ListItemIcon>
                <Typography fontSize={menuItemFontSize}>
                    {t('consoleConversationsMenuMarkClosed')}
                </Typography>
            </MenuItem>
        )
    }

    const renderCopyContentButton = () => {
        // Business Logic
        // If Group or Team errand, do not show the button
        if (props.errand.type === ChatType.Group || props.errand.type === ChatType.Team)
            return null;

        // if accessLevel is not admin, do not show the button
        if (props.operatorData && props.operatorData.accessLevel && props.operatorData.accessLevel !== 'Admin')
            return null;

        // Main Logic
        return (
            <MenuItem onClick={handleContentCopy}>
                <ListItemIcon>
                    <ContentCopy fontSize="small" />
                </ListItemIcon>
                <Typography fontSize={menuItemFontSize}>
                    {t('tCopy')}
                </Typography>
            </MenuItem>
        )
    }

    const renderViewConsoleButton = () => {
        // Business Logic
        // If Team or Group errand, do not show the button
        if (props.errand?.type === ChatType.Team || props.errand?.type === ChatType.Group)
            return null;


        const viewConsoleHandler = async () => {
            setConsoleDialog(true);
            setAnchorEl(null);
        }

        return (
            <MenuItem
                onClick={viewConsoleHandler}>
                <ListItemIcon>
                    <AssignmentIcon fontSize="small" />
                </ListItemIcon>
                <Typography fontSize={menuItemFontSize}>
                    {t('viewConsole')}
                </Typography>
            </MenuItem>
        )
    }
    // menu is open when anchorEl exists and has some value.
    const isMenuOpen = ValidatorFunctions.isNotUndefinedNorNull(anchorEl);
    return (
        <Menu
            sx={zIndexSxStyle}
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleClose}
        >

            {/* Add Operator Button */}
            {renderAddOperatorButton()}

            {/* Leave Convo Button */}
            {renderLeaveConvoButton()}

            {/* Suspicious Button */}
            {renderSuspiciousButton()}

            {/* Close Chat Button */}
            {renderCloseChatButton()}

            {/* Copy Content Button */}
            {renderCopyContentButton()}

            {/* View Console Button */}
            {renderViewConsoleButton()}
        </Menu>
    )
}

export default ConversationTitleMenu;



