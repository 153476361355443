import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IErrand, IMessage } from '@interfaces/Conversation';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';

import Styles from '@styles/MorphMessageOptions.module.css';
import { MorphType } from '@common/MorphType';
import { getAudienceArr } from '@common/msgUtils';
import { useUserContext } from '@contexts/user';

const Shush = process.env.REACT_APP_MORGAN_CDN + '/Images/Shush.png';

type TMorphMessageOptionPrivateModeProps = {
  errand: IErrand;
  handleClose: (morphType: MorphType) => void;
  message: IMessage;
};

const MorphMessageOptionPrivateMode = ({ errand, handleClose, message }: TMorphMessageOptionPrivateModeProps) => {
  const { t } = useTranslation();
  const { setMorphType, footerInputRef } = useErrandContext();
  const { setErrands } = useRootContext();
  const { _id } = useUserContext();

  const messageSender = useMemo(() => message?.sender, [message?.sender]);
  const messageIntendedAudience = useMemo(() => message?.intendedAudience, [message?.intendedAudience]);
  const messageChat = useMemo(() => message?.chat, [message?.chat]);
  const messageSenderType = useMemo(() => message?.senderType, [message?.senderType]);

  const messageSenderId = useMemo(() => messageSender?._id, [messageSender?._id]);

  const handleClickPrivateMode = useCallback(
    (e) => {
      e.stopPropagation();

      const audience = getAudienceArr(_id, messageSenderId, messageSenderType, messageIntendedAudience);

      setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === messageChat);

        if (chatObj) {
          chatObj.recipients = audience;
        }

        setMorphType(MorphType.PrivateChat);
        handleClose(MorphType.PrivateChat);
        footerInputRef.current?.focus();

        return [...prev]; // spread to trigger dependency arrays as state was modified
      });
    },
    [
      _id,
      messageSenderType,
      footerInputRef,
      handleClose,
      messageChat,
      messageIntendedAudience,
      messageSenderId,
      setErrands,
      setMorphType,
    ]
  );

  return (
    <button className={Styles.btn} onClick={handleClickPrivateMode}>
      <div className={Styles.btnBorder}>
        <img src={Shush} alt="Angel with index finger on lips asking you to keep her secrets" />
        <p className={Styles.label}>{t('tPrivate')}</p>
      </div>
    </button>
  );
};

export default MorphMessageOptionPrivateMode;
