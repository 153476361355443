import LinkPreview from '@components/LinkPreview';
import React, { useState, useCallback } from 'react';
import MessagesSkeletonLoader from '@components/MessagesSkeletonLoader';
import { useRootContext } from '@contexts/RootContext';
import { useErrandContext } from '@contexts/ErrandContext';
import { IErrand } from '@interfaces/Conversation';

const Page = (props) => {
  // useContext to keep track of when messages have loaded for the skeleton loader
  const [previewUrl, setPreviewUrl] = useState(props.link);

  // parent component context usage
  const { chatBulkClose } = useRootContext();
  const { errandId } = useErrandContext();

  const clearPreviewAndCloseErrand = useCallback(async (url) => {
    try {
      // don't allow url set from child component
      if (url) return;

      // clear preview url
      setPreviewUrl('');

      chatBulkClose([{ _id: errandId } as IErrand]);

    } catch (err) {
      if (err) {
        console.error(`LinkPreview - closeErrand`, err);
      }
    }
  }, [errandId, chatBulkClose]);

  return (
    <>
      <MessagesSkeletonLoader />
      {previewUrl && (
        <LinkPreview url={previewUrl} setPreviewUrl={clearPreviewAndCloseErrand} chatId={props.errandId} />
      )}
    </>
  );
};

export default Page;