import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import axiosCall from '@services/axios';
import styles from '@styles/MessageContent/PropertyListingMessageContent.module.css';
import Sanitized from '@components/Sanitized';
import { IMessage, IErrand } from '@interfaces/Conversation';
import { validateString } from '@common/common';
import { useTranslation } from 'react-i18next';
import TextMessageContent from './TextMessageContent';
import { useMessageContext } from '@contexts/MessageContext';

const DocumentPreview = process.env.REACT_APP_MORGAN_CDN + '/Images/DOC.png';

const PropertyListingPhotos = (props) => {
  const { thumbnail, message } = props;

  const response = useQuery([thumbnail, message?.updatedAt], async ({ signal }) => {
    let request = {
      url: `chat/${message?.chatId}/document/${thumbnail}`,
    };
    let config = {
      signal,
    };
    return await axiosCall(request, config);
  });

  return (
    <div>
      <img
        src={
          response?.data?.mimeType?.split('/')[0] === 'image'
            ? `data:${response?.data?.mimeType};base64,${response?.data?.data}`
            : DocumentPreview
        }
        alt={response?.data?.mimeType?.split('/')[0] === 'image' ? 'Property listing image' : 'Document preview'}
      />
    </div>
  );
};

type TPropertyListingMessageContentProps = {
  errand: IErrand;
  message: IMessage;
};

const PropertyListingMessageContent = ({ errand, message }: TPropertyListingMessageContentProps) => {
  const { t } = useTranslation();
  const { setPreviewUrl } = useMessageContext();

  const messageUrl = useMemo(() => message?.url, [message?.url]);

  const handleClick = useCallback(
    async (e: any) => {
      e.preventDefault();

      e.stopPropagation();

      if (validateString(messageUrl)) {
        setPreviewUrl(messageUrl?.indexOf('http') === -1 ? `https://${messageUrl}` : messageUrl);
      }
    },
    [setPreviewUrl, messageUrl]
  );

  return (
    <div className={[styles.wrapper, ...(message?.alignByCurrentUser ? [styles.alignByCurrentUser] : [])].join(' ')}>
      <TextMessageContent errand={errand} message={{ ...message, message: t('propertyInMyDatabase') }} />
      <div className={styles.dottedLines} />
      <button onClick={handleClick}>
        <div>
          <div className={styles.img}>
            <PropertyListingPhotos thumbnail={message.documents?.[0]} message={message}></PropertyListingPhotos>
          </div>
          <div className={styles.meta}>
            <div className={styles.price}>
              <Sanitized
                html={!message.message.split('<>')[1] ? t('callNow') : message.message.split('<>')[1]}
              ></Sanitized>
            </div>
            <div className={styles.content}>
              <h3>{message.message.split('<>')[0]}</h3>
              <div className={styles.imgs}>
                <>
                  {message?.documents?.slice(1, 5)?.map((document, index) => (
                    <PropertyListingPhotos thumbnail={document} message={message}></PropertyListingPhotos>
                  ))}
                </>
              </div>
              <p>{t('clickThisListing')}</p>
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};

export default PropertyListingMessageContent;
