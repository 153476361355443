import React, { useState, lazy } from 'react';
import ErrorBoundary from '@components/ErrorBoundary';
import useInitialMount from '@common/hooks/useInitialMount';
import { getUserConsent } from '@storage/userStorage';

import Styles from '@styles/ConsentPage.module.css';
// import ConsentContent from '@components/ConsentContent';
import { UserProvider } from '@contexts/user';
import { SocketProvider } from '@contexts/socket';
const ConversationPage = lazy(() => import('./Conversation'));

const Consent = (props) => {
  const [userConsent, ] = useState<string>(getUserConsent());
  const [loadConversation, setLoadConversation] = useState<string>(getUserConsent());

  useInitialMount(() => setLoadConversation('true'));

  return (
    <ErrorBoundary debug={`.src/Pages/Conversation/Consent.tsx`}>
      {/* <ConsentContent userConsent={userConsent} setUserConsent={setUserConsent} /> */}
      {loadConversation === 'true' && (
        <UserProvider>
          <SocketProvider userConsent={userConsent}>
            <section className={[
              Styles.pageContainer,
              // ...(!(userConsent === 'true' || userConsent === 'false') ? [Styles.disablePointerEvents] : []),
              // ...(userConsent === 'true' || userConsent === 'false' ? [Styles.unblur] : []),
            ].join(' ')}>
              <ConversationPage {...props} />
            </section>
          </SocketProvider>
        </UserProvider>
      )}
    </ErrorBoundary>
  );
};

export default Consent;
