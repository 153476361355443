import { ValidatorFunctions } from '@common/Validators';
import { IErrand } from '@interfaces/Conversation';
import { ChatType } from '@common/ChatType';

enum ChatFetchState {
  Error = 'Error',
  Start = 'Start',
  Fetching = 'Fetching',
  HasMore = 'HasMore',
  NothingToFetch = 'NothingToFetch',
}

enum ChatFetchEvent {
  RequestRestart = 'RequestRestart',
  RequestMore = 'RequestMore',

  EventFetchCompleted = 'EventFetchCompleted',
}

enum ChatFetchAction {
  Error = 'Error',
  Idle = 'Idle',
  LoadMore = 'LoadMore',
}

interface ChatFetchParamType {
  state: ChatFetchState;
  lastBatchLength: number;
  limit: number;
  positionMax: number;
  chatsLoaded: Set<string>;
}

const CHAT_BATCH_SIZE = 15;

const ChatFetchDefaultState: ChatFetchParamType = {
  state: ChatFetchState.Start,
  lastBatchLength: 0,
  limit: CHAT_BATCH_SIZE,
  positionMax: -2147483648,
  chatsLoaded: new Set(),
};

const ResetData = (data: ChatFetchParamType) => {
  data.state = ChatFetchDefaultState.state;
  data.lastBatchLength = ChatFetchDefaultState.lastBatchLength;
  data.limit = ChatFetchDefaultState.limit;
  data.positionMax = ChatFetchDefaultState.positionMax;
  data.chatsLoaded = new Set();
};

const StateHandlerError = (data: ChatFetchParamType, event: ChatFetchEvent): ChatFetchAction => {
  switch (event) {
    case ChatFetchEvent.RequestRestart:
      ResetData(data);
      return ChatFetchAction.LoadMore;
    default:
      console.error(`Error state unexpected event: |${event}|`);
      return ChatFetchAction.Error;
  }
};

const StateHandlerStart = (data: ChatFetchParamType, event: ChatFetchEvent): ChatFetchAction => {
  switch (event) {
    case ChatFetchEvent.RequestRestart:
      ResetData(data);
      return ChatFetchAction.LoadMore;
    case ChatFetchEvent.RequestMore:
      return ChatFetchAction.LoadMore;
    default:
      console.error(`Start state unexpected event: |${event}|`);
      return ChatFetchAction.Error;
  }
};

const StateHandlerFetching = (data: ChatFetchParamType, event: ChatFetchEvent): ChatFetchAction => {
  switch (event) {
    case ChatFetchEvent.RequestRestart:
      return ChatFetchAction.Idle;
    case ChatFetchEvent.RequestMore:
      return ChatFetchAction.Idle;
    case ChatFetchEvent.EventFetchCompleted:
      if (data.lastBatchLength < data.limit) {
        data.state = ChatFetchState.NothingToFetch;
      } else {
        data.state = ChatFetchState.HasMore;
      }

      return ChatFetchAction.Idle;
    default:
      console.error(`Fetching state unexpected event: |${event}|`);
      return ChatFetchAction.Error;
  }
};

const StateHandlerHasMore = (data: ChatFetchParamType, event: ChatFetchEvent): ChatFetchAction => {
  switch (event) {
    case ChatFetchEvent.RequestRestart:
      ResetData(data);
      return ChatFetchAction.LoadMore;
    case ChatFetchEvent.RequestMore:
      return ChatFetchAction.LoadMore;
    default:
      console.error(`HasMore state unexpected event: |${event}|`);
      return ChatFetchAction.Error;
  }
};

const StateHandlerNothingToFetch = (data: ChatFetchParamType, event: ChatFetchEvent): ChatFetchAction => {
  switch (event) {
    case ChatFetchEvent.RequestRestart:
      ResetData(data);
      return ChatFetchAction.LoadMore;
    case ChatFetchEvent.RequestMore:
      return ChatFetchAction.Idle;
    default:
      console.error(`NothingToFetch  state unexpected event: |${event}|`);
      return ChatFetchAction.Error;
  }
};

const logicMap: {
  [key in ChatFetchState]: (data: ChatFetchParamType, event: ChatFetchEvent) => ChatFetchAction;
} = {
  [ChatFetchState.Error]: StateHandlerError,
  [ChatFetchState.Start]: StateHandlerStart,
  [ChatFetchState.Fetching]: StateHandlerFetching,
  [ChatFetchState.HasMore]: StateHandlerHasMore,
  [ChatFetchState.NothingToFetch]: StateHandlerNothingToFetch,
};

/**
 * Here we handle all chat fetch related logic. There are 2 main functionality
 * Reload: Nuke current fetched errands and restart chat fetch process
 * LoadMore: load more errands
 *
 * @param data Chat fetch related all parameters
 * @param event Event coming from reach component
 * @returns actino
 */
const ChatFetchStateManager = (data: ChatFetchParamType, event: ChatFetchEvent): ChatFetchAction => {
  const state = data?.state;

  if (ValidatorFunctions.isUndefinedOrNull(state)) {
    console.error(`Unexpected state: |${state}|`);
    return ChatFetchAction.Error;
  }

  const action = logicMap?.[state];

  if (ValidatorFunctions.isUndefinedOrNull(action)) {
    console.error(`Unexpected action: |${state}|`);
    return ChatFetchAction.Error;
  }

  const result = action(data, event);

  switch (result) {
    case ChatFetchAction.Error:
      data.state = ChatFetchState.Error;
      break;
    case ChatFetchAction.Idle:
      break;
    case ChatFetchAction.LoadMore:
      data.state = ChatFetchState.Fetching;
      break;
    default:
      console.error(`Unexpected result: |${result}|`);
  }

  return result;
};

/**
 * Checks if system can fetch more errands
 */
const ChatFetchHasMore = (action: ChatFetchAction): boolean => {
  if (action === ChatFetchAction.LoadMore) {
    return true;
  } else {
    return false;
  }
};

/**
 * Whenever endpoint request is finished this fn must me called in order to sync state
 */
const ChatFetchRequestCallback = (data: ChatFetchParamType, errands: Array<IErrand>): Array<IErrand> => {
  if (Array.isArray(errands) === false) {
    errands = [];
  }

  data.lastBatchLength = errands.length;
  ChatFetchStateManager(data, ChatFetchEvent.EventFetchCompleted);

  const newErrands: Array<IErrand> = [];

  for (const elm of errands) {
    if (ChatFetchAdd(data, elm)) {
      newErrands.push(elm);
    }
  }

  return newErrands;
};

/**
 * Add single chat, While we insert new chat we need to keep track of few parameters
 * positionMax: we will keep track of current loaded chat's max position number
 *
 * @param data state manager parameters
 * @param errand errand that needs to be added
 * @returns
 */
const ChatFetchAdd = (data: ChatFetchParamType, errand: IErrand): boolean => {
  const alreadyExists = data.chatsLoaded.has(errand._id);

  data.chatsLoaded.add(errand._id);

  const position = errand?.position;
  const type = errand?.type;

  if (ValidatorFunctions.isTypeOfNumber(position) && type !== ChatType.Conversation) {
    if (data.positionMax < position) {
      data.positionMax = position;
    }
  }

  if (alreadyExists) {
    console.error('chat already exists in loaded chats!');
    return false;
  }

  return true;
};

const ChatFetchReloadView = (data: ChatFetchParamType, errands: Array<IErrand>): boolean => {
  ResetData(data);

  for (const elm of errands) {
    ChatFetchAdd(data, elm);
  }

  // restore state
  if (errands.length < data.limit) {
    data.state = ChatFetchState.NothingToFetch;
  } else {
    data.state = ChatFetchState.HasMore;
  }

  return true;
};

const ChatFetchPositionMax = (data: ChatFetchParamType): number => {
  return data.positionMax;
};

const ChatFetchBulkDeleteFromCache = (data: ChatFetchParamType, errands: Array<IErrand>) => {
  for (const elm of errands) {
    const chatId = elm?._id;

    data.chatsLoaded.delete(chatId);
  }
};

const ChatFetchCountLoaded = (data: ChatFetchParamType): number => {
  return data.chatsLoaded.size;
};

const ChatFetchCheckIfAlreadyLoaded = (data: ChatFetchParamType, chatId: string): boolean => {
  if (data.chatsLoaded.has(chatId)) {
    return true;
  }
  return false;
};

export {
  ChatFetchStateManager,
  ChatFetchRequestCallback,
  ChatFetchBulkDeleteFromCache,
  ChatFetchCountLoaded,
  ChatFetchHasMore,
  ChatFetchPositionMax,
  ChatFetchCheckIfAlreadyLoaded,
  ChatFetchReloadView,
  ChatFetchParamType,
  ChatFetchDefaultState,
  ChatFetchState,
  ChatFetchAction,
  ChatFetchEvent,
};
